import React, { useContext, useEffect, useRef, useState } from "react";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import { CSVLink } from "react-csv";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { parseISO } from "date-fns";

import {
  createAuthenticatedRequest,
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";

import GrowlContext from "../../services/growlContext";
import "../DataTable.css";
import "../Forecast/Forecast.css";
import getWindowDimensions from "../../services/dimensions";
import { useHistory, useLocation } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { getFullDateString, getDateString } from "../../services/formatter";
import { tryCatchServerError, useDebounce } from "../../services/functions";
import { parse } from "query-string";
import { Popover, PopoverProps } from "../../components/Popover/Popover";
import { Button as PButton } from "primereact/components/button/Button";
import {
  buildExcelTable,
  generateExcel,
  genEmptyRow,
} from "../../services/exporter";
import { TableProperties } from "exceljs";
import { useGridStyles } from "../../hooks/useGridStyles";
import { GetCustomColumnShortened } from "../../components/DataTable/Columns";
import * as ActionButtons from "../../components/DataTable/ActionButtons";
import {
  MaximizeGrid,
  MinimizeGrid,
} from "../../components/DataTable/ActionButtons";
import IconTooltip from "../../components/IconTooltip";
import Swal from "sweetalert2";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import axios from "axios";
import {
  removeHtmlTags,
  removeHtmlTagsExceptLink,
} from "../../services/dataParser";
import { geographicalCodesHeader, ShareUser } from "../Forecast/Forecast";
import { OverlayPanel } from "primereact/overlaypanel";
import ThemeContext from "../../configs/theme";
import { MultiSelect } from "primereact/multiselect";
import Chip from "../../components/Chip";
import { Capitalize } from "../../utils/Helpers/Capitalize";
import styled from "@emotion/styled";
import { validateEmailSimple } from "../../utils/validation/input-validation";
import ReactDOM from "react-dom";
import ShowMoreWhenHover from "../../components/ShowMoreWhenHover";

enum businessForecastRecordType {
  default = "Default",
  newRecord = "NewRecord",
  modifiedRecord = "ModifiedRecord",
  finalRecord = "FinalRecord",
}

enum myListStatus {
  default = "Default",
  bid = "Bid",
  noBid = "NoBid",
  undecided = "Undecided",
  bidSubmitted = "BidSubmitted",
  live = "LiveOpportunity",
  lost = "Lost",
  win = "Win",
}

interface ServerRecord {
  forecast: {
    id: string;
    rowId: number;
    country: string;
    sector: string;
    totalEstimatedCost: string;
    awardActionType: string;
    smallBusinessSetAside: string;
    anticipatedAwardDate: string;
    anticipatedSolicitationReleaseDate: string;
    createdOn: string;
    status: businessForecastRecordType;
    changedColumns: string;
    awardTitle: string;

    awardDescription: string;
    aaPlanId: string;
    fiscalYearofAction: string;
    aaSpecialist: string;
    awardLength: string;
    eligibilityCriteria: string;
    geographicalCodes: string;
    categoryManagementContractVehicle: string;
    solicitationNumber: string;
    coCreation: string;
    location: string;
  };
  enhancements: {
    primeOrIncumbent: string;
    quarterlyCallNotes: string;
  };
  userEdits: {
    myListStatus: myListStatus;
    isMyList: boolean;
    email: string;
    notes: string;
    customColumn: string;
  };
}

const options = [
  { label: "Undecided", value: myListStatus.undecided },
  { label: "No Bid", value: myListStatus.noBid },
  { label: "Bid Submitted", value: myListStatus.bidSubmitted },
  { label: "Bid", value: myListStatus.bid },
  { label: "Live Opportunity", value: myListStatus.live },
  { label: "Lost", value: myListStatus.lost },
  { label: "Won", value: myListStatus.win },
];

type BasicForecastRecordEvent = {
  id: string;
  recordType: businessForecastRecordType;
  changedColumns: string;
  isMyList: boolean;
  title: string;
  createdOnDate: string;
  description: string;
  primeOrIncumbent: string;
  quarterlyCallNotes: string;
  estimatedCostRange: string;
  projectType: string;
  anticipatedReleaseDate: string;
  anticipatedAwardDate: string;
  smallBusinessSetAside: string;
  sector: string;
  country: string;

  aaPlanId: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string;
  coCreation: string;
  location: string;
};

type MyViewForecastSearch = {
  sortField: string;
  sortOrder: number;
  rowsPerPage: number;
  pageIndex: number;
  keyword: string;
};

interface MyViewForecastRecord {
  id: string;
  recordType: businessForecastRecordType;
  changedColumns: string;
  isMyList: boolean;
  title: string;
  createdOnDate: string;
  description: string;
  primeOrIncumbent: string;
  quarterlyCallNotes: string;
  estimatedCostRange: string;
  projectType: string;
  anticipatedReleaseDate: string;
  anticipatedAwardDate: string;
  smallBusinessSetAside: string;
  sector: string;
  country: string;
  myListStatus: myListStatus;
  notes: string;
  customColumn: string;
  rawRecord: ServerRecord;

  aaPlanId: string;
  fiscalYearofAction: string;
  aaSpecialist: string;
  awardLength: string;
  eligibilityCriteria: string;
  geographicalCodes: string;
  categoryManagementContractVehicle: string;
  solicitationNumber: string;
  coCreation: string;
  location: string;
}

const OverlayerWindow = styled.div({
  zIndex: 1000000,
});
const customStyle = {
  multiSelect: {
    width: "100%",
    borderRadius: 10,
  },
};

function RecordExpandedView({
  record,
  popoverProps,
  pipelineId,
}: {
  record: MyViewForecastRecord;
  popoverProps: PopoverProps;
  pipelineId: number;
}) {
  const context = React.useContext(UserContext);
  const growl = React.useContext(GrowlContext);
  const styles = useGridStyles();

  const [data, setData] = React.useState(Array<BasicForecastRecordEvent>());
  const [loading, setLoading] = React.useState(false);
  let dt = React.useRef<DataTable>(null);

  const getFormattedCellFromBody = function (
    fieldNames: string[],
    getValueFunc: (r: BasicForecastRecordEvent) => any,
  ) {
    return function (row: BasicForecastRecordEvent) {
      const recordType = row.recordType;
      const updatedFields = row.changedColumns
        ? row.changedColumns.split(",").map((m) => m.trim())
        : [];

      let className;

      if (
        recordType === businessForecastRecordType.newRecord ||
        recordType === businessForecastRecordType.default
      ) {
        className = "new";
      } else if (recordType === businessForecastRecordType.finalRecord) {
        className = "final";
      } else if (
        recordType !== businessForecastRecordType.modifiedRecord ||
        !fieldNames.length
      ) {
        className = "";
      } else {
        // Only modified rows here - find if this field is a modified field - or if source field
        if (
          fieldNames.find((x) => x === "Source") ||
          updatedFields.find((m) => fieldNames.find((x) => x === m))
        ) {
          className = "modified";
        } else {
          className = "";
        }
      }

      return <div className={className}>{getValueFunc(row)}</div>;
    };
  };
  const getPipelineDetailsAsync = async () => {
    const request = createRequestWithAuthHeaders(context);
    await axios
      .post(
        getFullUrl(
          `/api/pipeline/${pipelineId}/forecast/${record.id}/details`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        null,
        request,
      )
      .then((r) => {
        const { data } = r;
        const records: BasicForecastRecordEvent[] = (
          data as ServerRecord[]
        ).map((d) => ({
          id: d.forecast.id,
          recordType: d.forecast.status,
          changedColumns: d.forecast.changedColumns,
          country: d.forecast.country,
          createdOnDate: d.forecast.createdOn,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          description: d.forecast.awardDescription,
          sector: d.forecast.sector,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
        }));
        setData(records);
        setLoading(false);
      })
      .catch(
        tryCatchServerError((m) => {
          growl.current.show({
            severity: "error",
            summary: "Error getting details",
            detail: `Error getting the Forecast+ details for '${record.title}'`,
          });
          setLoading(false);
        }),
      );
  };
  React.useEffect(() => {
    setLoading(true);
    getPipelineDetailsAsync();
  }, []);

  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible === "ForecastInside"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
      />
      <DataTable
        value={data}
        scrollHeight="600px"
        rowClassName={(r: BasicForecastRecordEvent) => {
          const { recordType } = r;
          const baseclassName =
            recordType === businessForecastRecordType.newRecord
              ? "new-record"
              : recordType === businessForecastRecordType.modifiedRecord
              ? "modified-record"
              : recordType === businessForecastRecordType.finalRecord
              ? "final-record"
              : "new-record";
          const classNames = [baseclassName];

          if (baseclassName === "modified-record") {
            // add class for each modified column
            classNames.splice(
              1,
              0,
              ...r.changedColumns
                .split(",")
                .map((m) => "changed-column-" + m.trim().toLowerCase()),
            );
          }

          return classNames.reduce(
            (prev, curr) => ({ ...prev, [curr]: true }),
            {},
          );
        }}
        ref={dt}
        scrollable={true}
      >
        <Column
          headerStyle={styles.headerStyle(188)}
          style={styles.columnStyle(188)}
          header="Changes"
          field="changedColumns"
        />
        <Column
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="source"
          header="Source"
          body={getFormattedCellFromBody(["Source"], (x) => "Forecast+")}
        />
        <Column
          field="country"
          header="Country/Office"
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="country"
          body={getFormattedCellFromBody(
            ["Location", "OperatingUnit"],
            (x) => x.country,
          )}
        />

        <Column
          field="title"
          header="Title"
          sortable={true}
          headerStyle={styles.headerStyle(350)}
          style={styles.columnStyle(350)}
          bodyClassName="awardtitle"
          body={getFormattedCellFromBody(["AwardTitle"], (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ))}
        />
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          sortable={true}
          body={getFormattedCellFromBody([], (r) =>
            getFullDateString(parseISO(r.createdOnDate)),
          )}
        />
        <Column
          field="description"
          header="Description"
          sortable={true}
          body={getFormattedCellFromBody(["AwardDescription"], (r) => (
            <p
              className="primeOrIncumbent"
              data-title={r.title}
              data-full-abstract={r.description}
            >
              {r.description && r.description.length > 95
                ? `${removeHtmlTags(r.description).substring(0, 95)}...`
                : r.description}
            </p>
          ))}
          headerStyle={styles.headerStyle(550)}
          style={{ ...styles.columnStyle(550), ...styles.primeColumnStyle }}
          bodyClassName="awarddescription"
        />
        <Column
          field="primeOrIncumbent"
          header="Suggested Primes/Partners"
          sortable={true}
          body={getFormattedCellFromBody(
            [],
            (r) =>
              r.primeOrIncumbent && (
                <p
                  className="primeOrIncumbent"
                  data-full-abstract={r.primeOrIncumbent}
                >
                  {r.primeOrIncumbent && r.primeOrIncumbent.length > 95
                    ? `${removeHtmlTags(r.primeOrIncumbent).substring(
                        0,
                        95,
                      )}...`
                    : r.primeOrIncumbent}
                </p>
              ),
          )}
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650), ...styles.primeColumnStyle }}
          bodyClassName="primeorincumbent"
        />
        <Column
          field="quarterlyCallNotes"
          header="Quarterly Forecast Q&A and Notes"
          sortable={true}
          body={getFormattedCellFromBody(
            [],
            (r) =>
              r.quarterlyCallNotes && (
                <p
                  className="primeOrIncumbent"
                  data-full-abstract={r.quarterlyCallNotes}
                >
                  {r.quarterlyCallNotes && r.quarterlyCallNotes.length > 95
                    ? `${removeHtmlTags(r.quarterlyCallNotes).substring(
                        0,
                        95,
                      )}...`
                    : r.quarterlyCallNotes}
                </p>
              ),
          )}
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650), ...styles.primeColumnStyle }}
          bodyClassName="primeorincumbent"
        />
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          body={getFormattedCellFromBody(["Sector"], (x) =>
            removeHtmlTags(x.sector),
          )}
          headerStyle={styles.headerStyle(250)}
          style={styles.columnStyle(250)}
          bodyClassName="sector"
        />
        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          body={getFormattedCellFromBody(["AAPlanId"], (x) => x.aaPlanId)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaplanid"
          exportable={true}
        />

        {/**Incumbent - cannot find */}

        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          body={getFormattedCellFromBody(
            ["CategoryManagementContractVehicle"],
            (x) => x.categoryManagementContractVehicle,
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          body={getFormattedCellFromBody(
            ["TotalEstimatedCost"],
            (x) => x.estimatedCostRange,
          )}
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          bodyClassName="totalestimatedcost"
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AwardActionType"],
            (x) => x.projectType,
          )}
          headerStyle={styles.headerStyle(200)}
          style={styles.columnStyle(200)}
          bodyClassName="awardactiontype"
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={getFormattedCellFromBody(["GeographicalCodes"], (x) => (
            <span
              dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}
            ></span>
          ))}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />

        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={getFormattedCellFromBody(["CoCreation"], (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.coCreation }}></span>
          ))}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AnticipatedSolicitationReleaseDate"],
            (r) => getFullDateString(parseISO(r.anticipatedReleaseDate)),
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="anticipatedsolicitationreleasedate"
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={getFormattedCellFromBody(["AnticipatedAwardDate"], (r) =>
            getFullDateString(parseISO(r.anticipatedAwardDate)),
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="anticipatedawarddate"
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          body={getFormattedCellFromBody(["AwardLength"], (x) => x.awardLength)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          body={getFormattedCellFromBody(
            ["SmallBusinessSetAside"],
            (x) => x.smallBusinessSetAside,
          )}
          headerStyle={styles.headerStyle(150)}
          style={styles.columnStyle(150)}
          bodyClassName="smallbusinesssetaside"
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          body={getFormattedCellFromBody(
            ["EligibilityCriteria"],
            (x) => x.eligibilityCriteria,
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          body={getFormattedCellFromBody(
            ["SolicitationNumber"],
            (x) => x.solicitationNumber,
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          body={getFormattedCellFromBody(
            ["AASpecialist"],
            (x) => x.aaSpecialist,
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          body={getFormattedCellFromBody(
            ["FiscalYearofAction"],
            (x) => x.fiscalYearofAction,
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          body={getFormattedCellFromBody(["Location"], (x) => x.location)}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>
    </div>
  );
}

const statusRenderer = (record: MyViewForecastRecord) => {
  const match = options.find((o) => o.value === record.myListStatus);
  return match ? match.label : "";
};

export function MyBids({
  id,
  isPipelineScreen,
  isPipelineOwner,
  refreshForecastData,
  setRefreshForecastData,
}) {
  const context = useContext(UserContext);
  const styles = useGridStyles();

  const history = useHistory();
  const location = useLocation();

  let initialFilterTxt = "";
  if (location && location.search) {
    initialFilterTxt = (parse(location.search).initialFilter as string) || "";
  }
  const path = history.location.pathname;
  const [data, setData] = useState(Array<MyViewForecastRecord>());
  // const [allData, setAllData] = useState(Array<MyViewForecastRecord>());
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [keyword, setKeyword] = React.useState(initialFilterTxt);
  const [expandedRows, setExpandedRows] = React.useState(Array<any>());
  const [originalNotes, setOriginalNotes] = React.useState(
    Array<{ id: string; note: string }>(),
  );
  const [originalColumns, setOriginalColumns] = React.useState(
    Array<{ id: string; customColumn: string }>(),
  );
  const [baseUrl, setBaseUrl] = React.useState("");
  const [notesCollapsed, setNotesCollapsed] = React.useState(false);
  const [customColumnCollapsed, setCustomColumnCollapsed] =
    React.useState(false);
  const [customColumnName, setCustomColumnName] = React.useState("");
  const growl = React.useContext(GrowlContext);
  const [gridWidth, setGridWidth] = React.useState(0);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [selectedTextToShow, setSelectedTextToShow] = useState<string | null>(
    null,
  );

  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
  const [bottomOffset, setBottomOffset] = useState<string>();
  const [leftOffset, setLeftOffset] = useState<string>();
  const popupRef = useRef(null);

  const displayMoreTextPopup = (
    text: string,
    show: boolean,
    top: any,
    left: any,
  ) => {
    if (show) {
      setSelectedTextToShow(text);
      var element = ReactDOM.findDOMNode(popupRef.current) as Element;
      if (element) {
        const elementRect = element.getBoundingClientRect();
        if (text.length < 60) elementRect.height = 70;
        else elementRect.height = 160;

        setBottomOffset(top - elementRect.height + "px");
        setLeftOffset(left + "px");
      }
    }
    setVisibleTooltip(show);
  };

  React.useEffect(() => {
    queryData({ first: first, rows: rowsPerPage, keyword });

    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const debouncedSearch = useDebounce(keyword, 750);

  React.useEffect(() => {
    queryData({
      first,
      rows: rowsPerPage,
      sort: { sortField, sortOrder },
      keyword,
    });
  }, [debouncedSearch]);

  const [queryDto, setQueryDto] = React.useState<MyViewForecastSearch>();

  function sort(event: { sortField: string; sortOrder: number }) {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    queryData({ first, rows: rowsPerPage, sort: { ...event }, keyword });
  }

  const queryData = (event: {
    first: number;
    rows: number;
    sort?: { sortField: string; sortOrder: number };
    keyword: string;
  }) => {
    setIsLoading(true);

    // Since this table is being reused in my bids
    // We switch url depending on the current path.

    let url = "/api/businessforecast/mylist";

    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/forecast`;
        setBaseUrl(url);
        break;
      case "/mybids":
        setBaseUrl(url);
        break;
      default:
        break;
    }
    setRowsPerPage(event.rows);
    setFirst(event.first);

    const dto: MyViewForecastSearch = {
      sortField: event.sort ? event.sort.sortField : sortField,
      sortOrder: event.sort ? event.sort.sortOrder : sortOrder,
      rowsPerPage: event.rows, // need to send rows since rowsPerPage may not be updated yet
      pageIndex: event.first / event.rows,
      keyword: event.keyword,
    };
    const queryString = new URLSearchParams();

    setFirst(event.first);
    const pageIndex = event.first / rowsPerPage;
    queryString.set("pageIndex", pageIndex.toString());
    queryString.set("rowsPerPage", event.rows.toString());

    queryString.set("sortField", event.sort?.sortField || sortField);
    queryString.set(
      "sortOrder",
      (event.sort?.sortOrder || sortOrder).toString(),
    );

    if (keyword !== null && event.keyword != null) {
      queryString.set("keyword", event.keyword.toString());
    } else {
      // assign an empty string if keyword is undefined or null
      queryString.set("keyword", "");
    }

    const filterToUse = event.keyword !== undefined ? event.keyword : keyword;
    if (filterToUse) {
      queryString.set("filter", filterToUse);
    }
    setQueryDto(dto);

    //  Temporary  pending migration
    //  TO DO : remove
    switch (path) {
      case `/pipeline/${id}`:
        axios
          .get(
            getFullUrl(`${url}?${queryString.toString()}`, {
              useDedicatedEnvironment: true,
            }),
            // dto,
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var r: {
              lastUpdatedUtc: string;
              totalItems: number;
              data: ServerRecord[];
              attributes: any;
            } = response.data;
            const { lastUpdatedUtc, data, totalItems, attributes } = r;
            setTotalRecords(totalItems);
            setCustomColumnName(attributes ? attributes.CustomColumnName : "");
            setLastUpdated(
              lastUpdated
                ? `Last updated ${new Date(lastUpdated + "Z").toLocaleString()}`
                : "",
            );
            setOriginalNotes(
              data.map((r) => ({ id: r.forecast.id, note: r.userEdits.notes })),
            );
            setOriginalColumns(
              data.map((r) => ({
                id: r.forecast.id,
                customColumn: r.userEdits.customColumn,
              })),
            );
            const records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              primeOrIncumbent: d.enhancements.primeOrIncumbent,
              quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: d.userEdits.myListStatus,
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,

              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: d.forecast.geographicalCodes,
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTags(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            setData(records);
            let rows: any[][] = [];

            for (let i = 0; i < data.length; i++) {
              const record = records[i];

              rows.push([
                record.rawRecord.forecast.status !==
                businessForecastRecordType.finalRecord
                  ? `https://www.usaid.gov/node/${record.rawRecord.forecast.id}`
                  : "",
                record.notes
                  ? record.notes
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/\&nbsp;/g, "")
                      .replace(/&amp;/g, "&")
                  : record.notes,
                record.customColumn
                  ? record.customColumn
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/\&nbsp;/g, "")
                      .replace(/&amp;/g, "&")
                  : record.customColumn,
                statusRenderer(record),
                record.country?.replace(/\&nbsp;/g, "").replace(/&amp;/g, "&"),
                record.title?.replace(/\&nbsp;/g, "").replace(/&amp;/g, "&"),
                record.recordType === businessForecastRecordType.newRecord ||
                record.recordType === businessForecastRecordType.modifiedRecord
                  ? "Active"
                  : "Closed",
                //businessForecastRecordType[record.recordType]
                // ?.replace(/\&nbsp;/g, "")
                // .replace(/&amp;/g, "&"),
                record.createdOnDate
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.description
                  ? record.description
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/\&nbsp;/g, "")
                      .replace(/&amp;/g, "&")
                  : record.description,
                record.sector?.replace(/\&nbsp;/g, "").replace(/&amp;/g, "&"),
                // record.primeOrIncumbent
                //   ? record.primeOrIncumbent
                //       .replace(/(<([^>]+)>)/gi, "")
                //       .replace(/\&nbsp;/g, "")
                //       .replace(/&amp;/g, "&")
                //   : record.primeOrIncumbent,
                // record.quarterlyCallNotes
                //   ? record.quarterlyCallNotes
                //       .replace(/(<([^>]+)>)/gi, "")
                //       .replace(/\&nbsp;/g, "")
                //       .replace(/&amp;/g, "&")
                //   : record.quarterlyCallNotes,
                removeHtmlTagsExceptLink(record.primeOrIncumbent),
                removeHtmlTagsExceptLink(record.quarterlyCallNotes),
                record.aaPlanId?.replace(/\&nbsp;/g, "").replace(/&amp;/g, "&"),
                record.categoryManagementContractVehicle
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.estimatedCostRange
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.projectType
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.geographicalCodes
                  ? record.geographicalCodes
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/\&nbsp;/g, "")
                      .replace(/&amp;/g, "&")
                  : record.geographicalCodes,
                record.coCreation
                  ? record.coCreation
                      .replace(/(<([^>]+)>)/gi, "")
                      .replace(/\&nbsp;/g, "")
                      .replace(/&amp;/g, "&")
                  : record.coCreation,
                record.anticipatedReleaseDate
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.anticipatedAwardDate
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.awardLength
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.smallBusinessSetAside
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.eligibilityCriteria
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.solicitationNumber
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.aaSpecialist
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.fiscalYearofAction
                  ?.replace(/\&nbsp;/g, "")
                  .replace(/&amp;/g, "&"),
                record.location?.replace(/\&nbsp;/g, "").replace(/&amp;/g, "&"),
              ]);
            }

            setPageDataRows(rows);
            // setAllData(records);
            setIsLoading(false);
          })
          .catch(
            tryCatchServerError((m) => {
              growl.current.show({
                severity: "error",
                sticky: true,
                summary: "Export quota reached",
                detail: m,
              });
              setIsLoading(false);
            }),
          );
        break;
      default:
        axios
          .post(
            getFullUrl(url, {
              useDedicatedEnvironment: true,
            }),
            dto,
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var r: {
              lastUpdatedUtc: string;
              totalItems: number;
              data: ServerRecord[];
              attributes: any;
            } = response.data;
            const { lastUpdatedUtc, data, totalItems, attributes } = r;
            setTotalRecords(totalItems);
            setCustomColumnName(attributes ? attributes.CustomColumnName : "");
            setLastUpdated(
              lastUpdated
                ? `Last updated ${new Date(lastUpdated + "Z").toLocaleString()}`
                : "",
            );
            setOriginalNotes(
              data.map((r) => ({ id: r.forecast.id, note: r.userEdits.notes })),
            );
            setOriginalColumns(
              data.map((r) => ({
                id: r.forecast.id,
                customColumn: r.userEdits.customColumn,
              })),
            );
            const records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              primeOrIncumbent: removeHtmlTags(d.enhancements.primeOrIncumbent),
              quarterlyCallNotes: removeHtmlTags(
                d.enhancements.quarterlyCallNotes,
              ),
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: d.userEdits.myListStatus,
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,

              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: removeHtmlTags(d.forecast.geographicalCodes),
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTags(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            setData(records);
            // setAllData(records);
            setIsLoading(false);
          })
          .catch(
            tryCatchServerError((m) => {
              growl.current.show({
                severity: "error",
                sticky: true,
                summary: "Export quota reached",
                detail: m,
              });
              setIsLoading(false);
            }),
          );
        break;
    }
  };

  useEffect(() => {
    if (refreshForecastData) {
      setRefreshForecastData(false);
      queryData({
        first,
        rows: rowsPerPage,
        sort: { sortOrder, sortField },
        keyword,
      });
    }
  }, [refreshForecastData]);

  const StatusDropdown = ({
    rowData,
    pipelineId,
    isPipelineOwner,
  }: {
    rowData: MyViewForecastRecord;
    pipelineId: number;
    isPipelineOwner?: boolean;
  }) => {
    const context = React.useContext(UserContext);
    const growl = React.useContext(GrowlContext);

    const handleChange = async (status: myListStatus) => {
      // set value, try to update, roll back if need be
      rowData.myListStatus = status;

      await axios
        .post(
          getFullUrl(`/api/pipeline/${pipelineId}/forecast/status`, {
            useDedicatedEnvironment: true,
          }),
          {
            businessForecastId: rowData.id,
            value: status,
          },
          createRequestWithAuthHeaders(context),
        )
        .then((r) => {
          // success
          queryData({
            first,
            rows: rowsPerPage,
            sort: { sortField, sortOrder },
            keyword,
          });
          if (growl)
            growl.current.show({
              severity: "success",
              summary: "Status Updated",
              detail: `Updated '${rowData.title}' to ${statusRenderer(
                rowData,
              )}`,
            });
        })
        .catch((r) => {
          // error - roll back
          rowData.myListStatus = status;
          console.error(r);
          if (growl)
            growl.current.show({
              severity: "error",
              summary: "Error",
              detail: `Error updating status`,
            });
        });
    };

    const dropdown = React.useRef<Dropdown>();

    return (
      <div>
        <Dropdown
          style={{ width: "100%" }}
          className="mybids-forecast-dropdown"
          appendTo={document.body}
          disabled={!isPipelineOwner}
          ref={(e) => {
            if (e) dropdown.current = e;
          }}
          onFocus={(e) => {
            if (dropdown.current) {
              (dropdown.current as any).showOverlay();
            }
          }}
          options={options}
          onChange={(e) => handleChange(e.value)}
          value={rowData.myListStatus}
        />
      </div>
    );
  };

  let dt = React.useRef<DataTable>(null);
  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const [exportVisible, setExportVisible] = React.useState(false);

  const [disableExcel, setDisableExcel] = React.useState(false);

  var getExcelData = async (): Promise<MyViewForecastRecord[]> => {
    var base = baseUrl;
    var q = queryDto;

    if (q) q.rowsPerPage = 1000;

    var qu = q ? base + "?" + "rowsPerPage=" + q.rowsPerPage : base;
    var result = Array<MyViewForecastRecord>();

    // TO DO : Clean after full migration
    switch (path) {
      case `/pipeline/${id}`:
        await axios
          .get(
            getFullUrl(qu, {
              useDedicatedEnvironment: true,
            }),
            // q, TO DO requires post for the end point.
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var data = response.data.data;

            var records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              // primeOrIncumbent: removeHtmlTags(d.enhancements.primeOrIncumbent),
              // quarterlyCallNotes: removeHtmlTags(
              //   d.enhancements.quarterlyCallNotes,
              // ),
              primeOrIncumbent: removeHtmlTagsExceptLink(
                d.enhancements.primeOrIncumbent,
              ),
              quarterlyCallNotes: removeHtmlTagsExceptLink(
                d.enhancements.quarterlyCallNotes,
              ),
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: statusRenderer(d.userEdits),
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,
              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: removeHtmlTags(d.forecast.geographicalCodes),
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTags(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            result = records;
          })
          .catch((error) => {
            alert("error fetching data");
          });
        return result;
      default:
        await axios
          .post(
            getFullUrl(base, {
              useDedicatedEnvironment: true,
            }),
            q, // TO DO requires post for the end point.
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var data = response.data.data;

            var records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              primeOrIncumbent: removeHtmlTags(d.enhancements.primeOrIncumbent),
              quarterlyCallNotes: removeHtmlTags(
                d.enhancements.quarterlyCallNotes,
              ),
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: statusRenderer(d.userEdits),
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,
              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: removeHtmlTags(d.forecast.geographicalCodes),
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTags(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            result = records;
          })
          .catch((error) => {
            alert("error fetching data");
          });
        return result;
    }
  };

  const [pageDataRows, setPageDataRows] = React.useState<any[]>([]);

  const [entireDataRows, setEntireDataRows] = React.useState<any[]>([]);

  const cvsColumns = [
    "usaid.gov link",
    "Notes",
    customColumnName,
    "Status",
    "Country/Office",
    "Name",
    "Type",
    "Date Record Created/Changed",
    "Description",
    "Sector",
    "Suggested Primes/Partners",
    "Quarterly Forecast Q&A and Notes",
    "ID",
    "Category Management Contract Vehicle",
    "Cost",
    "Award/Action Type",
    "Geographical Codes",
    "Co-creation",
    "Anticipated Release Date",
    "Anticipated Award Date",
    "Award Length",
    "Small Business Set-Aside",
    "Eligibility Criteria",
    "Solicitation Number",
    "A&A Specialist",
    "Fiscal Year of Action",
    "Location",
  ];

  React.useEffect(() => {
    if (data && queryDto) {
      getExcelData().then((excelData) => {
        getExportableCSVDataTable(excelData ?? data);
      });
    }
  }, [data, queryDto]);

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col topLeftColumnHeader topRightColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div className="p-col"></div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <div style={{ font: "14px Open Sans", verticalAlign: "middle" }}>
          <IconTooltip
            tooltipText={
              "Opportunities are automatically sorted by “Last Updated Date,” so you can quickly identify changes and when they were made."
            }
            className="tooltip-fixed"
          />
        </div>
        <div className="button-select">
          <PButton
            type="button"
            icon="pi pi-file"
            iconPos="left"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            label="All Forecasts"
            onClick={() => history.push("/forecast")}
          />
        </div>
        <div className="search-bar">
          <InputText
            type="search"
            style={{ ...styles.keywordSearch }}
            value={keyword}
            onChange={(e) => setKeyword((e.target as any).value)}
            placeholder="Global Search"
          />
        </div>
        <div className="button-export">
          {exportVisible && (
            <div className="button-export-popup">
              <CSVLink
                filename={`Aidkonekt_forecast_plus_${new Date().getFullYear()}`}
                data={pageDataRows}
                headers={cvsColumns}
              >
                <button
                  className="export-popup-option"
                  onClick={() => setExportVisible(false)}
                >
                  Export Current Page
                </button>
              </CSVLink>
              <CSVLink
                filename={`Aidkonekt_forecast_plus_${new Date().getFullYear()}`}
                data={entireDataRows}
                headers={cvsColumns}
              >
                <button
                  className="export-popup-option"
                  onClick={() => setExportVisible(false)}
                >
                  Export Entire Pipeline
                </button>
              </CSVLink>
            </div>
          )}
          <PButton
            type="button"
            icon="pi pi-file-o"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            tooltip="CSV"
            tooltipOptions={{ position: "top" }}
            onClick={() => {
              setExportVisible(!exportVisible);
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "MyForecasts+",
                `Aidkonekt_myForecasts_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            className="aid-blu-btn"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false),
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{ ...styles.tableHeaderButton, height: "auto" }}
          />
        </div>
      </div>
    </div>
  );

  const getExportableDataTable = (
    data: MyViewForecastRecord[],
  ): TableProperties => {
    const columns = [
      { name: "usaid.gov link" },
      { name: "Notes" },
      { name: customColumnName },
      { name: "Status" },
      { name: "Country/Office" },
      { name: "Name" },
      { name: "Type" },
      { name: "Date Record Created/Changed" },
      { name: "Description" },
      { name: "Sector" },
      { name: "Suggested Primes/Partners" },
      { name: "Quarterly Forecast Q&A and Notes" },
      { name: "ID" },
      { name: "Category Management Contract Vehicle" },
      { name: "Cost" },
      { name: "Award/Action Type" },
      { name: "Geographical Codes" },
      { name: "Co-creation" },
      { name: "Anticipated Release Date" },
      { name: "Anticipated Award Date" },
      { name: "Award Length" },
      { name: "Small Business Set-Aside" },
      { name: "Eligibility Criteria" },
      { name: "Solicitation Number" },
      { name: "A&A Specialist" },
      { name: "Fiscal Year of Action" },
      { name: "Location" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          record.rawRecord.forecast.status !==
          businessForecastRecordType.finalRecord
            ? `https://www.usaid.gov/node/${record.rawRecord.forecast.id}`
            : "",
          record.notes
            ? record.notes.replace(/(<([^>]+)>)/gi, "")
            : record.notes,
          record.customColumn
            ? record.customColumn.replace(/(<([^>]+)>)/gi, "")
            : record.customColumn,
          statusRenderer(
            record.rawRecord.userEdits as any as MyViewForecastRecord,
          ),
          removeHtmlTags(record.country),
          removeHtmlTags(record.title),
          record.recordType === businessForecastRecordType.newRecord ||
          record.recordType === businessForecastRecordType.modifiedRecord
            ? "Active"
            : "Closed", //businessForecastRecordType[record.recordType],
          record.createdOnDate,
          removeHtmlTagsExceptLink(record.description).replace(
            /(<([^>]+)>)/gi,
            "",
          ),
          record.sector ? removeHtmlTags(record.sector) : "",
          record.primeOrIncumbent
            ? record.primeOrIncumbent.replace(/(<([^>]+)>)/gi, "")
            : record.primeOrIncumbent,
          record.quarterlyCallNotes
            ? record.quarterlyCallNotes.replace(/(<([^>]+)>)/gi, "")
            : record.quarterlyCallNotes,
          record.aaPlanId,
          record.categoryManagementContractVehicle,
          record.estimatedCostRange,
          record.projectType,
          record.geographicalCodes
            ? record.geographicalCodes.replace(/(<([^>]+)>)/gi, "")
            : record.geographicalCodes,
          record.coCreation
            ? record.coCreation.replace(/(<([^>]+)>)/gi, "")
            : record.coCreation,
          record.anticipatedReleaseDate,
          record.anticipatedAwardDate,
          record.awardLength,
          record.smallBusinessSetAside,
          removeHtmlTags(record.eligibilityCriteria),
          record.solicitationNumber,
          record.aaSpecialist,
          record.fiscalYearofAction,
          record.location,
        ]);
      }
    }

    return buildExcelTable("MyBidsExport", columns, rows);
  };

  const getExportableCSVDataTable = (data: MyViewForecastRecord[]) => {
    let rows: any[][] = [];
    if (data.length == 0) {
      rows.push(genEmptyRow(cvsColumns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        rows.push([
          record.rawRecord.forecast.status !==
          businessForecastRecordType.finalRecord
            ? `https://www.usaid.gov/node/${record.rawRecord.forecast.id}`
            : "",
          record.notes
            ? record.notes.replace(/(<([^>]+)>)/gi, "")
            : record.notes,
          record.customColumn
            ? record.customColumn.replace(/(<([^>]+)>)/gi, "")
            : record.customColumn,
          record.myListStatus,
          record.country,
          record.title,
          record.recordType === businessForecastRecordType.newRecord ||
          record.recordType === businessForecastRecordType.modifiedRecord
            ? "Active"
            : "Closed", //businessForecastRecordType[record.recordType],
          record.createdOnDate,
          record.description
            ? record.description.replace(/(<([^>]+)>)/gi, "")
            : "",
          record.sector,
          // record.primeOrIncumbent
          //   ? record.primeOrIncumbent.replace(/(<([^>]+)>)/gi, "")
          //   : record.primeOrIncumbent,
          // record.quarterlyCallNotes
          //   ? record.quarterlyCallNotes.replace(/(<([^>]+)>)/gi, "")
          //   : record.quarterlyCallNotes,
          removeHtmlTagsExceptLink(record.primeOrIncumbent),
          removeHtmlTagsExceptLink(record.quarterlyCallNotes),
          record.aaPlanId,
          record.categoryManagementContractVehicle,
          record.estimatedCostRange,
          record.projectType,
          record.geographicalCodes
            ? record.geographicalCodes.replace(/(<([^>]+)>)/gi, "")
            : record.geographicalCodes,
          record.coCreation
            ? record.coCreation.replace(/(<([^>]+)>)/gi, "")
            : record.coCreation,
          record.anticipatedReleaseDate,
          record.anticipatedAwardDate,
          record.awardLength,
          record.smallBusinessSetAside,
          record.eligibilityCriteria,
          record.solicitationNumber,
          record.aaSpecialist,
          record.fiscalYearofAction,
          record.location,
        ]);
      }
    }

    setEntireDataRows(rows);
  };

  const removeRecordFromMyList = async (record: MyViewForecastRecord) => {
    let request = createAuthenticatedRequest(context);
    // `/api/businessforecast/mylist/${record.id
    await axios
      .delete(
        getFullUrl(`/api/pipeline/${id}/forecast/${record.id}`, {
          useDedicatedEnvironment: true,
        }),
        request,
      )
      .then((r) => {
        // re-set my list status for all items with this id
        const newData = [...data.filter((r) => r.id !== record.id)];
        setTotalRecords(newData.length);
        setData(newData);
        if (growl)
          growl.current.show({
            severity: "success",
            summary: "Removed",
            detail: `Removed '${record.title}' from my list`,
          });
      })
      .catch((r) => {
        console.error(r);
        if (growl)
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: `Error removing '${record.title}' from my list`,
          });
      });
  };

  let updatedNotes = [...data];

  const NotesEditor = (props: any) => {
    const { rowIndex } = props;
    const onEditorValueChange = (value: string) => {
      updatedNotes[rowIndex].notes = value;
    };
    return (
      <textarea
        disabled={!isPipelineOwner}
        autoFocus
        // value={data[rowIndex].notes}
        defaultValue={data[rowIndex].notes || updatedNotes[rowIndex].notes}
        className="notesTextarea"
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };
  const ColumnEditor = (props: any) => {
    const { rowIndex } = props;
    const onEditorValueChange = (value: string) => {
      updatedNotes[rowIndex].customColumn = value;
    };
    return (
      <input
        disabled={!isPipelineOwner}
        type="text"
        defaultValue={
          data[rowIndex].customColumn || updatedNotes[rowIndex].customColumn
        }
        ref={inputRef}
        className="customColumn"
        style={{
          borderRadius: "10px",
          height: "35px",
          border: "1px solid #187ab5",
        }}
        onChange={(e) => onEditorValueChange((e.target as any).value)}
      />
    );
  };

  const submitNote = async (o: any) => {
    const {
      rowData,
      rowIndex,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o.columnProps;
    const currentNote = updatedNotes[rowIndex].notes;
    // If the note has not changed, don't re-save
    const baseNote = originalNotes.find((r) => r.id === rowData.id);
    const isNoteUnmodified = baseNote && baseNote.note === currentNote;
    if (isNoteUnmodified) {
      return;
    }

    if (!isPipelineOwner) {
      return;
    }

    // Send update
    await axios
      .post(
        getFullUrl(`/api/pipeline/${id}/forecast/note`, {
          useDedicatedEnvironment: true,
        }),
        { businessForecastId: rowData.id, value: currentNote },
        createRequestWithAuthHeaders(context),
      )
      .then((r) => {
        //update row data
        let updatedData = [...data];
        updatedData[rowIndex].notes = currentNote;
        setData(updatedData);

        // Update in-memory notes to new note

        if (baseNote) {
          const updatedNotes = [...originalNotes];
          const updatedNote = updatedNotes.find((r) => r.id === baseNote.id);
          if (updatedNote) updatedNote.note = currentNote;
          setOriginalNotes(updatedNotes);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Note Updated",
            detail: `Finished updating notes for '${rowData.title}'`,
          });
      })
      .catch((e) => {
        console.error(`Error updating note`, e);
        if (growl && growl.current)
          growl.current.show({
            severity: "error",
            summary: "Error updating note",
            detail: `Here Unable to update note for '${rowData.title}'`,
          });

        // Revert note back to original note
        cancelNote(o);
      });
  };

  const submitColumn = async (o: any) => {
    const {
      rowData,
      rowIndex,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o.columnProps;
    const currentCustomValue = updatedNotes[rowIndex].customColumn;
    // If the note has not changed, don't re-save
    const baseColumn = originalColumns.find((r) => r.id === rowData.id);

    const isColumnUnmodified =
      baseColumn && baseColumn.customColumn === currentCustomValue;
    if (isColumnUnmodified || currentCustomValue === null) {
      return;
    }

    if (!isPipelineOwner) {
      return;
    }

    // Send update
    await axios
      .post(
        getFullUrl(`/api/pipeline/${id}/forecast/customcolumn`, {
          useDedicatedEnvironment: true,
        }),
        {
          businessForecastId: rowData.id,
          value: currentCustomValue,
        },
        createRequestWithAuthHeaders(context),
      )
      .then(() => {
        //update row values
        let updatedData = [...data];
        updatedData[rowIndex].customColumn = currentCustomValue;
        setData(updatedData);
        // Update in-memory notes to new note
        if (baseColumn) {
          const updatedColumns = [...originalColumns];
          const updatedColumn = updatedColumns.find(
            (r) => r.id === baseColumn.id,
          );
          if (updatedColumn) updatedColumn.customColumn = currentCustomValue;
          setOriginalColumns(updatedColumns);
        }

        if (growl && growl.current)
          growl.current.show({
            severity: "success",
            summary: "Custom Column Updated",
            detail: `Finished updating custom column for '${rowData.title}'`,
          });
      })
      .catch(
        tryCatchServerError((message) => {
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          });
          cancelCustomColumn(o);
        }),
      );
  };

  const cancelNote = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseNote =
      (originalNotes.find((r) => r.id === rowData.id) || {}).note || "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].notes = baseNote;
    setData(updatedData);
  };

  const cancelColumn = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const cancelCustomColumn = (o: any) => {
    const {
      rowIndex,
      rowData,
    }: { rowIndex: number; rowData: MyViewForecastRecord } = o;
    const baseColumn =
      (originalColumns.find((r) => r.id === rowData.id) || {}).customColumn ||
      "";

    // Revert note back in grid
    const updatedData = [...data];
    updatedData[rowIndex].customColumn = baseColumn;
    setData(updatedData);
  };

  const ActionsButtonColumn = ({
    record,
  }: {
    record: MyViewForecastRecord;
  }) => {
    // MySuggestion page
    const remove = (
      <ActionButtons.RemoveFromMyListButton
        remove={() => removeRecordFromMyList(record)}
      />
    );
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>{remove}</div>
    );
  };

  const showEditCustomColumnName = async () => {
    const { value: formValues } = await Swal.fire({
      title: `Edit Column Name`,
      html: `
          <input id="columnName" class="swal2-input" value="${customColumnName}"></input>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return [(document.getElementById("columnName") as any).value];
      },
    });

    if (!formValues || !formValues.length) return;

    const [newColumnName] = formValues;
    await axios
      .put(
        getFullUrl(`/api/pipeline/${id}/columnName`, {
          useDedicatedEnvironment: true,
        }),
        { columnName: newColumnName, type: "businessforecast" },
        createAuthenticatedRequest(context),
      )
      .then(() => {
        setCustomColumnName(newColumnName);
      })
      .catch((e) => {
        console.error("error setting contract custom column name", e);
        growl.current.show({
          severity: "error",
          summary: "Error setting column name",
        });
      });
  };

  const notesHeader = (
    <div>
      {notesCollapsed && (
        <div>
          <Button
            icon="pi pi-chevron-right"
            onClick={() => setNotesCollapsed(false)}
          />
        </div>
      )}
      {!notesCollapsed && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ alignSelf: "center", marginRight: 10 }}>Notes</div>
          <Button
            icon="pi pi-chevron-left"
            onClick={() => setNotesCollapsed(true)}
          />
        </div>
      )}
    </div>
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState<string | JSX.Element>("");
  const [dialogVisible, setDialogVisible] = React.useState("");
  const [dialogExpendedVisible, setDialogExpendedVisible] =
    React.useState(false);

  React.useEffect(() => {
    const onDescriptionClick = (event: any) => {
      if (
        !event.target.matches(".suggestions_description") &&
        !event.target.matches(".primeOrIncumbent") &&
        !event.target.matches(".quarterlyCallNotes")
      ) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      if (!container) {
        return;
      }

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      if (event.target.matches(".primeOrIncumbent")) {
        setDialogHeader("Suggested Primes/Partners");
      }

      if (event.target.matches(".quarterlyCallNotes")) {
        setDialogHeader("Quarterly Forecast Q&A and Notes");
      }

      if (event.target.matches(".suggestions_description")) {
        setDialogHeader(container.attributes["data-title"].value);
      }

      setDialogText(dialogText);
      if (dialogVisible !== "Forecast") {
        setDialogVisible("Forecast");
      }
    };
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
      setDialogVisible("");
    };
  }, []);

  const suggestionsDescriptionColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="suggestions_description"
      data-title={r.title}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.solicitationNumber);
        setDialogText(r.description);
        setDialogVisible("Forecast");
      }}
    ></p>
  );

  // Share opportunity

  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;
  const theme = React.useContext(ThemeContext);

  const shareColumnHeader = (
    <>
      <span style={{ font: theme.v2Fonts.context, verticalAlign: "middle" }}>
        <IconTooltip tooltipText={"You can share opportunity via email"} />
      </span>
      &nbsp;
    </>
  );

  const [shareId, setShareId] = React.useState(0);
  const [shareUsers, setShareUsers] = React.useState<ShareUser[]>([]);
  const [shareExternalUser, setShareExternalUser] = React.useState<string>("");
  const [shareExternalUsers, setShareExternalUsers] = React.useState<string[]>(
    [],
  );
  const [shareMessageText, setShareMessageText] = React.useState("");

  const handleAddExternalUser = () => {
    if (!!shareExternalUser) {
      const isValid = validateEmailSimple(shareExternalUser);
      if (isValid) {
        setShareExternalUsers([...shareExternalUsers, shareExternalUser]);
        setShareExternalUser("");
      } else {
        growl.current.show({
          severity: "error",
          summary: "Invalid email address",
        });
      }
    }
  };

  const removeExternalShareUser = (user: string) => {
    setShareExternalUsers(
      shareExternalUsers.filter((externalUser) => user !== externalUser),
    );
  };
  const removeShareUser = (userId: string) => {
    setShareUsers(shareUsers.filter((user) => user.publicUserId !== userId));
  };

  const usersEmails = React.useMemo(() => {
    if (!!shareUsers) {
      return shareUsers.map((user) => user.email).concat(shareExternalUsers);
    }

    return shareExternalUsers;
  }, [shareUsers, shareExternalUsers]);

  const handleShare = () => {
    if (shareUsers.length === 0 && shareExternalUsers.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Please specify at least one recipient",
      });
      return;
    }

    if (shareMessageText.length === 0) {
      growl.current.show({
        severity: "error",
        summary: "Message field is empty",
      });
      return;
    }

    if (!!shareUsers || !!shareExternalUsers) {
      const sharePromises = usersEmails.map((email) => {
        const body = {
          toAdderess: email,
          opportunityId: shareId,
          recordType: "F",
          message: `${shareMessageText.replaceAll("\n", "<br>")}`,
        };

        return new Promise((resolve, reject) =>
          axios
            .post(
              getFullUrl("/api/pipeline/share", {
                useDedicatedEnvironment: true,
              }),
              body,
              createRequestWithAuthHeaders(context),
            )
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            }),
        );
      });

      Promise.all(sharePromises)
        .then(() => {
          if (growl.current) {
            growl.current.show({
              severity: "success",
              summary: "Opportunity shared successfully",
            });
          }
        })
        .catch(() => {
          if (growl.current) {
            growl.current.show({
              severity: "error",
              summary: "Unable to share opportunity",
            });
          }
        });
    }
    sharePopup.current?.hide();
  };
  const [companyUsers, setCompanyUsers] = React.useState<ShareUser[]>([]);

  const getOrganizationUser = async () => {
    await axios
      .get(
        getFullUrl("/api/organization/user", { useDedicatedEnvironment: true }),
        createAuthenticatedRequest(context),
      )
      .then((response) => {
        const { data } = response;
        const emailId = context.user.parsedIdToken?.email;
        if (emailId) {
          // var id = email[0]+ "@" + email[1]
          const orgUsers = data.filter(
            (r: ShareUser) => r.email?.toLocaleLowerCase() !== emailId,
          );
          setCompanyUsers(orgUsers);
        } else {
          setCompanyUsers(data);
        }
      })
      .catch((e) => {
        console.error(e);
        if (growl.current) {
          growl.current.show({
            severity: "error",
            summary: "Unable to load users",
          });
        }
      });
  };
  React.useEffect(() => {
    getOrganizationUser();
  }, []);

  const sharePopup = useRef<OverlayPanel>(null);
  const ShareButton = (e: MyViewForecastRecord) => {
    return (
      <div>
        <button
          className="share-btn"
          onClick={() => {
            setShareId(e.rawRecord.forecast.rowId);
            setShareUsers([]);
            setShareExternalUser("");
            setShareExternalUsers([]);
            setShareMessageText("");
            sharePopup.current?.toggle(
              e as unknown as React.SyntheticEvent<Element, Event>,
            );
          }}
        >
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4.60063L9.23147 0V2.20024C7.21594 2.38887 0.0446832 3.64493 0 12C0 12 1.73858 6.73656 9.23147 6.54326V9.20148L15 4.60063Z"
              fill="white"
            />
          </svg>
          <span>Share</span>
        </button>
      </div>
    );
  };

  const PAPColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.primeOrIncumbent}
      dangerouslySetInnerHTML={{
        __html:
          r.primeOrIncumbent && removeHtmlTags(r.primeOrIncumbent).length > 100
            ? `${r.primeOrIncumbent.substring(0, 100)}...`
            : r.primeOrIncumbent,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.primeOrIncumbent);
        setDialogVisible("Forecast");
      }}
    ></p>
  );

  const QCNColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.quarterlyCallNotes}
      dangerouslySetInnerHTML={{
        __html:
          r.quarterlyCallNotes &&
          removeHtmlTags(r.quarterlyCallNotes).length > 100
            ? `${r.quarterlyCallNotes.substring(0, 100)}...`
            : r.quarterlyCallNotes,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.quarterlyCallNotes);
        setDialogVisible("Forecast");
      }}
    ></p>
  );

  const LinkButton = (e: MyViewForecastRecord) => (
    <div>
      {e.rawRecord.forecast.status !==
      businessForecastRecordType.finalRecord ? (
        <a
          target="_blank"
          href={`https://www.usaid.gov/node/${e.rawRecord.forecast.id}`}
          rel="noreferrer"
        >
          link
        </a>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible === "Forecast"}
        dialogText={dialogText}
        setMultiDialogVisible={setDialogVisible}
        multi={true}
      />
      <OverlayerWindow>
        <OverlayPanel
          ref={sharePopup}
          id="pipeline-overlay"
          style={{ width: "500px", height: "100%", display: "flex !important" }}
          className="contract-panel share"
        >
          <div
            style={{ width: "500px", height: "100%", overflowY: "auto" }}
            className="share-overlay-height"
          >
            <div className="share-modal-header">
              Share opportunity
              <i
                onClick={() => sharePopup.current?.hide()}
                className="pi pi-times mr-2"
                style={{ fontSize: 20, cursor: "pointer" }}
              ></i>
            </div>
            <div className="share-modal-body">
              <div>
                <p>Recipients</p>
                <p className="email-option">External users</p>
                <div className="external-users-input">
                  <InputText
                    value={shareExternalUser}
                    onChange={(e) => {
                      setShareExternalUser(
                        (e.target as HTMLInputElement).value,
                      );
                    }}
                  />
                  <button
                    type="button"
                    className="add-btn aid-blu-btn"
                    onClick={handleAddExternalUser}
                  >
                    +
                  </button>
                </div>
                <div
                  className="share-users-list"
                  style={{
                    marginBottom: !!shareExternalUsers.length ? 10 : 0,
                  }}
                >
                  {!!shareExternalUsers.length &&
                    shareExternalUsers.map((user, i) => (
                      <Chip
                        key={i}
                        className="share-chip"
                        label={user}
                        onIconClick={() => removeExternalShareUser(user)}
                      />
                    ))}
                </div>
                <p className="email-option">Company users</p>
                <MultiSelect
                  optionLabel="email"
                  filter={true}
                  className="share-multiselect"
                  style={customStyle.multiSelect}
                  placeholder={shareUsers.length + " User(s) selected"}
                  fixedPlaceholder
                  value={shareUsers}
                  itemTemplate={(item: ShareUser) => (
                    <div className="share-multiselect-item">
                      {(item.firstName || item.lastName) && (
                        <p>{item.firstName + " " + item.lastName}</p>
                      )}
                      <p>{item.email}</p>
                    </div>
                  )}
                  options={companyUsers}
                  onChange={(e) => setShareUsers(e.value)}
                />
                <div className="share-users-list">
                  {!!shareUsers.length &&
                    shareUsers.map((user, i) => (
                      <Chip
                        key={i}
                        className="share-chip"
                        label={
                          user.firstName || user.lastName
                            ? Capitalize(user.firstName ?? "") +
                              " " +
                              Capitalize(user.lastName ?? "")
                            : user.email
                        }
                        onIconClick={() => removeShareUser(user.publicUserId)}
                      />
                    ))}
                </div>
              </div>
              <div>
                <p style={{ marginTop: 10 }}>Message</p>
                <textarea
                  className="share-message-text"
                  value={shareMessageText}
                  onChange={(e) => setShareMessageText(e.currentTarget.value)}
                />
              </div>
              <div className="share-footer">
                <button onClick={() => sharePopup.current?.hide()}>
                  Cancel
                </button>
                <button onClick={handleShare}>Share</button>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </OverlayerWindow>
      <DataTable
        value={data}
        globalFilter={keyword}
        style={{ marginTop: 20, width: `${gridWidth - 20}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        editMode="cell"
        ref={dt}
        header={tableHeader}
        exportFilename={`Aidkonekt_forecast_plus_${new Date().getFullYear()}`}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={(d: MyViewForecastRecord) => (
          <RecordExpandedView
            record={d}
            pipelineId={id}
            popoverProps={{
              dialogHeader,
              dialogText,
              isVisible: dialogExpendedVisible,
              setDialogVisible: setDialogExpendedVisible,
            }}
          />
        )}
      >
        <Column
          headerStyle={styles.headerStyle(40)}
          style={styles.columnStyle(40)}
          expander
        />
        {isPipelineScreen && isPipelineOwner && (
          <Column
            field="isMyList"
            header="Actions"
            sortable={true}
            headerStyle={styles.headerStyle(100)}
            style={styles.columnStyle(100)}
            body={(r: MyViewForecastRecord) => (
              <ActionsButtonColumn record={r} />
            )}
          />
        )}
        <Column
          headerStyle={styles.headerStyle(80)}
          style={styles.columnStyle(80)}
          bodyClassName="share"
          header="usaid.gov link"
          field="link"
          body={LinkButton}
        />
        {canShare && (
          <Column
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(150)}
            bodyClassName="share"
            header={shareColumnHeader}
            field="share"
            body={ShareButton}
          />
        )}
        <Column
          field={notesCollapsed ? "" : "notes"}
          header={notesHeader}
          headerStyle={{
            ...styles.headerStyle(notesCollapsed ? 50 : 350),
            ...styles.primeColumnStyle,
          }}
          body={(r: MyViewForecastRecord) => (
            <ShowMoreWhenHover
              text={r.notes ?? ""}
              showMoreInPopup={displayMoreTextPopup}
            />
          )}
          style={styles.columnStyle(notesCollapsed ? 50 : 350)}
          editor={notesCollapsed ? undefined : NotesEditor}
          onEditorSubmit={submitNote}
          onEditorCancel={cancelNote}
          onEditorInit={() => {
            setVisibleTooltip(false);
          }}
          editorValidator={(e) => {
            const element = e.originalEvent.srcElement as EventTarget & {
              className: string;
            };
            e.originalEvent.stopImmediatePropagation();
            return element ? element.className !== `notesTextarea` : true;
          }}
        />
        <Column
          field={customColumnCollapsed ? "" : "customColumn"}
          header={
            <div>
              {customColumnCollapsed && (
                <div>
                  <Button
                    icon="pi pi-chevron-right"
                    onClick={() => setCustomColumnCollapsed(false)}
                  />
                </div>
              )}
              {!customColumnCollapsed && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{ alignSelf: "center", marginRight: 10 }}>
                    {customColumnName}
                  </div>
                  <Button
                    icon="pi pi-chevron-left"
                    style={{ marginRight: 10 }}
                    onClick={() => setCustomColumnCollapsed(true)}
                  />
                  <Button
                    icon="pi pi-pencil"
                    onClick={showEditCustomColumnName}
                  />
                </div>
              )}
            </div>
          }
          headerStyle={{
            ...styles.headerStyle(customColumnCollapsed ? 50 : 350),
            ...styles.primeColumnStyle,
          }}
          style={styles.columnStyle(customColumnCollapsed ? 50 : 350)}
          editor={customColumnCollapsed ? undefined : ColumnEditor}
          onEditorSubmit={submitColumn}
          onEditorCancel={cancelColumn}
          onEditorInit={() => {
            setVisibleTooltip(false);
          }}
          body={(r: MyViewForecastRecord) => (
            <ShowMoreWhenHover
              text={r.customColumn ?? ""}
              showMoreInPopup={displayMoreTextPopup}
            />
          )}
          editorValidator={(e) => {
            const element = e.originalEvent.srcElement as EventTarget & {
              className: string;
            };
            e.originalEvent.stopImmediatePropagation();
            return element ? element.className !== "customColumn" : true;
          }}
        />
        <Column
          field="myListStatus"
          header="Status (*)"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) => statusRenderer(r)}
          editor={(r) => (
            <StatusDropdown
              pipelineId={id}
              isPipelineOwner={isPipelineOwner}
              {...r}
            />
          )}
        />
        <Column
          field="country"
          header="Country/Office"
          sortable={true}
          headerStyle={styles.headerStyle(120)}
          style={{ ...styles.columnStyle(120) }}
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          350,
          (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ),
          () => "Name",
          "Title",
          250,
          true,
          "title",
        )}
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.createdOnDate + "Z"))
          }
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsDescriptionColumnBody,
          (x) => x.title,
          "Description",
          250,
          true,
          "description",
        )}
        <Column
          field="primeOrIncumbent"
          header="Suggested Primes/Partners"
          sortable={true}
          // body={(r: MyViewForecastRecord) =>
          //   r.primeOrIncumbent && (
          //     <p
          //       className="primeOrIncumbent"
          //       data-full-abstract={r.primeOrIncumbent}
          //     >
          //       {r.primeOrIncumbent && r.primeOrIncumbent.length > 95
          //         ? `${removeHtmlTags(r.primeOrIncumbent).substring(0, 95)}...`
          //         : r.primeOrIncumbent}
          //     </p>
          //   )
          // }
          body={PAPColumnBody}
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
          className="primeOrIncumbent"
        />
        <Column
          field="quarterlyCallNotes"
          header="Quarterly Forecast Q&A and Notes"
          sortable={true}
          body={QCNColumnBody}
          // body={(r: MyViewForecastRecord) =>
          //   r.quarterlyCallNotes && (
          //     <p
          //       className="quarterlyCallNotes"
          //       data-full-abstract={r.quarterlyCallNotes}
          //     >
          //       {r.quarterlyCallNotes && r.quarterlyCallNotes.length > 100
          //         ? `${removeHtmlTags(r.quarterlyCallNotes).substring(
          //             0,
          //             100,
          //           )}...`
          //         : r.quarterlyCallNotes}
          //     </p>
          //   )
          // }
          headerStyle={styles.headerStyle(650)}
          style={{ ...styles.columnStyle(650) }}
          className="quarterlyCallNotes"
        />
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
        />
        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          headerStyle={styles.headerStyle(140)}
          style={{ ...styles.columnStyle(140) }}
          bodyClassName="aaplanid"
          exportable={true}
        />
        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          headerStyle={styles.headerStyle(100)}
          style={{ ...styles.columnStyle(100) }}
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          headerStyle={styles.headerStyle(200)}
          style={{ ...styles.columnStyle(200) }}
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={(x) => (
            <span
              dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}
            ></span>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />
        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={(x) => (
            <span dangerouslySetInnerHTML={{ __html: x.coCreation }}></span>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedReleaseDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedAwardDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>

      <div
        ref={popupRef}
        onMouseOver={() => {
          setVisibleTooltip(true);
        }}
        style={{ top: bottomOffset ?? "500px", left: leftOffset ?? "400px" }}
        onMouseLeave={() => {
          setVisibleTooltip(false);
        }}
        className={
          visibleTooltip ? "element-tooltip visible" : "element-tooltip hidden"
        }
      >
        <div>
          <p className="tooltip-body">{selectedTextToShow}</p>
        </div>
      </div>
    </div>
  );
}

export function MySuggestionsBusinessForecast({
  decrement,
  isRemove,
  id,
  isPipelineScreen,
  isPipelineOwner,
  setRefreshForecastData,
}: {
  decrement: () => void;
  isRemove: boolean;
  id: number;
  isPipelineScreen?: boolean;
  isPipelineOwner?: boolean;
  setRefreshForecastData?: (value: boolean) => void;
}) {
  const context = useContext(UserContext);
  const styles = useGridStyles();
  const history = useHistory();
  const [data, setData] = useState(Array<MyViewForecastRecord>());
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = React.useState(0); // todo
  const [first, setFirst] = React.useState(0);
  const [sortField, setSortField] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(0);
  const [baseUrl, setBaseUrl] = React.useState("");
  const growl = React.useContext(GrowlContext);
  const [gridWidth, setGridWidth] = React.useState(0);

  const path = history.location.pathname;

  React.useEffect(() => {
    queryData({ first: first, rows: rowsPerPage });

    function handleResize() {
      const { nestedWidth: width, headerWidth } = getWindowDimensions();
      if (width && headerWidth) {
        const viewWidth = width - headerWidth;
        setGridWidth(viewWidth);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function sort(event: { sortField: string; sortOrder: number }) {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    queryData({ first, rows: rowsPerPage, sort: { ...event } });
  }

  const queryData = async (event: {
    first: number;
    rows: number;
    sort?: { sortField: string; sortOrder: number };
  }) => {
    setIsLoading(true);
    //  switch paths depending on where the tables is rendered.

    let url = "/api/businessforecast/mysuggestions";
    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/forecast/suggestion`;
        break;
      case `/mybids`:
        setBaseUrl(url);
        break;
      default:
        break;
    }
    setRowsPerPage(event.rows);
    setFirst(event.first);
    //
    await axios
      .post(
        // TO DO :
        // "/api/businessforecast/mysuggestions"
        // `/api/pipeline/${id}/forecast/suggestion`
        getFullUrl(url, {
          useDedicatedEnvironment: true,
        }),
        {
          sortField: event.sort ? event.sort.sortField : sortField,
          sortOrder: event.sort ? event.sort.sortOrder : sortOrder,
          rowsPerPage: event.rows, // need to send rows since rowsPerPage may not be updated yet
          pageIndex: event.first / event.rows,
        },
        createRequestWithAuthHeaders(context),
      )
      .then((response) => {
        var r: {
          lastUpdatedUtc: string;
          totalItems: number;
          data: ServerRecord[];
        } = response.data;
        const { data, totalItems } = r;
        setTotalRecords(totalItems);

        const records: MyViewForecastRecord[] = data.map((d) => ({
          id: d.forecast.id,
          recordType: d.forecast.status,
          changedColumns: d.forecast.changedColumns,
          isMyList: d.userEdits.isMyList,
          title: d.forecast.awardTitle,
          createdOnDate: d.forecast.createdOn,
          description: d.forecast.awardDescription,
          primeOrIncumbent: d.enhancements.primeOrIncumbent,
          quarterlyCallNotes: d.enhancements.quarterlyCallNotes,
          estimatedCostRange: d.forecast.totalEstimatedCost,
          projectType: d.forecast.awardActionType,
          anticipatedReleaseDate: d.forecast.anticipatedSolicitationReleaseDate,
          anticipatedAwardDate: d.forecast.anticipatedAwardDate,
          smallBusinessSetAside: d.forecast.smallBusinessSetAside,
          sector: d.forecast.sector,
          country: d.forecast.country,
          myListStatus: myListStatus.default, // dont include in my suggestions
          notes: "", // dont include in my suggestions
          customColumn: "", // dont include in my suggestions
          rawRecord: d,

          aaPlanId: d.forecast.aaPlanId,
          fiscalYearofAction: d.forecast.fiscalYearofAction,
          aaSpecialist: d.forecast.aaSpecialist,
          awardLength: d.forecast.awardLength,
          eligibilityCriteria: d.forecast.eligibilityCriteria,
          geographicalCodes: d.forecast.geographicalCodes,
          categoryManagementContractVehicle:
            d.forecast.categoryManagementContractVehicle,
          solicitationNumber: d.forecast.solicitationNumber,
          coCreation: d.forecast.coCreation,
          location: d.forecast.location,
        }));
        setData(records);
        setIsLoading(false);
      })
      .catch(
        tryCatchServerError((m) => {
          growl.current.show({
            severity: "error",
            sticky: true,
            summary: "Export quota reached",
            detail: m,
          });
          setIsLoading(false);
        }),
      );
  };

  let dt = React.useRef<DataTable>(null);

  const addMySuggestion = async (record: MyViewForecastRecord) => {
    let request = createRequestWithAuthHeaders(context);
    await axios
      .post(
        getFullUrl(
          `/api/businessforecast/mylist/${record.rawRecord.forecast.id}`,
          { useDedicatedEnvironment: true },
        ),
        null,
        request,
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        growl.current.show({
          severity: "success",
          summary: "Added",
          detail: `Added forecast '${record.title}' to my list`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  React.useEffect(() => {
    if (isRemove) {
      removeAllMySuggestions();
    }
  }, [isRemove]);

  const removeAllMySuggestions = async () => {
    let request = createAuthenticatedRequest(context);
    await axios
      .delete(
        getFullUrl(
          id
            ? `/api/pipeline/${id}/forecast/suggestion`
            : `/api/businessforecast/mysuggestions`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        request,
      )
      .then(() => {
        setData([]);
        setTotalRecords(0);
        setRowsPerPage(0);
        decrement();
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const addToPipeline = (r: MyViewForecastRecord) => {
    let request = createAuthenticatedRequest(context);
    const sendToServer = async () => {
      try {
        await axios.post(
          getFullUrl(
            `/api/pipeline/${id}/forecast/${r.rawRecord.forecast.id}`,
            { useDedicatedEnvironment: true },
          ),
          null,
          request,
        );
        const newData = [...data].filter((x) => x.id !== r.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        setRefreshForecastData && setRefreshForecastData(true);
        growl.current.show({
          severity: "success",
          summary: "Suggestion added to My Forecast",
        });
      } catch (message) {
        growl.current.show({
          severity: "error",
          summary: "Error removing pipeline from the opportunity",
        });
      }
    };

    sendToServer();
  };
  const removeFromPipeline = (r: MyViewForecastRecord) => {
    const request = createAuthenticatedRequest(context);

    const sendToServer = async () => {
      try {
        await axios.delete(
          getFullUrl(
            `/api/pipeline/${id}/forecast/${r.rawRecord.forecast.id}`,
            { useDedicatedEnvironment: true },
          ),
          request,
        );
        const newData = [...data].filter((x) => x.id !== r.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        growl.current.show({
          severity: "success",
          summary: "Suggestion removed from the pipeline",
        });
      } catch (msg) {
        console.error(msg);
        growl.current.show({
          severity: "error",
          summary: "Error removing pipeline from the opportunity",
        });
      }
    };

    sendToServer();
  };
  const removeMySuggestion = async (
    record: MyViewForecastRecord,
    pipelineId: string | number,
  ) => {
    let request = createAuthenticatedRequest(context);
    await axios
      .delete(
        getFullUrl(
          `/api/pipeline/${pipelineId}/forecast/suggestion/${record.rawRecord.forecast.id}`,
          {
            useDedicatedEnvironment: true,
          },
        ),
        request,
      )
      .then(() => {
        // remove this item from current list
        const newData = [...data].filter((x) => x.id !== record.id);
        setData(newData);
        setTotalRecords(totalRecords - 1);
        setRowsPerPage(rowsPerPage - 1);
        decrement();
        growl.current.show({
          severity: "success",
          summary: "Ignored",
          detail: `Ignored forecast '${record.title}'`,
        });
      })
      .catch(
        tryCatchServerError((message) =>
          growl.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            sticky: true,
          }),
        ),
      );
  };

  const isMyListColumnHeader = (
    <>
      <IconTooltip
        tooltipText={
          "Click “+” to add the opportunity to your relevant pipeline below. The MyBids page will regularly check for updates " +
          "and will note changes to your opportunities in your AidKonekt email. If you click “-” to ignore, " +
          "the opportunity will be removed from this queue, but you will still be able to search for it under the relevant “Pipelines” subsection."
        }
      />
      &nbsp;
      <span>Add/Ignore</span>
    </>
  );

  // dialog
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);

  React.useEffect(() => {
    function onDescriptionClick(event: any) {
      if (!event.target.matches(".forecast_description")) {
        return;
      }

      const container =
        event.target.nodeName === "P"
          ? event.target
          : event.target.querySelector("p");

      const dialogText = container.attributes["data-full-abstract"].value;
      if (!dialogText) return;

      setDialogHeader(container.attributes["data-title"].value);
      setDialogText(dialogText);
      setDialogVisible(true);
    }
    document.addEventListener("click", onDescriptionClick, false);
    return () => {
      document.removeEventListener("click", onDescriptionClick, false);
    };
  }, []);

  const suggestionsDescriptionColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.description}
      dangerouslySetInnerHTML={{
        __html:
          r.description && r.description.length > 100
            ? `${removeHtmlTags(r.description).substring(0, 100)}...`
            : r.description,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.description);
        setDialogVisible(true);
      }}
    ></p>
  );

  const suggestionsPAPColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.primeOrIncumbent}
      dangerouslySetInnerHTML={{
        __html:
          r.primeOrIncumbent && r.primeOrIncumbent.length > 100
            ? `${removeHtmlTags(r.primeOrIncumbent).substring(0, 100)}...`
            : r.primeOrIncumbent,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.primeOrIncumbent);
        setDialogVisible(true);
      }}
    ></p>
  );

  const suggestionsQCNColumnBody = (r: MyViewForecastRecord) => (
    <p
      className="forecast_description"
      data-title={r.title}
      data-full-abstract={r.quarterlyCallNotes}
      dangerouslySetInnerHTML={{
        __html:
          r.quarterlyCallNotes && r.quarterlyCallNotes.length > 100
            ? `${removeHtmlTags(r.quarterlyCallNotes).substring(0, 100)}...`
            : r.quarterlyCallNotes,
      }}
      onClick={() => {
        setDialogHeader(r.title);
        setDialogText(r.quarterlyCallNotes);
        setDialogVisible(true);
      }}
    ></p>
  );

  const [isGridFullScreen, setIsGridFullScreen] = React.useState(false);
  const [disableExcel, setDisableExcel] = React.useState(false);

  var getExcelData = async (): Promise<MyViewForecastRecord[]> => {
    let url = "/api/businessforecast/mysuggestions";
    switch (path) {
      case `/pipeline/${id}`:
        url = `/api/pipeline/${id}/forecast/suggestion`;
        break;
      case `/mybids`:
        setBaseUrl(url);
        break;
      default:
        break;
    }
    var result = Array<MyViewForecastRecord>();

    // TO DO : Clean after full migration
    switch (path) {
      case `/pipeline/${id}`:
        await axios
          .post(
            getFullUrl(url, {
              useDedicatedEnvironment: true,
            }),
            {
              sortField: sortField,
              sortOrder: sortOrder,
              rowsPerPage: 1000,
            },
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var data = response.data.data;

            var records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              primeOrIncumbent: removeHtmlTagsExceptLink(
                d.enhancements.primeOrIncumbent,
              ),
              quarterlyCallNotes: removeHtmlTagsExceptLink(
                d.enhancements.quarterlyCallNotes,
              ),
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: d.userEdits.myListStatus,
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,
              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: removeHtmlTagsExceptLink(
                d.forecast.geographicalCodes,
              ),
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTagsExceptLink(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            result = records;
          })
          .catch((error) => {
            alert("error fetching data");
          });
        return result;
      default:
        await axios
          .post(
            getFullUrl(url, {
              useDedicatedEnvironment: true,
            }),
            {
              sortField: sortField,
              sortOrder: sortOrder,
            },
            createRequestWithAuthHeaders(context),
          )
          .then((response) => {
            var data = response.data.data;

            var records: MyViewForecastRecord[] = data.map((d) => ({
              id: d.forecast.id,
              recordType: d.forecast.status,
              changedColumns: d.forecast.changedColumns,
              isMyList: d.userEdits.isMyList,
              title: d.forecast.awardTitle,
              createdOnDate: d.forecast.createdOn,
              description: d.forecast.awardDescription,
              primeOrIncumbent: removeHtmlTagsExceptLink(
                d.enhancements.primeOrIncumbent,
              ),
              quarterlyCallNotes: removeHtmlTagsExceptLink(
                d.enhancements.quarterlyCallNotes,
              ),
              estimatedCostRange: d.forecast.totalEstimatedCost,
              projectType: d.forecast.awardActionType,
              anticipatedReleaseDate:
                d.forecast.anticipatedSolicitationReleaseDate,
              anticipatedAwardDate: d.forecast.anticipatedAwardDate,
              smallBusinessSetAside: d.forecast.smallBusinessSetAside,
              sector: d.forecast.sector,
              country: d.forecast.country,
              myListStatus: d.userEdits.myListStatus,
              notes: d.userEdits.notes || "",
              customColumn: d.userEdits.customColumn || "",
              rawRecord: d,
              aaPlanId: d.forecast.aaPlanId,
              fiscalYearofAction: d.forecast.fiscalYearofAction,
              aaSpecialist: d.forecast.aaSpecialist,
              awardLength: d.forecast.awardLength,
              eligibilityCriteria: d.forecast.eligibilityCriteria,
              geographicalCodes: removeHtmlTagsExceptLink(
                d.forecast.geographicalCodes,
              ),
              categoryManagementContractVehicle:
                d.forecast.categoryManagementContractVehicle,
              solicitationNumber: d.forecast.solicitationNumber,
              coCreation: removeHtmlTagsExceptLink(d.forecast.coCreation),
              location: d.forecast.location,
            }));
            result = records;
          })
          .catch((error) => {
            alert("error fetching data");
          });
        return result;
    }
  };

  const getExportableDataTable = (
    data: MyViewForecastRecord[],
  ): TableProperties => {
    const columns = [
      { name: "Country/Office" },
      { name: "Title" },
      { name: "Type" },
      { name: "Date record created/changed" },
      { name: "Description" },
      { name: "Sector" },
      { name: "Suggested Primes/Partners" },
      { name: "Quarterly Forecast Q&A and Notes" },
      { name: "ID" },
      { name: "Category Management Contract Vehicle" },
      { name: "Cost" },
      { name: "Award/Action Type" },
      { name: "Geographical Codes" },
      { name: "Co-creation" },
      { name: "Anticipated Release Date" },
      { name: "Anticipated Award Date" },
      { name: "Award Length" },
      { name: "Small Business Set-Aside" },
      { name: "Eligibility Criteria" },
      { name: "Solicitation Number" },
      { name: "A&A Specialist" },
      { name: "Fiscal Year of Action" },
      { name: "Location" },
    ];

    let rows: any[][] = [];

    if (data.length == 0) {
      rows.push(genEmptyRow(columns.length));
    } else {
      for (let i = 0; i < data.length; i++) {
        const record = data[i];

        rows.push([
          removeHtmlTagsExceptLink(record.country),
          removeHtmlTagsExceptLink(record.title),
          record.recordType === businessForecastRecordType.newRecord ||
          record.recordType === businessForecastRecordType.modifiedRecord
            ? "Active"
            : "Closed",
          getDateString(parseISO(record.createdOnDate + "Z")),
          removeHtmlTagsExceptLink(record.description).replace(
            /(<([^>]+)>)/gi,
            "",
          ),
          record.sector,
          record.primeOrIncumbent
            ? record.primeOrIncumbent.replace(/(<([^>]+)>)/gi, "")
            : record.primeOrIncumbent,
          record.quarterlyCallNotes
            ? record.quarterlyCallNotes.replace(/(<([^>]+)>)/gi, "")
            : record.quarterlyCallNotes,
          record.aaPlanId,
          record.categoryManagementContractVehicle,
          record.estimatedCostRange,
          record.projectType,
          record.geographicalCodes
            ? record.geographicalCodes.replace(/(<([^>]+)>)/gi, "")
            : record.geographicalCodes,
          record.coCreation
            ? record.coCreation.replace(/(<([^>]+)>)/gi, "")
            : record.coCreation,
          record.anticipatedReleaseDate,
          record.anticipatedAwardDate,
          record.awardLength,
          record.smallBusinessSetAside,
          removeHtmlTagsExceptLink(record.eligibilityCriteria),
          record.solicitationNumber,
          record.aaSpecialist,
          record.fiscalYearofAction,
          record.location,
        ]);
      }
    }

    return buildExcelTable("MyBidsExport", columns, rows);
  };

  const tableHeader = (
    <div className="p-grid p-justify-end p-align-center">
      <div
        className="p-col topLeftColumnHeader topRightColumnHeader"
        style={{ textAlign: "left" }}
      >
        <div className="p-col"></div>
      </div>
      <div className="p-sm-12 p-md-12 p-lg-4 p-xl-6 p-justify-end p-grid p-align-center buttonsHeader">
        <div className="button-export">
          <PButton
            type="button"
            icon="pi pi-file-excel"
            className="aid-blu-btn"
            style={{ ...styles.tableHeaderButton, height: "auto" }}
            tooltip="Excel"
            tooltipOptions={{ position: "top" }}
            disabled={disableExcel}
            onClick={async () => {
              setDisableExcel(true);
              generateExcel(
                "MySuggestions+",
                `Aidkonekt_mySuggestions_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData ? await getExcelData() : data,
                ),
              ).then(() => {
                setDisableExcel(false);
              });
            }}
          />
        </div>
        <div className="button-export">
          <PButton
            type="button"
            className="aid-blu-btn"
            icon={
              isGridFullScreen
                ? "pi pi-window-minimize"
                : "pi pi-window-maximize"
            }
            iconPos="left"
            onClick={(e) => {
              if (isGridFullScreen) {
                MinimizeGrid(e.target as HTMLElement);
              } else {
                MaximizeGrid(e.target as HTMLElement, () =>
                  setIsGridFullScreen(false),
                );
              }

              setIsGridFullScreen(!isGridFullScreen);
            }}
            tooltip={isGridFullScreen ? "Minimize" : "Maximize"}
            tooltipOptions={{ position: "top" }}
            style={{ ...styles.tableHeaderButton, height: "auto" }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-grid p-dir-col">
      <Popover
        dialogHeader={dialogHeader}
        isVisible={dialogVisible}
        dialogText={dialogText}
        setDialogVisible={setDialogVisible}
      />

      <DataTable
        value={data}
        style={{ marginTop: 20, width: `${gridWidth - 60}px` }}
        paginator={true}
        rows={rowsPerPage}
        totalRecords={totalRecords}
        lazy={true}
        first={first}
        onPage={queryData}
        header={tableHeader}
        loading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={sort}
        scrollable={true}
        scrollHeight="400px"
        ref={dt}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rowsPerPageOptions={rowsPerPageOptionsStandard}
      >
        {isPipelineScreen && isPipelineOwner && (
          <Column
            field="isMyList"
            header={isMyListColumnHeader}
            sortable={true}
            headerStyle={styles.headerStyle(150)}
            style={styles.columnStyle(100)}
            body={(r: MyViewForecastRecord) => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ActionButtons.AddToMyListButton
                  // add={() => addMySuggestion(r)} />
                  add={() => addToPipeline(r)}
                />
                <ActionButtons.RemoveFromMyListButton
                  remove={() => removeMySuggestion(r, id)}
                  //remove={() => removeFromPipeline(r)}
                />
              </div>
            )}
          />
        )}
        <Column
          field="country"
          header="Country/Office"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150), wordBreak: "break-word" }}
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          350,
          (x) => (
            <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
          ),
          () => "Name",
          "Title",
          250,
          true,
          "title",
        )}
        <Column
          field="recordType"
          header="Type"
          headerStyle={styles.headerStyle(100)}
          style={styles.columnStyle(100)}
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            r.recordType === businessForecastRecordType.newRecord ||
            r.recordType === businessForecastRecordType.modifiedRecord
              ? "Active"
              : "Closed"
          }
        />
        <Column
          field="createdOnDate"
          header="Date Record Created/Changed"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.createdOnDate + "Z"))
          }
        />
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsDescriptionColumnBody,
          (x) => x.title,
          "Description",
          250,
          true,
          "description",
        )}
        <Column
          field="sector"
          header="Sector"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
        />

        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsPAPColumnBody,
          (x) => x.title,
          "Suggested Primes/Partners",
          250,
          true,
          "primeOrIncumbent",
        )}
        {GetCustomColumnShortened<MyViewForecastRecord>(
          styles,
          550,
          suggestionsQCNColumnBody,
          (x) => x.title,
          "Quarterly Forecast Q&A and Notes",
          250,
          true,
          "quarterlyCallNotes",
        )}
        {/**AAPlanId */}
        <Column
          field="aaPlanId"
          header="ID"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaplanid"
          exportable={true}
        />
        {/**CategoryManagementContractVehicle */}
        <Column
          field="categoryManagementContractVehicle"
          header="Category Management Contract Vehicle"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="categorymanagementcontractvehicle"
          exportable={true}
        />

        <Column
          field="estimatedCostRange"
          header="Cost"
          sortable={true}
          headerStyle={styles.headerStyle(100)}
          style={{ ...styles.columnStyle(100) }}
        />
        <Column
          field="projectType"
          header="Award/Action Type"
          sortable={true}
          headerStyle={styles.headerStyle(200)}
          style={{ ...styles.columnStyle(200) }}
        />

        {/**Geographical Codes */}
        <Column
          field="geographicalCodes"
          header={geographicalCodesHeader()}
          sortable={true}
          body={(x) => (
            <p dangerouslySetInnerHTML={{ __html: x.geographicalCodes }}></p>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="geographicalcodes"
          exportable={true}
        />
        {/**Co-creation */}
        <Column
          field="coCreation"
          header="Co-creation"
          sortable={true}
          body={(x) => (
            <p dangerouslySetInnerHTML={{ __html: x.coCreation }}></p>
          )}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="cocreationparsed"
          exportable={true}
        />

        <Column
          field="anticipatedReleaseDate"
          header="Anticipated Release Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedReleaseDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />
        <Column
          field="anticipatedAwardDate"
          header="Anticipated Award Date"
          sortable={true}
          body={(r: MyViewForecastRecord) =>
            getDateString(parseISO(r.anticipatedAwardDate))
          }
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Award Length */}
        <Column
          field="awardLength"
          header="Award Length"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="awardlength"
          exportable={true}
        />

        <Column
          field="smallBusinessSetAside"
          header="Small Business Set-Aside"
          sortable={true}
          headerStyle={styles.headerStyle(150)}
          style={{ ...styles.columnStyle(150) }}
        />

        {/**Eligibility Criteria */}
        <Column
          field="eligibilityCriteria"
          header="Eligibility Criteria"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="eligibilitycriteria"
          exportable={true}
        />

        {/**Solicitation Number*/}
        <Column
          field="solicitationNumber"
          header="Solicitation Number"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="solicitationnumber"
          exportable={true}
        />

        {/**A&A Specialist*/}
        <Column
          field="aaSpecialist"
          header="A&A Specialist"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="aaspecialist"
          exportable={true}
        />

        {/**Fiscal Year of Action*/}
        <Column
          field="fiscalYearofAction"
          header="Fiscal Year of Action"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="fiscalyearofaction"
          exportable={true}
        />

        {/**Location*/}
        <Column
          field="location"
          header="Location"
          sortable={true}
          headerStyle={styles.headerStyle(250)}
          style={{ ...styles.columnStyle(250) }}
          bodyClassName="location"
          exportable={true}
        />
      </DataTable>
    </div>
  );
}
