import React, { ChangeEventHandler, FC, useCallback, useMemo } from "react";
import {
  Box,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DropdownOption } from "../../../components/Widgets/Inputs/Input";
import { useHistory } from "react-router-dom";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../../utils/Helpers/extractDataFromSearchParams";

interface OverviewFiltersProps {
  onlyInput?: boolean;
}

const options: DropdownOption[] = [
  { value: "today", label: "Last 24 hours" },
  { value: "last7", label: "Last 7 days" },
  { value: "lastMonth", label: "Last Month" },
  // { value: "lastYear", label: "Last Year" },
];

const OverviewFilters: FC<OverviewFiltersProps> = (props) => {
  const { onlyInput = false } = props;

  const theme = useTheme();
  const history = useHistory();

  const value = useMemo(
    () =>
      getSearchParamValue<string>(
        history.location.search,
        "updatesRange",
        "today",
      ),
    [history.location],
  );

  const onChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setUrlParams(
        history,
        { updatesRange: e.target.value as string },
        { replace: true },
      );
    },
    [history],
  );

  return onlyInput ? (
    <TextField
      value={value}
      SelectProps={{
        onChange,
      }}
      size={"small"}
      fullWidth
      select
      InputProps={{
        sx: {
          backgroundColor: theme.palette.background.paper,
          textAlign: "left",
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Box>
            <Typography variant="body1" fontSize="var(--Input-fontSize, 1rem)">
              {option.label}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  ) : (
    <Box
      sx={
        {
          // backgroundColor: theme.palette.primary.light,
          // px: 2,
          // py: 1,
          // borderTop: `solid 1px ${theme.palette.divider}`,
          // borderBottom: `solid 1px ${theme.palette.divider}`,
        }
      }
    >
      <TextField
        value={value}
        SelectProps={{
          onChange,
        }}
        size={"small"}
        fullWidth
        select
        InputProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            textAlign: "left",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Box>
              <Typography
                variant="body1"
                fontSize="var(--Input-fontSize, 1rem)"
              >
                {option.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default OverviewFilters;
