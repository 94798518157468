import React, { FC, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DropdownOption } from "../../../components/Widgets/Inputs/Input";
import { useHistory } from "react-router-dom";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../../utils/Helpers/extractDataFromSearchParams";
import Stack from "@mui/material/Stack";
import { debounce } from "lodash";
import { Clear } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { RootState, useSelector } from "../../../store";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import OverviewFilters from "../Overview/OverviewFilters";

interface UpdatesDetailsFiltersProps {
  loading: boolean;
}

const rangeOptions: DropdownOption[] = [
  { value: "today", label: "Last 24 hours" },
  { value: "last7", label: "Last 7 days" },
  { value: "lastMonth", label: "Last Month" },
  // { value: "lastYear", label: "Last Year" },
];

const opportunityTypesOptions: DropdownOption[] = [
  { value: "All", label: "All opportunities" },
  { value: "Forecast", label: "Forecasts" },
  { value: "Contract", label: "Contracts" },
  { value: "Grant", label: "Grants" },
];

const UpdatesDetailsFilters: FC<UpdatesDetailsFiltersProps> = (props) => {
  const { loading } = props;

  const { items, fetchStatus } = useSelector(
    (state: RootState) => state.pipelines,
  );

  const theme = useTheme();
  const history = useHistory();

  const queryInputRef = useRef<HTMLInputElement>(null);

  const opportunityTypeValue = useMemo(
    () =>
      getSearchParamValue<("Forecast" | "Contract" | "Grant" | "All")[]>(
        history.location.search,
        "opportunitiesType",
        ["All"],
        { multiple: true },
      ),
    [history.location],
  );
  const pipelineValue = useMemo(
    () =>
      getSearchParamValue<string[]>(
        history.location.search,
        "pipelineValue",
        ["all"],
        { multiple: true },
      ),
    [history.location, items],
  );

  const rangeValue = useMemo(
    () =>
      getSearchParamValue<string>(
        history.location.search,
        "opportunitiesRange",
        "today",
      ),
    [history.location],
  );

  const queryValue = useMemo(
    () =>
      getSearchParamValue<string>(
        history.location.search,
        "opportunitiesKeyword",
        "",
      ),
    [history.location],
  );

  const detailsForPipelines = useMemo(
    () =>
      getSearchParamValue<"true" | "false">(
        history.location.search,
        "detailsForPipelines",
        "true",
      ),
    [history.location],
  );

  const onOpportunityTypeChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const raw = e.target.value as string[];
      const value =
        raw.includes("All") && !opportunityTypeValue.includes("All")
          ? ["All"]
          : raw.includes("All") &&
            raw.length > 1 &&
            opportunityTypeValue.includes("All")
          ? raw.filter((type) => type !== "All")
          : raw;
      setUrlParams(
        history,
        { opportunitiesType: value, pageIndex: "0", pageSize: "20" },
        { replace: true },
      );
    },
    [history.location],
  );
  const onPipelinesChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const raw = e.target.value as string[];
      const value =
        raw.includes("all") && !pipelineValue.includes("all")
          ? ["all"]
          : raw.includes("all") &&
            raw.length > 1 &&
            pipelineValue.includes("all")
          ? raw.filter((type) => type !== "all")
          : raw;
      setUrlParams(
        history,
        { pipelineValue: value, pageIndex: "0" },
        { replace: true },
      );
    },
    [history.location, items],
  );

  const onRangeChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setUrlParams(
        history,
        { opportunitiesRange: e.target.value as string },
        { replace: true },
      );
    },
    [history.location],
  );

  const onQueryChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = useCallback(
    (value: string): void => {
      setUrlParams(history, {
        opportunitiesKeyword: value,
        pageIndex: "0",
        pageSize: "20",
      });
    },
    [history.location],
  );

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), [
    searchCallback,
  ]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.light,
        px: 2,
        py: 1,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        boxShadow: "inset 0px 11px 11px -16px rgba(66, 68, 90, 1)",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row", md: "column", lg: "row" }}
        spacing={1}
      >
        <TextField
          disabled={loading}
          inputRef={queryInputRef}
          defaultValue={queryValue ?? ""}
          fullWidth
          onChange={onQueryChange}
          size={"small"}
          placeholder="Search"
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.paper,
            },
            startAdornment: (
              <SearchIcon
                style={{
                  marginRight: "8px",
                }}
              />
            ),
            endAdornment: queryValue ? (
              <IconButton
                disabled={loading}
                onClick={() => {
                  if (!queryInputRef.current) return;
                  queryInputRef.current.value = "";
                  searchCallback("");
                }}
              >
                <Clear />
              </IconButton>
            ) : undefined,
          }}
        />
        <TextField
          disabled={loading}
          value={opportunityTypeValue ?? []}
          SelectProps={{
            onChange: onOpportunityTypeChange,
            multiple: true,
            renderValue: (value) => {
              const v = value as string[];
              return (
                <Typography
                  variant={"body1"}
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "300px",
                    wordBreak: "break-all",
                  }}
                  noWrap
                >
                  {v.includes("All") ? "All opportunities" : v.join(", ")}
                </Typography>
              );
            },
          }}
          size={"small"}
          fullWidth
          select
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.paper,
              textAlign: "left",
              // minWidth: "185px",
            },
          }}
        >
          {opportunityTypesOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={loading}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Checkbox
                  sx={{ p: 0.5, m: 0 }}
                  checked={opportunityTypeValue.includes(option.value)}
                />
                <Typography
                  variant="body1"
                  fontSize="var(--Input-fontSize, 1rem)"
                >
                  {option.label}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={
            loading ||
            RequestStatus.isFetching(fetchStatus) ||
            detailsForPipelines !== "true"
          }
          value={pipelineValue ?? []}
          SelectProps={{
            // onChange: onRangeChange,
            onChange: onPipelinesChange,
            multiple: true,
            renderValue: (value) => {
              const v = value as string[];
              return (
                <Typography
                  variant={"body1"}
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "300px",
                    wordBreak: "break-all",
                  }}
                  noWrap
                >
                  {v.includes("all")
                    ? "All Pipelines"
                    : items
                        .filter((pipeline) =>
                          v.includes(pipeline.id.toString()),
                        )
                        .map((pipeline) => pipeline.name)
                        .join(", ")}
                </Typography>
              );
            },
          }}
          size={"small"}
          fullWidth
          select
          InputProps={{
            sx: {
              backgroundColor: theme.palette.background.paper,
              textAlign: "left",
            },
          }}
        >
          {[{ id: "all", name: "All Pipelines" }, ...items].map((option) => (
            <MenuItem
              key={option.id.toString()}
              value={option.id.toString()}
              disabled={loading}
              sx={{
                maxWidth: "350px",
                whiteSpace: "normal",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ p: 0.5, m: 0 }}
                  checked={pipelineValue.includes(option.id.toString())}
                />
                <Typography
                  variant="body1"
                  fontSize="var(--Input-fontSize, 1rem)"
                  // sx={{
                  //   // textOverflow: "ellipsis",
                  //   maxWidth: "300px",
                  //   // wordBreak: "break-all",
                  // }}
                  // noWrap={false}
                >
                  {option.name}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </TextField>
        <OverviewFilters onlyInput />
      </Stack>
    </Box>
  );
};

export default UpdatesDetailsFilters;
