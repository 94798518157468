import {
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import { format } from "date-fns";

export const getStickyColumnsConfig = (
  canShare: boolean,
  isFree: boolean,
): Array<StickyColumnConfigProps> => [
  {
    propertyName: "actions",
    label: "Actions",
    width: canShare && !isFree ? 170 : canShare || !isFree ? 130 : 115,
    isSticky: true,
    cellAlign: "center",
    disableModalWithDetails: true,
  },
  {
    propertyName: "status",
    label: "Status",
    // cellAlign: "right",
    // maxWidth: 150,
    width: 200,
    isSticky: true,
    disableModalWithDetails: true,
  },
];

export const getColumnsConfig = (
  isFree: boolean,
  getSortableColumnPropertyName: (_propertyName: string) => string,
): Array<DataColumnConfigProps> => [
  {
    propertyName: "country",
    label: "Office / Country",
    maxNumberOfLines: 1,
    width: 250,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    order: 0,
    isVisible: true,
  },
  {
    propertyName: "awardTitle",
    label: "Name",
    textSx: {
      fontWeight: "bold",
    },
    width: 200,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardDescription",
    label: "Description",
    width: 300,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "primeOrIncumbent",
    label: "Suggested Primes / Partners",
    width: 300,
    order: 0,
    isVisible: true,
    sortable: true,
    disableModalWithDetails: isFree,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    headerTooltip:
      "AidKonekt algorithm determines potential primes based on publicly available data. The (P) means prime has relevant USAID project past performance, and the (R) means the prime has posted a public recruitment ad relevant to this bid.",
  },
  {
    propertyName: "rfpRfiLink",
    label: "Link to Bid Docs",
    order: 0,
    isVisible: true,
    cellAlign: "center",
    headerAlign: "center",
    width: 100,
    disableModalWithDetails: true,
  },
  {
    propertyName: "quarterlyCallNotes",
    label: "Quaterly Forecast Q&A and Notes",
    width: 300,
    order: 0,
    isVisible: true,
    sortable: true,
    disableModalWithDetails: isFree,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    headerTooltip:
      "This column includes updates from USAID's Quarterly Business Forecast call and Q&A, as well as other relevant notes and market context for individual procurements. If the column is empty, that likely means there has not been a recent question on that forecasted opportunity, or it has been posted after the most recent USAID Quarterly Business Forecast call",
  },
  {
    propertyName: "sector",
    label: "Sector",
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "aaPlanId",
    label: "ID",
    width: 100,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "categoryManagementContractVehicle",
    label: "Category Management Contract Vehicle",
    width: 300,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    headerTooltip:
      "Note from the USAID Industry Liaison on this field:" +
      "<br><br>" +
      "The intent of the Category Management Contract Vehicle field is not to show the Tiers, but to show the actual category management vehicle that will be used. USAID is sharing this note internally for Contracting Officers to update as needed." +
      "<br><br>" +
      "See FAQ linked here for more information on the “Tiers” referenced above.",
  },
  {
    propertyName: "totalEstimatedCost",
    label: "Cost",
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardActionType",
    label: "Award/Action Type",
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    width: 150,
  },
  {
    propertyName: "geographicalCodes",
    label: "Geographical Codes",
    width: 200,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    headerTooltip:
      "What do these numbers mean? <br>" +
      "USAID uses the Principal Geographic Code to share who is eligible to apply for funding. Definitions are outlined in ADS 310 <br>" +
      "•937: The United States, the recipient country, and developing countries other than advanced developing countries <br>" +
      "•110: The United States, the independent states of the former Soviet Union, or a developing country, but excluding any country that is a prohibited source <br>" +
      "•935: Any area or country including the recipient country",
  },
  {
    propertyName: "coCreation",
    label: "Co-Creation",
    width: 200,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "anticipatedSolicitationReleaseDate",
    label: "Anticipated Release Date",
    cellFormatter: (value) =>
      value && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "anticipatedAwardDate",
    label: "Anticipated Award Date",
    cellFormatter: (value) =>
      value && new Date(value).toISOString().startsWith("0")
        ? "N/A"
        : format(new Date(value), "dd MMMM yyyy"),
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "awardLength",
    label: "Award Length",
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "smallBusinessSetAside",
    label: "Small Business Set-Aside",
    width: 200,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "eligibilityCriteria",
    label: "Eligibility Criteria",
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "solicitationNumber",
    label: "Solicitation Number",
    width: 200,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "aaSpecialist",
    label: "A&A Specialist",
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "fiscalYearofAction",
    label: "Fiscal Year of Action",
    width: 150,
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
  },
  {
    propertyName: "location",
    label: "Location",
    order: 0,
    isVisible: true,
    sortable: true,
    getSortableColumnPropertyName: getSortableColumnPropertyName,
    width: 120,
  },
  {
    propertyName: "adminActions",
    label: "Admin Actions",
    order: 0,
    isVisible: true,
    disableModalWithDetails: true,
    cellAlign: "center",
    headerAlign: "center",
    hiddenBasedOnRole: true,
    width: 80,
  },
];
