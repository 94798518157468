import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  ContractsFiltersType,
  ContractsPagination,
  getContracts,
  resetShareOpportunitiesStatus,
  shareOpportunities,
} from "../../slices/contracts";
import { rowsPerPageOptionsStandard } from "../../utils/constants/gridsOptions";
import { useForm } from "react-hook-form";
import { RootState, useDispatch, useSelector } from "../../store";
import {
  alpha,
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { useHistory } from "react-router-dom";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import UserContext, {
  isUserInRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import CustomTable, {
  ColumnsSettingsData,
  DataColumnConfigProps,
  StickyColumnConfigProps,
} from "../../components/MaterialTable/Table";
import Button from "@mui/material/Button";
import {
  getContractsColumnsConfig,
  getContractsStickyColumnsConfig,
} from "./contractsTableColumns.config";
import {
  getExcelData,
  getExportableDataTable,
  getSortableColumnPropertyName,
  getTableHeader,
} from "./contractsUtils";
import RowActions from "./RowActions";
import {
  getPipelines,
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  resetPipelinesState,
  setPipelinesForRecordFor,
  updatePipelines,
} from "../../slices/pipelines";
import { PipelineDto } from "../../utils/types/Forecast";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../configs/axios-export.custom";
import toast from "react-hot-toast";
import ShareModal from "../../components/Widgets/USAIDBidSearchComponents/ShareModal";
import {
  getTableSettings,
  patchTableSettings,
  resetTableSettings,
} from "../../slices/user-settings";
import { extractDataFromSearchParams } from "../../utils/Helpers/extractDataFromSearchParams";
import { format } from "date-fns";
import { generateExcel } from "../../services/exporter";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import ContractsFilters from "./ContractsFilters";
import { handleInvalidDatesToast } from "../../utils/Helpers/invalidDatesErrorToastHandler";
import { DropdownOption } from "../../components/Widgets/Inputs/Input";
import ContractsDetailsAndFilesModal from "./ContractsDetailsAndFilesModal";
import ContactInfo from "./ContactInfo";
import { debounce } from "lodash";
import InfoIcon from "@mui/icons-material/Info";

const initialFilters: ContractsFiltersType = {
  filter: "",
  country: [],
  opportunityType: [],
  setAsides: [],
  solicitationNumber: "",
  sortField: "modifiedDateIn",
  sortOrder: -1,
  onlyMyList: false,
  showAwards: false,
  includeUsaid: true,
  includeOthers: false,
  includeMcc: false,
  includeStateDept: false,
  includeCdc: false,
  includeDfc: false,
  fileName: "",
  fileKeyword: "",
  updatedBy: Date.today().addMonths(-6),
  status: "",
  onlyUntrackedInPipeline: false,
};

const initialPagination: ContractsPagination = {
  pageIndex: 0,
  pageSize: rowsPerPageOptionsStandard[0],
};

export const CONTRACTS_COLUMNS_SETTINGS_KEY = "contractsColumnsSettingsKey";
const FILTERS_FORM_ID = "contracts-filters-form";

const Contracts: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const MUITheme = useTheme();

  const context = useContext(UserContext);

  const isPlus = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const canShare = isPlus || isPro;

  const {
    items,
    fetchStatus,
    total,
    lastUpdatedUtc,
    shareOpportunities: { postFetchStatus: shareOpportunitiesFetchStatus },
    dropdownOptions,
  } = useSelector((state: RootState) => state.contracts);
  const {
    rawItems: rawPipelines,
    items: pipelines,
    fetchStatus: pipelinesFetchStatus,
    addOrRemoveFromPipelines: {
      postFetchStatus: addOrRemoveFromPipelinesFetchStatus,
    },
  } = useSelector((state: RootState) => state.pipelines);
  const {
    tableSettings: { columnsOrder, fetchStatus: tableSettingsFetchStatus },
  } = useSelector((state: RootState) => state.userSettings);
  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);

  const getValuesFromUrl = (): {
    filters: ContractsFiltersType;
    pagination: ContractsPagination;
  } => {
    const filters = extractDataFromSearchParams(
      history.location.search,
      Object.keys(initialFilters),
      // ["updatedBy"],
      [],
      [
        "onlyMyList",
        "showAwards",
        "includeUsaid",
        "includeOthers",
        "includeMcc",
        "includeStateDept",
        "includeCdc",
        "includeDfc",
        "onlyUntrackedInPipeline",
      ],
      ["sortOrder"],
      ["country", "opportunityType", "setAsides"],
    );
    const pagination = extractDataFromSearchParams(
      history.location.search,
      Object.keys(initialPagination),
      [],
      [],
      ["pageIndex", "pageSize"],
    ) as ContractsPagination;
    return {
      filters: { ...initialFilters, ...filters },
      pagination: { ...initialPagination, ...pagination },
    };
  };

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<ContractsFiltersType>({
      defaultValues: getValuesFromUrl().filters,
    });

  const [pagination, setPagination] = useState<ContractsPagination>(
    getValuesFromUrl().pagination,
  );
  const [filters, setFilters] = useState<ContractsFiltersType>(
    getValuesFromUrl().filters,
  );
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  //used to catch case when user has filters selected and they are included in url and user clicks on Forecast nav link in sidebar menu
  const [preventFiltersEffectCall, setPreventFiltersEffectCall] =
    useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>([]);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [shareModalOpenFor, setShareModalOpenFor] = useState<any>(null);

  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [detailsModalOpenFor, setDetailsModalOpenFor] = useState<{
    [key: string]: any;
  } | null>(null);

  const [keywordSearchInputValue, setKeywordSearchInputValue] =
    useState<string>("");

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<
    string | number | null
  >(null);

  const stickyColumns = getContractsStickyColumnsConfig(canShare);
  const getDataForRequest = () => {
    return {
      ...pagination,
      ...Object.fromEntries(
        Object.entries(filters)
          .filter(([key, value]) => value !== null)
          .map(([key, value]) =>
            Array.isArray(value)
              ? [
                  key,
                  value.map((v: DropdownOption) =>
                    v.hasOwnProperty("value") ? v.value : v,
                  ),
                ]
              : [key, value],
          ),
      ),
    };
  };

  const handlePageChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    page: number,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: page,
    }));
  };

  const handleRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
      pageSize: +e.target.value,
    }));
  };

  const onKeywordChange = (e) => {
    setKeywordSearchInputValue(e.target.value);
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = (value) => {
    setFilters((prev) => ({ ...prev, filter: value }));
  };

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), []);

  const onFiltersSubmit = (values: ContractsFiltersType) => {
    handleInvalidDatesToast(values, () => {
      setFilters((prev) => ({
        ...prev,
        ...Object.fromEntries(
          Object.entries(values)?.filter(
            ([key, value]) =>
              !key.includes("include") &&
              !key.includes("sort") &&
              key !== "showAwards" &&
              key !== "onlyUntrackedInPipeline",
          ),
        ),
      }));
      handleCloseFilters();
    });
  };

  const handleOpenFilters = (): void => {
    setFiltersOpen(true);
  };

  const handleCloseFilters = (): void => {
    setFiltersOpen(false);
  };

  const handleCancelFilters = (): void => {
    handleCloseFilters();
    setTimeout(() => {
      reset({
        filter: filters.filter,
        country: filters.country,
        opportunityType: filters.opportunityType,
        setAsides: filters.setAsides,
        solicitationNumber: filters.solicitationNumber,
        sortField: filters.sortField,
        sortOrder: filters.sortOrder,
        onlyMyList: filters.onlyMyList,
        showAwards: filters.showAwards,
        includeUsaid: filters.includeUsaid,
        includeOthers: filters.includeOthers,
        includeMcc: filters.includeMcc,
        includeStateDept: filters.includeStateDept,
        includeCdc: filters.includeCdc,
        includeDfc: filters.includeDfc,
        fileName: filters.fileName,
        fileKeyword: filters.fileKeyword,
        updatedBy: filters.updatedBy,
        status: filters.status,
        onlyUntrackedInPipeline: filters.onlyUntrackedInPipeline,
      });
    }, 150);
  };

  const handleClearFilters = (): void => {
    reset(initialFilters);
  };

  const handleOpenShareModal = (item: any): void => {
    setShareModalOpen(true);
    setShareModalOpenFor(item);
  };

  const onShareSubmit = (values: any) => {
    dispatch(
      shareOpportunities({
        context,
        ...values,
      }),
    );
  };

  const handleOpenDetailsModal = (item: any): void => {
    setDetailsModalOpen(true);
    setDetailsModalOpenFor(item);
  };

  const handleCloseDetailsModal = (): void => {
    setDetailsModalOpen(false);
    setTimeout(() => {
      setDetailsModalOpenFor(null);
    }, 150);
  };

  const adjustPipelinesData = async (): Promise<void> => {
    // const chunkSize = 10;
    // const chunksAmount = Math.ceil(rawPipelines.length / chunkSize);
    // const allPipelines: any = [];
    // let fetchStatus: string | null = null;
    //
    // for (let i = 0; i < chunksAmount; ++i) {
    //   const start = i * chunkSize;
    //   const records = await Promise.all(
    //     rawPipelines
    //       .slice(start, start + chunkSize)
    //       .map(async (x: PipelineDto) => {
    //         const sa = await axios.get(
    //           getFullUrl(`/api/pipeline/${x.id}/contract`, {
    //             useDedicatedEnvironment: true,
    //           }),
    //           createAuthenticatedRequest(context),
    //         );
    //         const data = sa.data.data;
    //         return {
    //           id: x.id,
    //           name: x.name,
    //           contracts: data,
    //         };
    //       }),
    //   ).catch((err) => {
    //     fetchStatus = RequestStatus.statuses.ERROR;
    //     i = chunksAmount;
    //     return [];
    //   });
    //
    //   allPipelines.push(
    //     records.map((x) => ({
    //       id: x.id,
    //       name: x.name,
    //       contracts: x.contracts,
    //     })),
    //   );
    // }

    dispatch(
      updatePipelines({
        pipelines: rawPipelines,
        //   allPipelines.flat(),
        // ...(fetchStatus !== null && { fetchStatus }),
      }),
    );
  };

  const handleSaveColumnsConfiguration = (data: {
    [key: string]: Array<ColumnsSettingsData>;
  }): void => {
    dispatch(
      patchTableSettings({
        context,
        key: CONTRACTS_COLUMNS_SETTINGS_KEY,
        configuration: data,
      }),
    );
  };

  const handleOpenAddToPipelines = (item: any): void => {
    const { id } = item;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "contracts",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "contracts",
          recordId: id,
        }),
      );
    }
    // setAddToPipelinesOpen(id);
  };

  const handleCloseAddToPipelines = (): void => {
    // dispatch(resetPipelinesForRecord());
    setAddToPipelinesOpen(null);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(pipelinesForRecordFor);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (
      RequestStatus.isDone(tableSettingsFetchStatus) ||
      RequestStatus.isError(tableSettingsFetchStatus)
    ) {
      setColumns(
        getContractsColumnsConfig(getSortableColumnPropertyName)
          .map((column, idx) => ({
            ...column,
            order:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.order ?? idx,
            isVisible:
              columnsOrder.find(
                (_column) => _column.propertyName === column.propertyName,
              )?.isVisible ?? column.isVisible,
          }))
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [tableSettingsFetchStatus, getSortableColumnPropertyName]);

  useEffect(() => {
    //  load pipeline data
    dispatch(getPipelines(context));
    dispatch(
      getTableSettings({ context, key: CONTRACTS_COLUMNS_SETTINGS_KEY }),
    );

    return () => {
      setColumns([]);
      dispatch(resetTableSettings());
      dispatch(resetShareOpportunitiesStatus());
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(resetPipelinesState());
      dispatch(resetPipelinesForRecord());
    };
  }, []);

  useEffect(() => {
    if (
      !RequestStatus.isNull(fetchStatus) &&
      history.location.search.length === 0
    ) {
      setPreventFiltersEffectCall(true);
    } else {
      //todo: define fetching
      dispatch(
        getContracts({
          context,
          params: {
            ...getDataForRequest(),
          },
        }),
      );
    }
  }, [history.location]);

  useEffect(() => {
    if (preventFiltersEffectCall) {
      setPagination(initialPagination);
      setFilters(initialFilters);
      reset(initialFilters);
      setTimeout(() => setPreventFiltersEffectCall(false), 150);
    }
  }, [preventFiltersEffectCall]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus))
      history.push(
        `/contracts${buildQuery({
          ...getDataForRequest(),
        })}`,
      );
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (!RequestStatus.isNull(fetchStatus) && !preventFiltersEffectCall) {
      if (pagination.pageIndex > 0)
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
      else
        history.push(
          `/contracts${buildQuery({
            ...getDataForRequest(),
          })}`,
        );
    }
  }, [filters, preventFiltersEffectCall]);

  useEffect(() => {
    //it differs from regular fetch status value assignment since this requires updating the pipelines with additional data
    if (RequestStatus.isHalfDone(pipelinesFetchStatus)) {
      adjustPipelinesData();
    } else if (RequestStatus.isError(pipelinesFetchStatus)) {
      toast.error(
        "Couldn't load pipelines details for Contracts. Try again later.",
      );
      dispatch(resetPipelinesState());
    }
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    if (!RequestStatus.isNull(addOrRemoveFromPipelinesFetchStatus)) {
      if (RequestStatus.isDone(addOrRemoveFromPipelinesFetchStatus))
        toast.success("Pipelines modified");
      else if (RequestStatus.isError(addOrRemoveFromPipelinesFetchStatus))
        toast.error("There was an error performing this operation.");
      dispatch(getPipelines(context));
      addToPipelinesOpen && handleCloseAddToPipelines();
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      dispatch(
        getContracts({
          context,
          params: {
            ...getDataForRequest(),
          },
        }),
      );
    }
  }, [addOrRemoveFromPipelinesFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(shareOpportunitiesFetchStatus)) {
      toast.success("Opportunity shared successfully");
      dispatch(resetShareOpportunitiesStatus());
      setShareModalOpen(false);
    }
    if (RequestStatus.isError(shareOpportunitiesFetchStatus))
      toast.error("There was an error sharing this opportunity.");
  }, [shareOpportunitiesFetchStatus]);

  return (
    <>
      <ContractsDetailsAndFilesModal
        open={detailsModalOpen}
        openFor={detailsModalOpenFor}
        handleCloseDetailsModal={handleCloseDetailsModal}
      />
      <ShareModal
        open={shareModalOpen}
        handleClose={() => setShareModalOpen(false)}
        openFor={shareModalOpenFor}
        onSubmit={onShareSubmit}
        shareFetchStatus={shareOpportunitiesFetchStatus}
        type={"contracts"}
      />
      <Drawer
        open={filtersOpen}
        onClose={handleCancelFilters}
        variant={"temporary"}
        anchor={"right"}
        sx={{
          zIndex: 2147483639, //to be sure that the drawer is over the FAQ side button
        }}
        PaperProps={{
          sx: {
            width: "480px",
            "& > div": {
              px: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            backgroundColor: MUITheme.palette.secondary.light,
            borderBottom: `solid 3px ${MUITheme.palette.secondary.main}`,
            py: 3,
          }}
        >
          <FiltersIcon />
          <Typography variant={"h5"}>Filters</Typography>
        </Box>
        <Box
          sx={{
            py: 2,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <form
            onSubmit={handleSubmit(onFiltersSubmit)}
            id={FILTERS_FORM_ID}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <ContractsFilters control={control} />
          </form>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            py: 2,
          }}
        >
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleCancelFilters}
          >
            Cancel
          </Button>
          <Button
            variant={"secondaryContained"}
            color={"secondary"}
            onClick={handleClearFilters}
          >
            Clear
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            // onClick={handleFilterData}
            type={"submit"}
            form={FILTERS_FORM_ID}
          >
            Save
          </Button>
        </Box>
      </Drawer>
      {columns.length > 0 && (
        <CustomTable
          config={{
            data: items.map((item) => ({
              ...item,
              actions: (
                <RowActions
                  onShareClick={() => handleOpenShareModal(item)}
                  item={item}
                  canShare={canShare}
                  onViewDetailsClick={() => handleOpenDetailsModal(item)}
                  addToPipelinesOpen={addToPipelinesOpen}
                  setAddToPipelinesOpen={setAddToPipelinesOpen}
                  handleOpenAddToPipelines={handleOpenAddToPipelines}
                  handleCloseAddToPipelines={handleCloseAddToPipelines}
                />
              ),
              contactInfo: ContactInfo({ data: item.pointOfContactOne }), //<ContactInfo data={item.pointOfContactOne} />,
              secondaryContactInfo: ContactInfo({
                data: item.pointOfContactTwo,
              }), //(<ContactInfo data={item.pointOfContactTwo} />),
            })),
            columns,
            stickyColumns,
            header: getTableHeader(MUITheme, {
              fetchStatus,
              inputValue: keywordSearchInputValue,
              allFilters: {
                pagination,
                filters: Object.fromEntries(
                  Object.entries(filters)
                    .filter(([key, value]) => !key.includes("sort"))
                    .map(([key, value]) => [key, value]),
                ),
                sortOption: {
                  sortField: filters.sortField ?? "",
                  sortOrder: filters.sortOrder ?? 0,
                },
              },
              lastUpdated: lastUpdatedUtc
                ? `${format(
                    new Date(lastUpdatedUtc),
                    "dd MMMM yyyy",
                  )}, ${format(new Date(lastUpdatedUtc), "HH:mm ")}`
                : "N/A",
              onChange: onKeywordChange,
              handleOpenFilters,
              setFilters,
            }),
            exportExcelFunc: async () =>
              generateExcel(
                "Contracts+",
                `Aidkonekt_contracts_plus_${new Date().getFullYear()}`,
                getExportableDataTable(
                  getExcelData
                    ? await getExcelData(context, getDataForRequest(), total)
                    : items,
                ),
              ),
            columnsConfiguration: {
              allowColumnsConfiguration: true,
              onSaveColumnsConfiguration: handleSaveColumnsConfiguration,
              columnsSettingsKey: CONTRACTS_COLUMNS_SETTINGS_KEY,
            },
          }}
          loading={
            RequestStatus.isFetching(fetchStatus) ||
            RequestStatus.isHalfDone(pipelinesFetchStatus) ||
            RequestStatus.isFetching(pipelinesFetchStatus)
          }
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          pagination={{
            page: pagination.pageIndex,
            rowsPerPage: pagination.pageSize,
            total,
          }}
          tableContainerComponentSx={{
            mb: 2,
          }}
          onSortClick={(
            column: DataColumnConfigProps | StickyColumnConfigProps,
          ) =>
            setFilters((prev) => {
              const propertyName = getSortableColumnPropertyName(
                column.propertyName,
              );
              return prev.sortField === propertyName
                ? prev.sortOrder === 0
                  ? { ...prev, sortField: propertyName, sortOrder: 1 }
                  : prev.sortOrder === 1
                  ? { ...prev, sortField: propertyName, sortOrder: -1 }
                  : { ...prev, sortField: "", sortOrder: 0 }
                : { ...prev, sortField: propertyName, sortOrder: 1 };
            })
          }
          currentSort={{
            sortField: filters.sortField ?? "",
            sortOrder: filters.sortOrder ?? 0,
          }}
          disableOpenCellDetailsModal={!!addToPipelinesOpen}
        />
      )}
    </>
  );
};

export default Contracts;
