import { TableProperties } from "exceljs";
import { buildExcelTable, genEmptyRow } from "../../services/exporter";
import {
  removeHtmlTags,
  removeHtmlTagsExceptLink,
} from "../../services/dataParser";
import axios from "axios";
import {
  createRequestWithAuthHeaders,
  getFullUrl,
} from "../../configs/axios-export.custom";
import {
  businessForecastRecordType,
  ForecastExtendedFiltersProps,
  ForecastFiltersProps,
  ForecastPagination,
  ForecastRecord,
  ForecastSortOption,
  ServerRecord,
} from "../../utils/types/Forecast";
import { Theme } from "@mui/material/styles";
import React, { Dispatch, ReactNode, SetStateAction } from "react";
import {
  alpha,
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FiltersIcon } from "../../components/Icons/FiltersIcon";
import { SourceIcon } from "../../components/Icons/SourceIcon";
import { LastUpdatedIcon } from "../../components/Icons/LastUpdatedIcon";
import SeverityPill from "../../components/Widgets/SeverityPill";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import ClearIcon from "@mui/icons-material/Clear";
import { compact } from "lodash";
import { buildQuery } from "../../utils/Helpers/queryBuilder";
import Button from "@mui/material/Button";

export const getExportableDataTable = (
  data: ForecastRecord[],
): TableProperties => {
  const columns = [
    { name: "usaid.gov" },
    { name: "Opportunity Action" },
    { name: "Changed Columns" },
    { name: "Country/Office" },
    { name: "Date Record Created/Changed" },
    { name: "Name" },
    { name: "Description" },
    { name: "Sector" },
    { name: "Suggested Primes/Partners" },
    { name: "Quarterly Forecast Q&A and Notes" },
    { name: "Link to Bid Docs" },
    { name: "ID" },
    { name: "Category Management Contract Vehicle" },
    { name: "Cost" },
    { name: "Award/Action Type" },
    { name: "Geographical Codes" },
    { name: "Co-creation" },
    { name: "Anticipated Release Date" },
    { name: "Anticipated Award Date" },
    { name: "Award Length" },
    { name: "Small Business Set-Aside" },
    { name: "Eligibility Criteria" },
    { name: "Solicitation Number" },
    { name: "A&A Specialist" },
    { name: "Fiscal Year of Action" },
    { name: "Location" },
  ];

  let rows: any[][] = [];

  if (data.length == 0) {
    rows.push(genEmptyRow(columns.length));
  } else {
    for (let i = 0; i < data.length; i++) {
      const record = data[i];

      rows.push([
        record.status !== businessForecastRecordType.FinalRecord
          ? !!record?.id
            ? `https://www.usaid.gov/node/${record.id}`
            : ""
          : "",
        record.status === businessForecastRecordType.NewRecord
          ? "New Opportunity Added"
          : record.status === businessForecastRecordType.ModifiedRecord
          ? "Updated Opportunity"
          : "Opportunity Removed",
        record.changedColumns
          ? (record.changedColumns as unknown as string)
              .replace(/\&nbsp;/g, "")
              .replace(/&amp;/g, "&")
          : "",
        record.country
          ? record.country.replace(/\&nbsp;/g, "")?.replace(/&amp;/g, "&")
          : "",
        record.createdOn,
        record.awardTitle
          ? record.awardTitle.replace(/\&nbsp;/g, "")?.replace(/&amp;/g, "&")
          : "",
        removeHtmlTags(record.awardDescription) ?? "",
        record.sector ? removeHtmlTags(record.sector) : "",
        record.primeOrIncumbent && typeof record.primeOrIncumbent === "string"
          ? removeHtmlTagsExceptLink(record.primeOrIncumbent)
          : "",
        record.quarterlyCallNotes
          ? removeHtmlTagsExceptLink(record.quarterlyCallNotes)
          : "",
        record.externalLinkHtml
          ? removeHtmlTagsExceptLink(record.externalLinkHtml)
          : "",
        record.aaPlanId ?? "",
        record.categoryManagementContractVehicle ?? "",
        record.totalEstimatedCost ?? "",
        record.awardActionType ?? "",
        removeHtmlTags(record.geographicalCodes) ?? "",
        removeHtmlTags(record.coCreation) ?? "",
        record.anticipatedSolicitationReleaseDate ?? "",
        record.anticipatedAwardDate ?? "",
        record.awardLength ?? "",
        record.smallBusinessSetAside ?? "",
        record.eligibilityCriteria ?? "",
        record.solicitationNumber ?? "",
        record.aaSpecialist ?? "",
        record.fiscalYearofAction ?? "",
        record.location ?? "",
      ]);
    }
  }

  return buildExcelTable("ForecastExport", columns, rows);
};

export const getExcelData = async (
  context,
  queryDto,
  totalItems: number = 1000,
): Promise<ForecastRecord[]> => {
  const q = queryDto;
  if (q) q.rowsPerPage = totalItems;
  let result = Array<ForecastRecord>();
  await axios
    .get(
      getFullUrl(`/api/BusinessForecast${buildQuery(q)}`, {
        useDedicatedEnvironment: true,
      }),
      createRequestWithAuthHeaders(context),
    )
    .then((response) => {
      result = response.data.data;
    })
    .catch((error) => {
      alert("error fetching data");
    });
  return result;
};

export const transformData = (data: any): Array<any> => {
  // const records: ForecastRecord[] =
  return (data as Array<ServerRecord>).map((record) => ({
    id: record.forecast.id,
    country: record.forecast.country,
    // createdOn: record.forecast.createdOn,
    isMyList: record.userEdits.isMyList,
    awardTitle: record.forecast.awardTitle,
    awardDescription: record.forecast.awardDescription,
    sector: record.forecast.sector,
    primeOrIncumbent: record.enhancements.primeOrIncumbent,
    quarterlyCallNotes: record.enhancements.quarterlyCallNotes,
    totalEstimatedCost: record.forecast.totalEstimatedCost,
    awardActionType: record.forecast.awardActionType,
    anticipatedSolicitationReleaseDate:
      record.forecast.anticipatedSolicitationReleaseDate,
    anticipatedAwardDate: record.forecast.anticipatedAwardDate,
    smallBusinessSetAside: record.forecast.smallBusinessSetAside,
    changedColumns:
      record.forecast.changedColumns !== null
        ? record.forecast.changedColumns
            .split(", ")
            .map((name) => name.toLowerCase())
        : record.forecast.changedColumns,
    numberIncludedPipelines: record.pipelines.numberIncludedPipelines,
    aaPlanId: record.forecast.aaPlanId,
    fiscalYearofAction: record.forecast.fiscalYearofAction,
    aaSpecialist: record.forecast.aaSpecialist,
    awardLength: record.forecast.awardLength,
    eligibilityCriteria: record.forecast.eligibilityCriteria,
    geographicalCodes: record.forecast.geographicalCodes,
    categoryManagementContractVehicle:
      record.forecast.categoryManagementContractVehicle,
    solicitationNumber: record.forecast.solicitationNumber,
    coCreation: record.forecast.coCreation,
    location: record.forecast.location,
    rawRecord: record,
  }));
};

export const getTableHeader = (
  theme: Theme,
  props: {
    handleOpenFilters: () => void;
    setFilters: Dispatch<SetStateAction<any>>;
    liveTally: number | null;
    lastUpdated: string | null;
    fetchStatus: string;
    inputValue: string;
    onChange: (event) => void;
    // extendedFilters: ForecastExtendedFiltersProps;
    allFilters: {
      pagination: ForecastPagination;
      sortOption: ForecastSortOption;
      filters: ForecastFiltersProps;
      extendedFilters: ForecastExtendedFiltersProps;
    };
    isFree: boolean;
  },
): Array<ReactNode> => {
  const {
    handleOpenFilters,
    setFilters,
    liveTally,
    lastUpdated,
    fetchStatus,
    inputValue,
    onChange,
    // extendedFilters,
    allFilters: { pagination, filters, extendedFilters, sortOption },
    isFree,
  } = props;

  const isDataFetching = RequestStatus.isFetching(fetchStatus);

  const extendedFiltersLength = Object.entries(extendedFilters)
    ?.filter(([key, value]) =>
      Array.isArray(value) ? value.length > 0 : !!value,
    )
    ?.map(([key, value]) => key)?.length;

  const topLine = (
    <Box
      sx={{
        pt: 2,
        pb: 1,
        display: "flex",
        gap: "16px",
        alignItems: "center",
      }}
    >
      <TextField
        value={inputValue}
        onChange={onChange}
        disabled={isFree}
        sx={{
          width: "300px",
        }}
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{ color: theme.palette.action.active }} />
          ),
          endAdornment: (
            <>
              {inputValue?.length > 0 && (
                <IconButton
                  onClick={() => onChange({ target: { value: "" } })}
                  sx={{ mr: 0.5 }}
                  disabled={isDataFetching}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <Box
                sx={{
                  borderLeft: `solid 1px ${theme.palette.divider}`,
                  pl: 0.5,
                }}
              >
                <Tooltip
                  title={
                    isFree ? "Upgrade your plan to filter Forecasts" : "Filters"
                  }
                >
                  <span>
                    <IconButton onClick={handleOpenFilters} disabled={isFree}>
                      <FiltersIcon sx={{ width: "24px", height: "24px" }} />
                    </IconButton>
                    {extendedFiltersLength > 0 && (
                      <Avatar
                        sx={{
                          position: "absolute",
                          height: "16px",
                          width: "16px",
                          top: "2px",
                          right: "2px",
                          fontSize: "12px",
                          backgroundColor: theme.palette.secondary.main,
                          // border: `solid 1px ${theme.palette.secondary.main}`,
                          pointerEvents: "none",
                        }}
                      >
                        {extendedFiltersLength}
                      </Avatar>
                    )}
                  </span>
                </Tooltip>
              </Box>
            </>
          ),
          sx: {
            px: 0.5,
          },
        }}
        // inputProps={{
        //   style: {
        //     padding: "8px",
        //   },
        // }}
        size={"small"}
        placeholder={"Search"}
        // disabled={isDataFetching}
      />
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({ ...prev, newOnly: checked }))
        }
        control={<Switch checked={filters.newOnly} />}
        label={"New only"}
        disabled={isDataFetching}
      />
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({ ...prev, liveOnly: checked }))
        }
        control={<Switch checked={filters.liveOnly} />}
        label={"Current only"}
        disabled={isDataFetching}
      />
      <FormControlLabel
        onChange={(e, checked) =>
          setFilters((prev) => ({
            ...prev,
            OnlyUntrackedInPipeline: checked,
          }))
        }
        control={<Switch checked={filters.OnlyUntrackedInPipeline} />}
        label={"Show Opportunities Not Added to Pipelines"}
        disabled={isDataFetching}
      />
    </Box>
  );

  const bottomLine = (
    <Box
      sx={{
        py: 1,
        display: "flex",
        gap: "16px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        Number of current opportunities on the Business Forecast:{" "}
        {liveTally ?? "N/A"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          "& > *": { display: "flex", alignItems: "center" },
        }}
      >
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <SourceIcon
            color={"secondary"}
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Source: USAID Business Forecast
        </Typography>
        <Typography variant={"subtitle2"} color={"textSecondary"}>
          <LastUpdatedIcon
            sx={{
              height: "16px",
              width: "16px",
              mr: 0.5,
              mt: "-2px",
            }}
          />
          Last updated: {lastUpdated ?? "N/A"}
        </Typography>
      </Box>
    </Box>
  );

  return [topLine, bottomLine];
};

export const getStatusPill = (
  item: ForecastRecord,
  theme: Theme,
  borderRadius?: string,
  height?: number,
): ReactNode => {
  return (
    <SeverityPill
      height={height ?? 24}
      label={
        item.status === businessForecastRecordType.NewRecord
          ? "New"
          : item.status === businessForecastRecordType.ModifiedRecord
          ? "Changed"
          : "Removed"
      }
      color={
        item.status === businessForecastRecordType.NewRecord
          ? theme.palette.primary.main
          : item.status === businessForecastRecordType.ModifiedRecord
          ? theme.palette.secondary.main
          : theme.palette.text.secondary
      }
      backgroundColor={
        item.status === businessForecastRecordType.NewRecord
          ? theme.palette.primary.light
          : item.status === businessForecastRecordType.ModifiedRecord
          ? alpha(theme.palette.secondary.light, 0.5)
          : theme.palette.action.hover
      }
      {...(borderRadius && { borderRadius })}
    />
  );
};

export const getSortableColumnPropertyName = (
  _propertyName: string,
): string => {
  return _propertyName === "awardDescription"
    ? "description"
    : _propertyName === "awardTitle"
    ? "title"
    : _propertyName === "totalEstimatedCost"
    ? "estimatedCostRange"
    : _propertyName === "awardActionType"
    ? "projectType"
    : _propertyName === "anticipatedSolicitationReleaseDate"
    ? "anticipatedReleaseDate"
    : _propertyName;
};
