import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  alpha,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SeverityPill from "../Widgets/SeverityPill";
import { OpenInNewIcon } from "../../assets/icons/material-icons/open-in-new";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import Button from "@mui/material/Button";
import {
  labelSettings,
  SourceType,
  urlSettings,
} from "./opportunityDetailsUtils";
import { History } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import AddToPipelinesTooltip from "../../screens/ForecastV2/AddToPipelinesTooltip";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ShareModal from "../Widgets/USAIDBidSearchComponents/ShareModal";
import { ForecastRecord } from "../../utils/types/Forecast";
import ReplyIcon from "@mui/icons-material/Reply";
import toast from "react-hot-toast";
import { resetShareOpportunitiesStatus } from "../../slices/forecast";
import { ReplyReversedIcon } from "../../assets/icons/material-icons/reply-reversed";

interface TopBarProps {
  pipelinesNumber: number;
  data: Record<
    string,
    {
      label: string;
      value: string;
      icon: JSX.Element;
    }
  >;
  urlDisabled: boolean;
  source: SourceType;
  id: string | number;
  addToPipelinesOpen: boolean;
  setAddToPipelinesOpen: Dispatch<SetStateAction<boolean>>;
  handleOpenAddToPipelines: (item: any) => void;
  handleCloseAddToPipelines: () => void;
  fetchStatus: string;
  item: any;
  shareOpportunitiesFetchStatus: string;
  onShareSubmit?: (values: any) => void;
  handleResetShareOpportunitiesStatus?: () => void;
}

const TopBar: FC<TopBarProps> = (props) => {
  const {
    pipelinesNumber,
    data,
    source,
    urlDisabled,
    id,
    handleOpenAddToPipelines,
    handleCloseAddToPipelines,
    addToPipelinesOpen,
    setAddToPipelinesOpen,
    fetchStatus,
    item,
    shareOpportunitiesFetchStatus,
    onShareSubmit = undefined,
    handleResetShareOpportunitiesStatus = undefined,
  } = props;

  const theme = useTheme();

  const [isDownloadView, setIsDownloadView] = useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [shareModalOpenFor, setShareModalOpenFor] = useState<any>(null);

  const labelConfig = useMemo(
    () => labelSettings(theme, source),
    [theme, source],
  );

  const urlConfig = useMemo(() => urlSettings(source, id), [source, id]);

  const handleOpenShareModal = (): void => {
    setShareModalOpen(true);
    setShareModalOpenFor(item);
  };

  const handleCloseShareModal = (): void => {
    setShareModalOpen(false);
  };

  const handleDownloadPdf = () => {
    const doc = document.getElementById("root");
    if (!doc) return;

    const header = doc.querySelector(".App > .p-grid > .p-col-fixed");
    const body = doc.querySelector(
      ".App > .p-grid.main-container > .p-col-fixed",
    );

    if (!header || !body) return;
    const height = header.clientHeight + body.clientHeight;
    window.scrollTo(0, 0);

    if (doc) {
      setIsDownloadView(true);
      html2canvas(doc, {
        height,
        width: doc.offsetWidth - 230,
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("album", "px", [canvas.width, canvas.height]);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save(
            `${
              item?.awardTitle ??
              item?.title ??
              item?.opportunityTitle ??
              `Opportunity details`
            }.pdf`,
          );
        })
        .finally(() => setIsDownloadView(false));
    } else {
      setIsDownloadView(false);
    }
  };

  useEffect(() => {
    if (RequestStatus.isDone(shareOpportunitiesFetchStatus)) {
      toast.success("Opportunity shared successfully");
      handleResetShareOpportunitiesStatus?.();
      handleCloseShareModal();
    }
    if (RequestStatus.isError(shareOpportunitiesFetchStatus))
      toast.error("There was an error sharing this opportunity.");
  }, [shareOpportunitiesFetchStatus]);

  return (
    <>
      {onShareSubmit && (
        <ShareModal
          open={shareModalOpen}
          handleClose={handleCloseShareModal}
          openFor={shareModalOpenFor}
          onSubmit={onShareSubmit}
          shareFetchStatus={shareOpportunitiesFetchStatus}
          type={source}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& > *": {
              alignItems: "center",
            },
          }}
        >
          <SeverityPill
            label={labelConfig.label}
            height={28}
            margin={"8px 24px 0 0"}
            fontSize={"14px"}
            color={theme.palette.secondary.contrastText}
            backgroundColor={labelConfig.backgroundColor}
            borderRadius={"8px"}
            // removed because html2canvas can't handle box shadows
            // boxShadow={`2px 2px 11px -4px ${labelConfig.boxShadowColor}`}
            icon={labelConfig.icon}
            sx={{
              "& > svg": {
                fill: theme.palette.secondary.contrastText,
                height: "18px !important",
                width: "18px !important",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > *": {
                mr: 3,
                alignItems: "center",
                mt: 1,
              },
            }}
          >
            {Object.values(data).map((_, idx) => (
              <Box
                key={`top-bar-item-${idx}`}
                sx={{
                  display: "flex",
                  gap: "4px",
                  "& > p": { fontSize: "14px" },
                  "& > p, h6": { mt: "2px" },
                  "& > svg": {
                    fill: theme.palette.text.secondary,
                    height: "18px !important",
                    width: "18px !important",
                  },
                }}
              >
                {_.icon}
                <Typography variant={"body2"} color={"textSecondary"}>
                  {_.label}
                </Typography>
                <Typography
                  variant={"subtitle2"}
                  fontWeight={"600"}
                  color={"textPrimary"}
                >
                  {_.value}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                "& > *": { fontSize: "14px", mt: "-4px" },
                height: "22px",
              }}
            >
              <OpenInNewIcon
                sx={{
                  height: "18px !important",
                  width: "18px !important",
                  color: theme.palette.text.secondary,
                }}
              />
              <Button
                variant={"text"}
                component={"a"}
                // fontWeight={"600"}
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: "600",
                  p: 0.5,
                  height: "22px",
                }}
                href={urlConfig.url}
                target={"_blank"}
                rel={"noreferrer noopener"}
                disabled={urlDisabled}
              >
                {urlConfig.label}
              </Button>
            </Box>
          </Box>
        </Box>
        <Stack direction={"row"} spacing={1}>
          {onShareSubmit && (
            <>
              <Tooltip title={"Share opportunity"}>
                <span>
                  <IconButton
                    color={"primary"}
                    sx={{
                      border: "solid 2px",
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }}
                    onClick={handleOpenShareModal}
                  >
                    <ReplyReversedIcon
                      fontSize={"small"}
                      sx={{
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Download PDF"}>
                <span>
                  <IconButton
                    color={"info"}
                    sx={{
                      border: "solid 2px",
                      backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    }}
                    onClick={handleDownloadPdf}
                  >
                    <PictureAsPdfIcon
                      sx={{
                        height: "24px !important",
                        width: "24px !important",
                      }}
                    />
                    {isDownloadView && (
                      <CircularProgress
                        size={16}
                        sx={{
                          position: "absolute",
                          left: "-6px",
                          top: "-6px",
                        }}
                      />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          <Tooltip
            onClose={handleCloseAddToPipelines}
            open={addToPipelinesOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            disableInteractive={false}
            PopperProps={{
              disablePortal: false,
              sx: {
                "&[data-popper-reference-hidden]": {
                  // visibility: "hidden",
                  // "pointer-events": "none",
                },
              },
            }}
            title={
              <AddToPipelinesTooltip
                handleCloseAddToPipelines={handleCloseAddToPipelines}
                item={item}
                source={source}
              />
            }
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: "rgb(97,97,97)",
                  minWidth: "350px",
                },
              },
            }}
          >
            <span>
              <Button
                size={"small"}
                variant={"contained"}
                color={"success"}
                startIcon={
                  <CreateNewFolderOutlinedIcon
                    sx={{
                      height: "24px !important",
                      width: "24px !important",
                    }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  minWidth: "140px",
                  height: "31px !important",
                }}
                onClick={handleOpenAddToPipelines}
              >
                Pipelines ({pipelinesNumber})
                {RequestStatus.isFetching(fetchStatus) && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-13px",
                      right: "-10px",
                    }}
                  >
                    <CircularProgress size={20} thickness={5} />
                  </Box>
                )}
              </Button>
            </span>
          </Tooltip>
          {/*<Tooltip title={"Changelog"}>*/}
          {/*  <span>*/}
          {/*    <Button*/}
          {/*      size={"small"}*/}
          {/*      variant={"contained"}*/}
          {/*      color={"secondary"}*/}
          {/*      sx={{*/}
          {/*        borderRadius: "8px",*/}
          {/*        // minWidth: "140px",*/}
          {/*      }}*/}
          {/*      onClick={handleOnPipelinesClick}*/}
          {/*    >*/}
          {/*      <History*/}
          {/*        sx={{*/}
          {/*          height: "24px !important",*/}
          {/*          width: "24px !important",*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </Button>*/}
          {/*  </span>*/}
          {/*</Tooltip>*/}
        </Stack>
      </Box>
    </>
  );
};

export default TopBar;
