import { getISODateWithAdjustedTimezone } from "../conversion/date-converters";
import { RouterChildContext } from "react-router-dom";

export const extractDataFromSearchParams = (
  search: string,
  keys: Array<string>,
  dateKeys: Array<string> = [],
  booleanKeys: Array<string> = [],
  numberKeys: Array<string> = [],
  arrayKeys: Array<string> = [],
): { [key: string]: any } => {
  const searchParams = new URLSearchParams(search);

  let params: Array<{ key: string; value: any }> = [];
  for (const key of keys) {
    let rawValue = arrayKeys.includes(key)
      ? searchParams.getAll(key)
      : searchParams.get(key);
    if (rawValue) {
      const value = dateKeys.includes(key)
        ? rawValue !== "null"
          ? new Date(
              getISODateWithAdjustedTimezone(new Date(rawValue as string)),
            )
          : null
        : booleanKeys.includes(key)
        ? rawValue === "true"
        : numberKeys.includes(key)
        ? +rawValue
        : rawValue;
      params.push({
        key,
        value,
      });
    }
  }

  return Object.fromEntries(params.map((param) => [param.key, param.value]));
};

export const getSearchParamValue = <T = string | string[] | null>(
  search: string,
  param: string,
  defaultReturn: T,
  options: {
    multiple?: boolean;
  } = {},
) => {
  const { multiple = false } = options;
  const searchParams = new URLSearchParams(search);
  const value = multiple
    ? searchParams.getAll(param)?.length > 0
      ? searchParams.getAll(param)
      : Array.isArray(defaultReturn) && defaultReturn.length > 0
      ? defaultReturn
      : []
    : searchParams.get(param) ?? defaultReturn ?? null;

  return value as unknown as T;
};

export const setUrlParams = (
  history: RouterChildContext["router"]["history"],
  params: Record<string, string | string[]>,
  options: { replace?: boolean; deleteFalsy?: boolean } = {
    replace: false,
    deleteFalsy: false,
  },
) => {
  const { replace, deleteFalsy } = options;
  const searchParams = new URLSearchParams(history.location.search);

  Object.entries(params).map(([key, value]) => {
    if (
      deleteFalsy &&
      ((Array.isArray(value) && value.length === 0) ||
        (value as string)?.length === 0 ||
        [null, ""].includes(value as any))
    ) {
      searchParams.delete(key);
    } else if (Array.isArray(value)) {
      searchParams.delete(key);
      value.forEach((_v) => searchParams.append(key, _v));
    } else searchParams.set(key, value);
  });
  if (replace) {
    history.replace(`${history.location.pathname}?${searchParams.toString()}`);
  } else {
    history.push(`${history.location.pathname}?${searchParams.toString()}`);
  }
};
