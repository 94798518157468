import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Box,
  Card,
  Collapse,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import OverviewFilters from "./OverviewFilters";
import {
  DashboardUpdates,
  UNUSUAL_ACTIVITY_TRIGGER_COUNT,
} from "../../../slices/dashboard";
import Stack from "@mui/material/Stack";
import { format } from "date-fns";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import SeverityPill from "../../../components/Widgets/SeverityPill";
import { ExpandLess } from "@mui/icons-material";
import EnhancementRow from "./EnhancementRow";
import RemovedRow from "./RemovedRow";
import UpdatedRow from "./UpdatedRow";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import { useHistory } from "react-router-dom";
import { getSearchParamValue } from "../../../utils/Helpers/extractDataFromSearchParams";
import { customColors } from "../../../theme/MUITheme";
import {
  getRandomIntArray,
  getRandomIntFromInterval,
} from "../../../utils/Helpers/randomNumbers";
import { RootState, useDispatch, useSelector } from "../../../store";
import {
  getUserSetting,
  patchUserSettings,
} from "../../../slices/user-settings";
import UserContext from "../../../services/UserContext";
import { debounce } from "lodash";
import SummaryRating from "./SummaryRating";

interface OverviewContentProps {
  updates: DashboardUpdates;
}

export const DASHBOARD_SUMMARY_ANIMATION_SETTINGS_KEY =
  "dashboardSummaryAnimationSettingsKey" as const;

const OverviewContent: FC<OverviewContentProps> = (props) => {
  const { updates } = props;

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const { fetchStatus, patchFetchStatus, setting } = useSelector(
    (state: RootState) => state.userSettings.userSettings,
  );

  const showAISection = useMemo(
    () => history.location.pathname === "/dashboard-new-w68f5es4",
    [history.location],
  );

  const [showForecastUpdates, setShowForecastUpdates] = useState<boolean>(true);
  const [showContractsUpdates, setShowContractsUpdates] =
    useState<boolean>(true);
  const [showGrantsUpdates, setShowGrantsUpdates] = useState<boolean>(true);

  const [showOverview, setShowOverview] = useState<boolean>(true);

  const [isSummaryCurrentlySimulated, setIsSummaryCurrentlySimulated] =
    useState<{
      forecast: boolean;
      contracts: boolean;
      grants: boolean;
    }>({
      forecast: false,
      contracts: false,
      grants: false,
    });

  const patchAnimationDate = () => {
    if (updates.forecast && updates.forecast.createdOn) {
      dispatch(
        patchUserSettings({
          context,
          key: DASHBOARD_SUMMARY_ANIMATION_SETTINGS_KEY,
          setting: { animationShownFor: updates.forecast.createdOn },
        }),
      );
    }
  };

  const debouncedPatchAnimationDate = useCallback(
    debounce(patchAnimationDate, 1000),
    [updates.forecast],
  );

  const shouldShowUnusualActivityAlertBasedOnFilter = (): boolean => {
    const range = getSearchParamValue<
      "today" | "last7" | "lastMonth" | "lastYear"
    >(history.location.search, "updatesRange", "today");
    return range === "today";
  };

  const printLetterByLetter = (
    destination: string,
    message: string,
    stopPoints: number[],
    onStop: () => void,
    showAnimation: boolean,
  ) => {
    //todo: remove return when dashboard AI prompts are ready
    if (!showAISection) return;
    if (!showAnimation) {
      document.getElementById(destination)!.innerHTML = message;
      return;
    }
    debouncedPatchAnimationDate();
    let i = 0;
    let interval;
    const intervalSetter = () =>
      setInterval(function () {
        document.getElementById(destination)!.innerHTML += message.charAt(i);
        if (i > message.length) {
          clearInterval(interval);
          onStop();
        } else {
          if (stopPoints.includes(i)) {
            clearInterval(interval);
            const timeout = getRandomIntFromInterval(250, 1250);
            setTimeout(() => {
              i++;
              interval = intervalSetter();
            }, timeout);
          } else {
            i++;
          }
        }
      }, 5);
    interval = intervalSetter();
  };

  useEffect(() => {
    dispatch(
      getUserSetting({
        context,
        key: DASHBOARD_SUMMARY_ANIMATION_SETTINGS_KEY,
      }),
    );
  }, []);

  useEffect(() => {
    if (
      updates.forecast?.summary &&
      !isSummaryCurrentlySimulated.forecast &&
      (RequestStatus.isDone(fetchStatus) || RequestStatus.isError(fetchStatus))
    ) {
      updates?.forecast?.createdOn &&
        setting !== updates.forecast.createdOn &&
        setIsSummaryCurrentlySimulated((prev) => ({ ...prev, forecast: true }));
      printLetterByLetter(
        "forecast-ai-summary",
        updates.forecast.summary,
        getRandomIntArray(
          (updates.forecast.summary.length / 4) * 3,
          getRandomIntFromInterval(2, 5),
        ),
        () =>
          setIsSummaryCurrentlySimulated((prev) => ({
            ...prev,
            forecast: false,
          })),
        updates?.forecast?.createdOn && setting !== updates.forecast.createdOn,
      );
    }
  }, [updates.forecast?.summary, fetchStatus]);

  useEffect(() => {
    if (
      updates.contracts?.summary &&
      !isSummaryCurrentlySimulated.contracts &&
      (RequestStatus.isDone(fetchStatus) || RequestStatus.isError(fetchStatus))
    ) {
      updates?.forecast?.createdOn &&
        setting !== updates.forecast.createdOn &&
        setIsSummaryCurrentlySimulated((prev) => ({
          ...prev,
          contracts: true,
        }));
      printLetterByLetter(
        "contracts-ai-summary",
        updates.contracts.summary,
        getRandomIntArray(
          (updates.contracts.summary.length / 4) * 3,
          getRandomIntFromInterval(2, 5),
        ),
        () =>
          setIsSummaryCurrentlySimulated((prev) => ({
            ...prev,
            contracts: false,
          })),
        updates?.forecast?.createdOn && setting !== updates.forecast.createdOn,
      );
    }
  }, [updates.forecast?.summary, fetchStatus]);

  useEffect(() => {
    if (
      updates.grants?.summary &&
      !isSummaryCurrentlySimulated.grants &&
      (RequestStatus.isDone(fetchStatus) || RequestStatus.isError(fetchStatus))
    ) {
      updates?.forecast?.createdOn &&
        setting !== updates.forecast.createdOn &&
        setIsSummaryCurrentlySimulated((prev) => ({ ...prev, grants: true }));
      printLetterByLetter(
        "grants-ai-summary",
        updates.grants.summary,
        getRandomIntArray(
          (updates.grants.summary.length / 4) * 3,
          getRandomIntFromInterval(2, 5),
        ),
        () =>
          setIsSummaryCurrentlySimulated((prev) => ({
            ...prev,
            grants: false,
          })),
        updates?.forecast?.createdOn && setting !== updates.forecast.createdOn,
      );
    }
  }, [updates.forecast?.summary, fetchStatus]);

  return (
    <>
      <Box
        sx={{
          // px: 2,
          // py: 1,
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          // gap: 1,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            py: 1,
            px: 2,
            borderBottom: `solid 1px ${theme.palette.divider}`,
            backgroundColor: theme.palette.secondary.light,
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            direction={{
              xs: "column",
              sm: "row",
            }}
            sx={{
              alignItems: "center",
              rowGap: 0.5,
              columnGap: 1,
              flexWrap: "wrap",
            }}
            // spacing={1}
          >
            <Typography
              variant={"h6"}
              fontWeight={500}
              sx={{
                color: customColors.dashboardSectionTitle,
              }}
            >
              USAID Updates Overview
            </Typography>
            <SeverityPill
              label={"AI Generated Summary"}
              height={20}
              fontSize={"12px"}
              color={theme.palette.primary.contrastText}
              backgroundColor={theme.palette.primary.main}
              borderRadius={"8px"}
              icon={<AutoAwesomeOutlinedIcon />}
              sx={{
                "& > svg": {
                  fill: theme.palette.secondary.contrastText,
                  height: "16px !important",
                  width: "16px !important",
                },
                fontWeight: 400,
              }}
            />
            <Typography
              variant={"body2"}
              // color={"textSecondary"}
              fontSize={"12px"}
            >
              Last updated:{" "}
              {updates.forecast && updates.forecast.createdOn !== null
                ? format(
                    new Date(updates.forecast.createdOn),
                    "" +
                      // "MM/dd/yyyy 'at' " +
                      "hh:mm aaa",
                  )
                : "N/A"}
              . Refreshes every 30 minutes
            </Typography>
          </Stack>
          <IconButton onClick={() => setShowOverview((prev) => !prev)}>
            <ExpandLess
              sx={{
                transition: "transform 250ms ease-in-out",
                transform: `rotate(${showOverview ? "0deg" : "180deg"})`,
              }}
            />
          </IconButton>
        </Stack>
        <Collapse in={showOverview}>
          {showAISection ? (
            <>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  py: 0.5,
                  px: 2,
                  backgroundColor: "neutral.100",
                  borderBottom: `solid 1px`,
                  borderColor: "neutral.200",
                  alignItems: "center",
                  boxShadow: "inset 0px 11px 11px -16px rgba(66, 68, 90, 1)",
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  fontWeight={600}
                  sx={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Forecast Updates
                </Typography>
                <IconButton
                  onClick={() => setShowForecastUpdates((prev) => !prev)}
                >
                  <ExpandLess
                    sx={{
                      transition: "transform 250ms ease-in-out",
                      transform: `rotate(${
                        showForecastUpdates ? "0deg" : "180deg"
                      })`,
                    }}
                  />
                </IconButton>
              </Stack>
              <Collapse
                in={showForecastUpdates}
                sx={{
                  px: 2,
                }}
              >
                {updates.forecast && (
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={600}
                    component={"p"}
                    sx={{
                      lineHeight: "24px",
                      mb: 2,
                    }}
                  >
                    USAID made changes to {updates.forecast.totalChanges}{" "}
                    opportunities. {updates.forecast.created} opportunities were
                    added, {updates.forecast.updated} opportunities were updated
                    and {updates.forecast.deleted} opportunities were removed.
                  </Typography>
                )}
                {updates.forecast?.unusualActivity &&
                  shouldShowUnusualActivityAlertBasedOnFilter() && (
                    <Alert
                      sx={{
                        mt: 1,
                        borderRadius: "4px",
                        alignItems: "center",
                        p: 0.5,
                        py: 0,
                        borderLeft: `solid 6px ${theme.palette.primary.main}`,
                        mb: 2,
                      }}
                      color={"info"}
                      icon={false}
                    >
                      AidKonekt has noted an unusual amount of activity on the
                      forecast today. There were more than{" "}
                      {UNUSUAL_ACTIVITY_TRIGGER_COUNT} opportunity changed. Our
                      team is reviewing this and will provide an update shortly
                    </Alert>
                  )}
                <Typography component={"p"}>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={500}
                    component={"span"}
                    sx={{
                      lineHeight: "24px",
                      whiteSpace: "pre-wrap",
                    }}
                    id={"forecast-ai-summary"}
                  >
                    {/*{updates.forecast*/}
                    {/*  ? updates.forecast?.summary ?? "No updates"*/}
                    {/*  : ""}*/}
                  </Typography>
                  <Box
                    component={"span"}
                    sx={{
                      display: isSummaryCurrentlySimulated.forecast
                        ? "inline-flex"
                        : "none",
                      "& > *": {
                        animation: "wave 1.3s linear infinite",
                        "&:nth-child(2)": {
                          "animation-delay": "-1.1s",
                        },
                        "&:nth-child(3)": {
                          "animation-delay": "-0.9s",
                        },
                        "@keyframes wave": {
                          "0%, 60%, 100%": {
                            transform: "initial",
                          },
                          "30%": {
                            transform: "translateY(-2px)",
                          },
                        },
                      },
                    }}
                  >
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                  </Box>
                </Typography>
                <SummaryRating
                  currentState={null}
                  aiSummaryId={updates.forecastSummaryId}
                />
              </Collapse>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  py: 0.5,
                  px: 2,
                  backgroundColor: "neutral.100",
                  borderBottom: `solid 1px`,
                  borderColor: "neutral.200",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  fontWeight={600}
                  sx={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Contracts Updates
                </Typography>
                <IconButton
                  onClick={() => setShowContractsUpdates((prev) => !prev)}
                >
                  <ExpandLess
                    sx={{
                      transition: "transform 250ms ease-in-out",
                      transform: `rotate(${
                        showContractsUpdates ? "0deg" : "180deg"
                      })`,
                    }}
                  />
                </IconButton>
              </Stack>
              <Collapse
                in={showContractsUpdates}
                sx={{
                  px: 2,
                }}
              >
                {/*{updates.contracts && (*/}
                {/*  <Typography*/}
                {/*    variant={"subtitle2"}*/}
                {/*    color={"inherit"}*/}
                {/*    fontWeight={600}*/}
                {/*    component={"p"}*/}
                {/*    sx={{*/}
                {/*      lineHeight: "24px",*/}
                {/*      mb: 2,*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Sam.gov posted {updates.contracts.created} new opportunities and{" "}*/}
                {/*    {updates.contracts.newAttachments > 0*/}
                {/*      ? `updated the attachments of ${*/}
                {/*          updates.contracts.newAttachments*/}
                {/*        } ${*/}
                {/*          updates.contracts.newAttachments !== 1*/}
                {/*            ? "opportunities"*/}
                {/*            : "opportunity"*/}
                {/*        }`*/}
                {/*      : "didn't update any attachments"}*/}
                {/*    .*/}
                {/*  </Typography>*/}
                {/*)}*/}
                <Typography component={"p"}>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={500}
                    component={"span"}
                    sx={{
                      lineHeight: "24px",
                      whiteSpace: "pre-wrap",
                    }}
                    id={"contracts-ai-summary"}
                  >
                    {/*{updates.contracts*/}
                    {/*  ? updates.contracts?.summary ?? "No updates"*/}
                    {/*  : ""}*/}
                  </Typography>
                  <Box
                    component={"span"}
                    sx={{
                      display: isSummaryCurrentlySimulated.contracts
                        ? "inline-flex"
                        : "none",
                      "& > *": {
                        animation: "wave 1.3s linear infinite",
                        "&:nth-child(2)": {
                          "animation-delay": "-1.1s",
                        },
                        "&:nth-child(3)": {
                          "animation-delay": "-0.9s",
                        },
                        "@keyframes wave": {
                          "0%, 60%, 100%": {
                            transform: "initial",
                          },
                          "30%": {
                            transform: "translateY(-2px)",
                          },
                        },
                      },
                    }}
                  >
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                  </Box>
                </Typography>
                <SummaryRating
                  currentState={null}
                  aiSummaryId={updates.contractsSummaryId}
                />
              </Collapse>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{
                  py: 0.5,
                  px: 2,
                  backgroundColor: "neutral.100",
                  borderBottom: `solid 1px`,
                  borderColor: "neutral.200",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={"subtitle2"}
                  color={"textPrimary"}
                  fontWeight={600}
                  sx={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Grants Updates
                </Typography>
                <IconButton
                  onClick={() => setShowGrantsUpdates((prev) => !prev)}
                >
                  <ExpandLess
                    sx={{
                      transition: "transform 250ms ease-in-out",
                      transform: `rotate(${
                        showGrantsUpdates ? "0deg" : "180deg"
                      })`,
                    }}
                  />
                </IconButton>
              </Stack>
              <Collapse
                in={showGrantsUpdates}
                sx={{
                  px: 2,
                }}
              >
                {/*{updates.grants && (*/}
                {/*  <Typography*/}
                {/*    variant={"subtitle2"}*/}
                {/*    color={"inherit"}*/}
                {/*    fontWeight={600}*/}
                {/*    component={"p"}*/}
                {/*    sx={{*/}
                {/*      lineHeight: "24px",*/}
                {/*      mb: 2,*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    Grants.gov posted {updates.grants.created} new opportunities and{" "}*/}
                {/*    {updates.grants.newAttachments > 0*/}
                {/*      ? `updated the attachments of ${*/}
                {/*          updates.grants.newAttachments*/}
                {/*        } ${*/}
                {/*          updates.grants.newAttachments !== 1*/}
                {/*            ? "opportunities"*/}
                {/*            : "opportunity"*/}
                {/*        }`*/}
                {/*      : "didn't update any attachments"}*/}
                {/*    .*/}
                {/*  </Typography>*/}
                {/*)}*/}
                <Typography component={"p"}>
                  <Typography
                    variant={"subtitle2"}
                    color={"inherit"}
                    fontWeight={500}
                    component={"span"}
                    sx={{
                      lineHeight: "24px",
                      whiteSpace: "pre-wrap",
                    }}
                    id={"grants-ai-summary"}
                  >
                    {/*{updates.grants*/}
                    {/*  ? updates.grants?.summary ?? "No updates"*/}
                    {/*  : ""}*/}
                  </Typography>
                  <Box
                    component={"span"}
                    sx={{
                      display: isSummaryCurrentlySimulated.grants
                        ? "inline-flex"
                        : "none",
                      "& > *": {
                        animation: "wave 1.3s linear infinite",
                        "&:nth-child(2)": {
                          "animation-delay": "-1.1s",
                        },
                        "&:nth-child(3)": {
                          "animation-delay": "-0.9s",
                        },
                        "@keyframes wave": {
                          "0%, 60%, 100%": {
                            transform: "initial",
                          },
                          "30%": {
                            transform: "translateY(-2px)",
                          },
                        },
                      },
                    }}
                  >
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                    <Typography variant={"h6"}>.</Typography>
                  </Box>
                </Typography>
                <SummaryRating
                  currentState={null}
                  aiSummaryId={updates.grantsSummaryId}
                />
              </Collapse>
            </>
          ) : (
            <Typography
              variant="h6"
              color={"inherit"}
              sx={{
                width: "100%",
                textAlign: "center",
                my: 2,
              }}
            >
              Coming Soon
            </Typography>
          )}
        </Collapse>
      </Box>
    </>
  );
};

export default OverviewContent;
