import Stack from "@mui/material/Stack";
import { IconButton, Typography, useTheme } from "@mui/material";
import { ThumbsUpIcon } from "../../../assets/icons/material-icons/thumbs-up";
import { ThumbsDownIcon } from "../../../assets/icons/material-icons/thumbs-down";
import { useContext, useState } from "react";
import { useDispatch } from "../../../store";
import UserContext from "../../../services/UserContext";
import { sendSummaryFeedback } from "../../../slices/dashboard";

export type SummaryRatingType = "like" | "dislike" | null;

interface SummaryRatingProps {
  currentState: SummaryRatingType;
  aiSummaryId: number | null;
}

export default function SummaryRating({
  currentState,
  aiSummaryId,
}: SummaryRatingProps) {
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const theme = useTheme();

  const [state, setState] = useState<SummaryRatingType>(currentState);
  function like() {
    setState((prev) => (prev === "like" ? null : "like"));
    aiSummaryId !== null &&
      dispatch(
        sendSummaryFeedback({
          context,
          aiSummaryId,
          score: 1,
        }),
      );
  }
  function dislike() {
    setState((prev) => (prev === "dislike" ? null : "dislike"));
    aiSummaryId !== null &&
      dispatch(
        sendSummaryFeedback({
          context,
          aiSummaryId,
          score: -1,
        }),
      );
  }

  return (
    <Stack
      direction={{
        xs: "column",
        sm: "row",
      }}
      sx={{
        gap: 2,
        alignItems: "center",
        my: 1,
        "& > *": {
          fontWeight: 400,
        },
      }}
    >
      <Typography variant={"subtitle2"} color={"textSecondary"}>
        Have you found this summary helpful or accurate?
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          minWidth: "250px",
          alignItems: "center",
          "& > button": {
            "& > svg": {
              height: "16px !important",
              width: "16px !important",
            },
          },
        }}
      >
        <IconButton
          onClick={like}
          {...(state === "like" && { color: "success" })}
        >
          <ThumbsUpIcon />
        </IconButton>
        {state === "like" && (
          <Typography
            variant={"subtitle2"}
            sx={{
              color: theme.palette.success.main,
            }}
          >
            Yes
          </Typography>
        )}
        <IconButton
          onClick={dislike}
          {...(state === "dislike" && { color: "error" })}
          sx={{ ml: 1 }}
        >
          <ThumbsDownIcon />
        </IconButton>
        {state === "dislike" && (
          <Typography variant={"subtitle2"} color={"error"}>
            No
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
