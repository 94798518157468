import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import OpportunityDetails from "../../components/OpportunityDetails/OpportunityDetails";
import { RootState, useDispatch, useSelector } from "../../store";
import { useHistory } from "react-router-dom";
import { getContractDetails } from "../../slices/contracts";
import UserContext, { UserContextType } from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import {
  getPipelinesForRecord,
  resetAddOrRemoveFromPipelinesStatus,
  resetPipelinesForRecord,
  setPipelinesForRecordFor,
} from "../../slices/pipelines";
import toast from "react-hot-toast";
import { getSearchParamValue } from "../../utils/Helpers/extractDataFromSearchParams";
//
// const data = {
//   numberIncludedPipelines: 1,
//   contractOpportunityBase_Id: 758313,
//   id: "47ab3655e02c47fc9bac6f38d71b209c",
//   versionStr: null,
//   version: 2,
//   isMyList: false,
//   notes: "",
//   customColumn: "",
//   title: "USAID/Kosovo - Request for Information",
//   solicitationNumber: "72016723RFI00002",
//   setAside: null,
//   placeOfPerformance: "KOSOVO",
//   agencyName: "USAID",
//   type: "Presolicitation",
//   description:
//     "<p>Dear Interested Parties:&nbsp;</p>\n\n<p>The United States Government, represented by the U.S. Agency for International Development (USAID) Regional Office of Acquisition and Assistance, is seeking input from interested organizations and individuals who may provide comments, opinions and recommendations in response to the questions outlined in this Request for Information (RFI) announcement.&nbsp;</p>\n\n<p>This is an RFI issued solely for information and planning purposes. This is not a Request for Proposals (RFP) and is not to be construed as a commitment by the U.S. Government (USG) to issue a Solicitation or ultimately award a contract, or to pay for any information voluntarily submitted as a result of this RFI.&nbsp;</p>\n\n<p>Responses to this RFI shall not be portrayed as proposals, and will not be accepted by the USG to form a binding contract or agreement. Responders are solely responsible for all expenses associated with responding to this RFI. It should be noted that responding to or providing comment on this RFI will not give any advantage to any organization in any subsequent procurement or funding.&nbsp;</p>\n\n<p>Submission instructions are in the attached document RFI72016723RFI00002.&nbsp;</p>\n\n<p>Thank you for your feedback.&nbsp;</p>\n\n<p></p>\n",
//   primeOrIncumbent: null,
//   postedDateIn: "2023-08-14T10:18:40.048+00:00",
//   solicitationDeadlineResponseIn: "2023-08-28T10:00:00+00:00",
//   classificationCode: "R499",
//   naicsCodes: "541611",
//   lastUpdated: "2023-09-13T05:21:04.322135+00:00",
//   modifiedDateIn: "2023-09-13T03:55:40.01+00:00",
//   pointOfContactOne: {
//     fullName: "Urime A Abdyli",
//     email: "uabdyli@usaid.gov",
//     phone: "",
//     fax: "",
//   },
//   pointOfContactTwo: {
//     fullName: "Claudio Ballesteros",
//     email: "cballesteros@usaid.gov",
//     phone: "",
//     fax: "",
//   },
//   country: "USAID/KOSOVO",
//   awardNumber: null,
//   contractorAwardedName: null,
//   contractorAwardedAmount: null,
//   contractorAwardedAmountAsNumeric: null,
//   contractorAwardedDate: null,
//   createdOn: "2023-08-14T10:21:18.570472+00:00",
//   files: null,
//   status: "INACTIVE",
// };

const ContractsProfilePage: FC = (props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const context = useContext(UserContext);

  const {
    details: { files, matchingFiles, fetchStatus, records },
  } = useSelector((state: RootState) => state.contracts);
  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);
  const { postFetchStatus } = useSelector(
    (state: RootState) => state.pipelines.addOrRemoveFromPipelines,
  );
  const { postFetchStatus: shareOpportunitiesFetchStatus } = useSelector(
    (state: RootState) => state.contracts.shareOpportunities,
  );

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<boolean>(false);

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);

  const id = useMemo(() => {
    const path = history.location.pathname.split("/");
    return path[path.length - 1];
  }, [history]);

  // const contractId = useMemo(() => {
  //   return getSearchParamValue(history.location.search, "id", null);
  // }, [history.location]);

  const [data, changelog] = useMemo(() => {
    const changelog = ([...records] as any)?.sort(
      (r1, r2) =>
        new Date(r2.createdOn).getTime() - new Date(r1.createdOn).getTime(),
    );
    const postedDateIn =
      changelog.length > 0
        ? changelog[changelog.length - 1].postedDateIn
        : null;

    const data = {
      ...changelog.shift(),
      postedDateIn,
    };
    return [data, changelog];
  }, [records]);

  useEffect(() => {
    if (!id) return;
    dispatch(
      getContractDetails({
        context,
        params: {
          id,
          fileKeyword: "",
          fileName: "",
        },
      }),
    );
  }, [id]);

  const handleOpenAddToPipelines = (): void => {
    if (!id) return;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(id));
        dispatch(
          getPipelinesForRecord({
            context,
            table: "contracts",
            recordId: id,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(id));
      dispatch(
        getPipelinesForRecord({
          context,
          table: "contracts",
          recordId: id,
        }),
      );
    }
  };

  const handleCloseAddToPipelines = (): void => {
    setAddToPipelinesOpen(false);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(true);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (RequestStatus.isDone(postFetchStatus)) {
      handleCloseAddToPipelines();
      dispatch(resetAddOrRemoveFromPipelinesStatus());
      id &&
        dispatch(
          getContractDetails({
            context,
            params: {
              id,
              fileKeyword: "",
              fileName: "",
            },
          }),
        );
    }
  }, [postFetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus) && isFirstLoading)
      setIsFirstLoading(false);
  }, [fetchStatus, isFirstLoading]);

  return (
    <>
      <OpportunityDetails
        source={"contracts"}
        data={{
          ...data,
          numberIncludedPipelines: data?.pipelineDetails?.length,
        }}
        loading={RequestStatus.isFetching(fetchStatus) && isFirstLoading}
        files={files}
        addToPipelinesOpen={addToPipelinesOpen}
        setAddToPipelinesOpen={setAddToPipelinesOpen}
        handleCloseAddToPipelines={handleCloseAddToPipelines}
        handleOpenAddToPipelines={handleOpenAddToPipelines}
        pipelinesFetchStatus={pipelinesForRecordFetchStatus}
        shareOpportunitiesFetchStatus={shareOpportunitiesFetchStatus}
        onShareSubmit={undefined}
        handleResetShareOpportunitiesStatus={undefined}
      />
    </>
  );
};

export default ContractsProfilePage;
