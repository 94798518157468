import React, { FC } from "react";
import IDIQTable from "./IDIQTable";
import { useGridStyles } from "../../hooks/useGridStyles";

const IDIQs: FC = (): JSX.Element => {
  const styles = useGridStyles();

  return (
    <div className="p-grid p-dir-col">
      <div className="p-grid" style={{ alignItems: "center" }}>
        <div className="p-col-12" style={styles.pageHeader}>
          <img
            src={styles.aidkonektIcon}
            style={styles.icon}
            alt="Aidkonekt"
            className="formIcon"
          ></img>
          Search
        </div>

        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          USAID issues task orders for competition among the IDIQ prime holders.
          Most task orders valued above $10 million should also appear on the
          Forecast+ feed, but task orders are typically sent by email directly
          to the prime holders, and are not shared publicly.
        </div>
        <div className="p-col-10" style={{ ...styles.sectionSubheader }}>
          Last updated: 1 December 2022
        </div>
      </div>
      <IDIQTable />
    </div>
  );
};

export default IDIQs;
