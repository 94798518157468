import React, { CSSProperties, Fragment, useContext, useState } from "react";
import "./Header.css";
import { Link, NavLink, withRouter } from "react-router-dom";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import ThemeContext, { defaultTheme, Theme } from "../../configs/theme";
import useIsAuthenticated from "../../services/authCheck";
import aidkonektIcon from "../../assets/AidKonectLogo.png";
import { goToUrlInNewTab } from "../../configs/resources";
import PillButton from "../PillButton";
import SimpleModal from "../Modals/SimpleModal";
import { Box, Typography } from "@mui/material";
import UpgradePlanModal from "../Modals/UpgradePlanModal";
import Stack from "@mui/material/Stack";
import { version } from "../../../package.json";
import SubmitFeedback from "../SubmitFeedback";

const navStyle = {
  paddingTop: "10px",
  paddingBottom: "10px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignSelf: "center",
  textDecoration: "none",
};

const unselectedNavStyle: any = (theme: Theme) => ({
  ...navStyle,
  backgroundColor: theme && theme.colors ? theme.colors.offwhite : "white",
});

const navTextStyle: React.CSSProperties = {
  textAlign: "left",
  font: defaultTheme.v2Fonts.menu,
  color: defaultTheme.v2.black,
  marginBottom: "0px",
  marginTop: "0px",
  // borderBottom: "2px solid transparent",
};

const lockedLinkStyle: CSSProperties = { pointerEvents: "none" };
const lockedIconStyle: CSSProperties = { paddingRight: 10 };

function useNavigationLink(
  path: string,
  name: string,
  theme: Theme,
  nest: number,
  lockLink: boolean,
  hideLockOverride?: boolean,
  triggerUpgradePlanModal: (() => void) | null = null,
): [React.Dispatch<boolean>, JSX.Element] {
  const [selected, setSelected] = React.useState(false);

  const selectedStyle = {
    ...navStyle,
    backgroundColor: theme.v2.lightBlue,
    borderRadius: "22px",
  };

  const selectedTextStyle = {
    ...navTextStyle,
    font: theme.v2Fonts.menuSelected,
    color: theme.v2.darkBlue,
  };

  const actualNest = !nest ? 0 : nest;
  const textStyle = selected ? selectedTextStyle : navTextStyle;
  let s: any = { ...textStyle, paddingLeft: 15 + actualNest * 15 };

  let linkStyle: any = selected ? selectedStyle : unselectedNavStyle(theme);

  if (lockLink) {
    linkStyle = { ...linkStyle, ...lockedLinkStyle };
  }

  if (!nest) {
    s.font = theme.v2Fonts.menuRootNode;
  }

  const component = (
    <Box
      {...(triggerUpgradePlanModal &&
        lockLink && {
          onClick: () => triggerUpgradePlanModal(),
          sx: {
            cursor: "pointer",
          },
        })}
    >
      <NavLink to={path} style={{ ...linkStyle }} className="aid-nav-link">
        <p style={s}>
          {lockLink && !hideLockOverride && (
            <span style={lockedIconStyle}>
              <i className="pi pi-lock" />
            </span>
          )}
          {name}
        </p>
      </NavLink>
    </Box>
  );

  return [setSelected, component];
}

// const icons = {
//   logo: require("../../assets/AidKonectLogo.png").default,
// };

type NavBarProps = { history: any; location: any };
function NavBar({ history, location }: NavBarProps) {
  const context = React.useContext(UserContext);
  const theme = React.useContext(ThemeContext);

  const isAdmin = doesUserHaveRole(context, ServerOrgRoles.admin);
  const isPro = isUserInRoles(context, UserRoleGroups.proOrHigher);
  const isFree = !isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);

  const [getIsAuthenticated] = useIsAuthenticated();
  const isAuthenticated = getIsAuthenticated();
  const isLoaded = context.isLoaded;

  const [setAdmin, Admin] = useNavigationLink(
    "/admin-panel",
    "Admin",
    theme,
    0,
    false,
  );
  const [setDashboard, Dashboard] = useNavigationLink(
    "/",
    "Dashboard",
    theme,
    0,
    false,
  );
  const [setPipelines, Pipeline] = useNavigationLink(
    "/pipeline",
    "Pipelines",
    theme,
    0,
    false,
  );
  const [, Pipelines] = useNavigationLink(
    "/forecast",
    "USAID Bid Search",
    theme,
    0,
    isFree,
    true,
  );
  const [setForecast, Forecast] = useNavigationLink(
    "/forecast",
    "Forecast+",
    theme,
    1,
    isFree,
    false,
    () => setUpgradePlanModalOpen(true),
  );
  const [setGrants, Grants] = useNavigationLink(
    "/grants",
    "Grants+",
    theme,
    1,
    isFree,
    false,
    () => setUpgradePlanModalOpen(true),
  );
  const [setContracts, Contracts] = useNavigationLink(
    "/contracts",
    "Contracts+",
    theme,
    1,
    isFree,
    false,
    () => setUpgradePlanModalOpen(true),
  );

  const [, Insights] = useNavigationLink(
    "/projects",
    "Capture Insight",
    theme,
    0,
    !isPro,
    true,
  );
  // const [setDataPlus, DataPlus] = useNavigationLink(
  //   "/decdata",
  //   "Reports",
  //   theme,
  //   1,
  //   !isPro,
  //   false,
  //   () => setUpgradePlanModalOpen(true),
  // );
  const [setReports, Reports] = useNavigationLink(
    "/reports",
    "Reports",
    theme,
    1,
    !isPro,
    false,
    () => setUpgradePlanModalOpen(true),
  );
  // const [setUsaid, UsAid] = useNavigationLink(
  //   "/projects",
  //   "Projects",
  //   theme,
  //   1,
  //   !isPro,
  //   false,
  //   () => setUpgradePlanModalOpen(true),
  // );

  const [setProjects, Projects] = useNavigationLink(
    "/projects",
    "Projects",
    theme,
    1,
    !isPro,
    false,
    () => setUpgradePlanModalOpen(true),
  );

  const [setECD, ECD] = useNavigationLink(
    "/ecd",
    "Environmental Reports",
    theme,
    1,
    !isPro,
    false,
    () => setUpgradePlanModalOpen(true),
  );

  const [setFAQ, FAQ] = useNavigationLink("/faq", "FAQ", theme, 0, isFree);
  const [setResources, Resources] = useNavigationLink(
    "/aidkonekt-resources",
    "Resources",
    theme,
    1,
    isFree,
    false,
    () => setUpgradePlanModalOpen(true),
  );

  const [setUserManagement, UserManagement] = useNavigationLink(
    "/users",
    "Users",
    theme,
    0,
    false,
  );
  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  function setSelected(path: string) {
    setAdmin(false);
    setDashboard(false);
    setForecast(false);
    // setDataPlus(false);
    setUserManagement(false);
    setGrants(false);
    setContracts(false);
    // setUsaid(false);
    setFAQ(false);
    setResources(false);
    setPipelines(false);
    setProjects(false);
    setReports(false);
    setECD(false);

    switch (path) {
      case "/":
        setDashboard(true);
        break;
      case "/admin-panel":
        setAdmin(true);
        break;
      // case "/projects":
      //   setUsaid(true);
      //   break;
      case "/projects":
        setProjects(true);
        break;
      case "/reports":
        setReports(true);
        break;
      case "/ecd":
        setECD(true);
        break;
      // case "/decdata":
      //   setDataPlus(true);
      //   break;
      case "/forecast":
        setForecast(true);
        break;
      case "/users":
        setUserManagement(true);
        break;
      case "/grants":
        setGrants(true);
        break;
      case "/contracts":
        setContracts(true);
        break;
      case "/faq":
        setFAQ(true);
        break;
      case "/pipeline":
        setPipelines(true);
        break;
      case "/aidkonekt-resources":
      case "/resources":
      case "/webinars":
      case "/cdcs-repository":
      case "/cdcs-repository-2":
      case "/idiqs":
      case "/lcp-archives":
      case "/iee-archives":
      case "/qa-archives":
        setResources(true);
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    setSelected(location.pathname); // initialize
    return history.listen((l: any, a: any) => {
      setSelected(l.pathname);
    });
  }, []);

  return (
    <Fragment>
      <div
        id="header"
        style={{
          width: "230px",
          // boxShadow: "0px 4px 12px rgb(0 0 0 / 5%)"
          paddingRight: "32px",
        }}
        className="p-col"
      >
        <UpgradePlanModal
          upgradePlanModalOpen={upgradePlanModalOpen}
          setUpgradePlanModalOpen={(val: boolean) =>
            setUpgradePlanModalOpen(val)
          }
        />
        {isLoaded && isAuthenticated && isAdmin && Admin}
        {isLoaded && isAuthenticated && Dashboard}

        {isLoaded && isAuthenticated && Pipeline}

        {isLoaded && isAuthenticated && Pipelines}
        {isLoaded &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Forecast}
        {isLoaded &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Contracts}
        {isLoaded &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Grants}

        {isLoaded && isAuthenticated && Insights}
        {/*{isLoaded &&*/}
        {/*  // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&*/}
        {/*  UsAid}*/}
        {isLoaded &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Projects}
        {/*{isLoaded &&*/}
        {/*  // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&*/}
        {/*  DataPlus}*/}
        {isLoaded &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Reports}
        {isLoaded &&
          isAuthenticated &&
          // isUserInRoles(context, UserRoleGroups.plusOrHigher) &&
          Resources}
        {/*{isLoaded && ECD}*/}
        {isAdmin && UserManagement}
        {/*{isLoaded && !isFree && isAuthenticated && FAQ}*/}
        {isFree && isAuthenticated && (
          <PillButton
            label="Upgrade Plan"
            onClick={
              isMember
                ? () => setUpgradePlanModalOpen(true)
                : goToUrlInNewTab("tiers")
            }
            style={{ marginTop: 10, marginLeft: 15, width: 160 }}
          />
        )}

        {/* {isLoaded && isAuthenticated && (
        <div
          style={{ paddingLeft: "15px", display: "flex", cursor: "pointer" }}
          onClick={signOut}
        >
          <p
            className="sign-out-btn"
            style={{
              font: theme.v2Fonts.menu,
              color: theme.v2.darkBlue,
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="pi pi-sign-out" />
            <span>&nbsp;&nbsp;Sign Out</span>
          </p>
        </div>
      )} */}
      </div>
      <SubmitFeedback />
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={1}
        sx={{
          px: 2,
          justifyContent: "center",
          "& > *": {
            fontSize: "13px",
          },
        }}
      >
        <Typography
          variant="body2"
          component="a"
          href="https://konektid-resources.s3.amazonaws.com/AidKonekt+Data+-+Terms+and+Conditions+Policy.pdf"
          target="blank"
        >
          Terms
          {/*and conditions*/}
        </Typography>
        <Typography variant="body2">•</Typography>
        <Typography
          variant="body2"
          component="a"
          href="https://konektid-resources.s3.amazonaws.com/AidKonekt Privacy Policy_2021.pdf"
          target="blank"
        >
          Privacy
          {/*Policy*/}
        </Typography>
      </Stack>
      <Typography variant="body2" fontSize={"13px"}>
        AidKonekt Data © {new Date().getFullYear()} - V{version}
        {/*Policy*/}
      </Typography>
    </Fragment>
  );
}

export function Header() {
  return (
    <Link to="/">
      <img style={styles.logo} src={aidkonektIcon} alt="logo" />
    </Link>
  );
}

const styles = {
  logo: {
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 20,
    maxWidth: "180px",
  },
};

export default withRouter(NavBar);
