import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Input from "../../components/Widgets/Inputs/Input";
import { RootState, useSelector } from "../../store";
import { Box, FormControlLabel, Switch } from "@mui/material";

type TableContextType = "usa-spending" | "foreign-assistance" | null;

interface ProjectsTableForEditsFiltersProps {
  filtersFormId: string;
  tableContext: TableContextType;
  usaSpendingControl: any;
  foreignAssistanceControl: any;
  usaSpendingHandleSubmit: any;
  foreignAssistanceHandleSubmit: any;
  onUsaSpendingFiltersSubmit: (values: any) => void;
  onForeignAssistanceFiltersSubmit: (values: any) => void;
  minimumAmountDisabled: boolean;
  setMinimumAmountDisabled: Dispatch<SetStateAction<boolean>>;
  showOnlyRecentRecords: boolean;
  handleChangeShowOnlyRecentRecords: () => void;
}

const ProjectsTableFilters: FC<ProjectsTableForEditsFiltersProps> = (props) => {
  const {
    filtersFormId,
    tableContext,
    usaSpendingControl,
    foreignAssistanceControl,
    usaSpendingHandleSubmit,
    foreignAssistanceHandleSubmit,
    onUsaSpendingFiltersSubmit,
    onForeignAssistanceFiltersSubmit,
    minimumAmountDisabled,
    setMinimumAmountDisabled,
    showOnlyRecentRecords,
    handleChangeShowOnlyRecentRecords,
  } = props;

  const {
    items: usaSpendingItems,
    fetchStatus: usaSpendingFetchStatus,
    total: usaSpendingTotal,
    dropdownOptions: usaSpendingDropdownOptions,
  } = useSelector((state: RootState) => state.usaSpending);
  const {
    items: foreignAssistanceItems,
    fetchStatus: foreignAssistanceFetchStatus,
    total: foreignAssistanceTotal,
    dropdownOptions: foreignAssistanceDropdownOptions,
  } = useSelector((state: RootState) => state.foreignAssistance);

  return tableContext === null ? (
    <></>
  ) : (
    <form
      id={filtersFormId}
      onSubmit={
        tableContext === "usa-spending"
          ? usaSpendingHandleSubmit(onUsaSpendingFiltersSubmit)
          : foreignAssistanceHandleSubmit(onForeignAssistanceFiltersSubmit)
      }
    >
      {tableContext === "usa-spending" ? (
        <>
          <Input
            type={"switch"}
            name={"isMyList"}
            control={usaSpendingControl}
            label={"Show only my projects?"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"country"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.country}
            label={"COUNTRY"}
            multiple
            withCheckboxes
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"implementer"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.implementor}
            label={"IMPLEMENTER"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"subawardRecipient"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.subawards}
            label={"SUBAWARDEES"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"text"}
            name={"projectNumber"}
            control={usaSpendingControl}
            label={"PROJECT NUMBER"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"mechanism"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.mechanism}
            label={"AWARD MECHANISM"}
            multiple
            withCheckboxes
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"naics"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.naics}
            label={"NAICS CODE"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"setAside"}
            control={usaSpendingControl}
            options={usaSpendingDropdownOptions.setAside}
            label={"SET-ASIDE TYPE"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"dateCloseFrom"}
            control={usaSpendingControl}
            label={"PROJECTS CLOSING FROM"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"dateCloseTo"}
            control={usaSpendingControl}
            label={"PROJECTS CLOSING TO"}
          />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={!minimumAmountDisabled}
                  onChange={() => setMinimumAmountDisabled((prev) => !prev)}
                />
              }
              label={"Only show projects over"}
            />
            <Input
              sx={{
                "& .MuiFormLabel-root": {
                  fontSize: "0.875em",
                  lineHeight: "1.875em",
                },
                flex: 1,
              }}
              type={"amount"}
              name={"minimumAmount"}
              control={usaSpendingControl}
              // label={"MINIMUM AMOUNT"}
              disabled={minimumAmountDisabled}
            />
          </Box>
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"dateStartFrom"}
            control={usaSpendingControl}
            label={"PROJECTS STARTING FROM"}
            disabled={showOnlyRecentRecords}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"dateStartTo"}
            control={usaSpendingControl}
            label={"PROJECTS STARTING TO"}
            disabled={showOnlyRecentRecords}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyRecentRecords}
                onChange={handleChangeShowOnlyRecentRecords}
              />
            }
            label={"Show recently awarded projects (last 90 days)"}
            labelPlacement={"start"}
            sx={{
              justifyContent: "space-between",
              mx: 0,
            }}
          />
        </>
      ) : tableContext === "foreign-assistance" ? (
        <>
          <Input
            type={"switch"}
            name={"IsMyList"}
            control={foreignAssistanceControl}
            label={"Show only my projects?"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"Country"}
            control={foreignAssistanceControl}
            options={foreignAssistanceDropdownOptions.country}
            label={"COUNTRY"}
            multiple
            withCheckboxes
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"Sector"}
            control={foreignAssistanceControl}
            options={foreignAssistanceDropdownOptions.technicalSector}
            label={"TECHNICAL SECTOR"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"Implementer"}
            control={foreignAssistanceControl}
            options={foreignAssistanceDropdownOptions.implementor}
            label={"IMPLEMENTER"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"text"}
            name={"ProjectNumber"}
            control={foreignAssistanceControl}
            label={"PROJECT NUMBER"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"FundingAgency"}
            control={foreignAssistanceControl}
            options={foreignAssistanceDropdownOptions.fundingAgency}
            label={"FUNDING AGENCY"}
            multiple
            withCheckboxes
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"combo-box"}
            name={"FundingAccount"}
            control={foreignAssistanceControl}
            options={foreignAssistanceDropdownOptions.fundingAccount}
            label={"FUNDING ACCOUNT"}
            multiple
            withCheckboxes
            useVirtualizedList
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"DateCloseFrom"}
            control={foreignAssistanceControl}
            label={"PROJECTS CLOSING FROM"}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"DateCloseTo"}
            control={foreignAssistanceControl}
            label={"PROJECTS CLOSING TO"}
          />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={!minimumAmountDisabled}
                  onChange={() => setMinimumAmountDisabled((prev) => !prev)}
                />
              }
              label={"Only show projects over"}
            />
            <Input
              sx={{
                "& .MuiFormLabel-root": {
                  fontSize: "0.875em",
                  lineHeight: "1.875em",
                },
                flex: 1,
              }}
              type={"amount"}
              name={"MinimumAmount"}
              control={foreignAssistanceControl}
              // label={"MINIMUM AMOUNT"}
              disabled={minimumAmountDisabled}
            />
          </Box>
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"DateStartFrom"}
            control={foreignAssistanceControl}
            label={"PROJECTS STARTING FROM"}
            disabled={showOnlyRecentRecords}
          />
          <Input
            sx={{
              "& .MuiFormLabel-root": {
                fontSize: "0.875em",
                lineHeight: "1.875em",
              },
            }}
            type={"date"}
            name={"DateStartTo"}
            control={foreignAssistanceControl}
            label={"PROJECTS STARTING TO"}
            disabled={showOnlyRecentRecords}
          />
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyRecentRecords}
                onChange={handleChangeShowOnlyRecentRecords}
              />
            }
            label={"Show recently awarded projects (last 90 days)"}
            labelPlacement={"start"}
            sx={{
              justifyContent: "space-between",
              mx: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </form>
  );
};

export default ProjectsTableFilters;
