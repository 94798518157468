import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import PipelineUpdates from "./PipelineUpdates";
import EventCalendar from "./EventCalendar";
import { RootState, useDispatch, useSelector } from "../../store";
import UserContext, {
  convertRoleToFriendlyName,
  doesUserHaveRole,
  getMaxUserRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
  userRoleInOrg,
} from "../../services/UserContext";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import { useHistory } from "react-router-dom";
import {
  getDashboardData,
  getEvents,
  getOverviewData,
  getPipelineUpdatesDetails,
} from "../../slices/dashboard";
import { getSearchParamValue } from "../../utils/Helpers/extractDataFromSearchParams";
import Resources from "./Resources";
import {
  getPipelines,
  resetPipelinesState,
  updatePipelines,
} from "../../slices/pipelines";
import toast from "react-hot-toast";
import { isEqual } from "lodash";
import { Userpilot } from "userpilot";

export type DashboardUpdatesTabType = "overview" | "details" | null;

const Dashboard: FC = (props) => {
  const {} = props;

  const dispatch = useDispatch();
  const context = useContext(UserContext);
  const history = useHistory();

  const {
    events,
    updates,
    fetchStatus,
    pipelineUpdates,
    opportunitiesDetails,
    user,
    organization,
  } = useSelector((state: RootState) => state.dashboard);
  const {
    fetchStatus: pipelinesFetchStatus,
    rawItems,
    items: pipelines,
  } = useSelector((state: RootState) => state.pipelines);

  const theme = useTheme();

  const isTallerThan660 = useMediaQuery("(min-height: 660px)");

  const liveBidsContainerRef = useRef<HTMLDivElement>(null);
  const resourcesContainerRef = useRef<HTMLDivElement>(null);

  const isPlusUser = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);

  /**
   * used to keep track of all components' heights apart from events card, so that events card can always take up all possible space
   */
  const [combinedHeight, setCombinedHeight] = useState<number>(0);

  const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));

  const userPilotRole = useMemo(() => {
    let isOrgAdmin = userRoleInOrg(context, ServerOrgRoles.organizationAdmin);
    let maxUserRole = getMaxUserRole(context);
    let role = convertRoleToFriendlyName(maxUserRole);
    if (
      maxUserRole === ServerOrgRoles.plus ||
      maxUserRole === ServerOrgRoles.pro
    )
      role = isOrgAdmin ? `${role} Admin` : role;

    return role;
  }, [context]);

  const pageIndex = useMemo(
    () =>
      +getSearchParamValue<string>(history.location.search, "pageIndex", "0"),
    [history.location],
  );
  const pageSize = useMemo(
    () =>
      +getSearchParamValue<string>(history.location.search, "pageSize", "20"),
    [history.location],
  );
  const opportunitiesKeyword = useMemo(
    () =>
      getSearchParamValue<string>(
        history.location.search,
        "opportunitiesKeyword",
        "",
      ),
    [history.location],
  );
  const eventsKeyword = useMemo(
    () =>
      getSearchParamValue<string>(history.location.search, "eventsKeyword", ""),
    [history.location],
  );

  const overviewRange = useMemo(
    () =>
      getSearchParamValue<"today" | "last7" | "lastMonth" | "lastYear">(
        history.location.search,
        "updatesRange",
        "today",
      ),
    [history.location],
  );
  const overviewStartDate = useMemo(
    () =>
      overviewRange === "lastYear"
        ? new Date().addYears(-1)
        : overviewRange === "lastMonth"
        ? new Date().addMonths(-1)
        : new Date().addDays(overviewRange === "last7" ? -7 : -1),
    [overviewRange],
  );

  const updatesRange = useMemo(
    () =>
      getSearchParamValue<"today" | "last7" | "lastMonth" | "lastYear">(
        history.location.search,
        "opportunitiesRange",
        "today",
      ),
    [history.location],
  );
  const datasetTypes = useMemo(
    () =>
      // stringify to prevent triggering useEffect even when the value doesn't change
      JSON.stringify(
        getSearchParamValue<("Forecast" | "Contract" | "Grant" | "All")[]>(
          history.location.search,
          "opportunitiesType",
          ["All"],
          {
            multiple: true,
          },
        ),
      ),
    [history.location],
  );
  // const updatesStartDate = useMemo(
  //   () =>
  //     updatesRange === "lastYear"
  //       ? new Date().addYears(-1)
  //       : updatesRange === "lastMonth"
  //       ? new Date().addMonths(-1)
  //       : new Date().addDays(updatesRange === "last7" ? -7 : -1),
  //   [updatesRange],
  // );

  const detailsForPipelines = useMemo(
    () =>
      getSearchParamValue<"true" | "false">(
        history.location.search,
        "detailsForPipelines",
        "true",
      ),
    [history.location],
  );
  const pipelineValue = useMemo(
    () =>
      // stringify to prevent triggering useEffect even when the value doesn't change
      JSON.stringify(
        getSearchParamValue<string[]>(
          history.location.search,
          "pipelineValue",
          ["all"],
          {
            multiple: true,
          },
        ),
      ),
    [history.location],
  );

  const tab = useMemo(
    () =>
      getSearchParamValue<DashboardUpdatesTabType>(
        history.location.search,
        "tab",
        "overview",
      ),
    [history.location],
  );

  const handleGetOverviewData = useCallback((): void => {
    if (!isPlusUser) return;
    dispatch(
      getOverviewData({
        context,
        values: {
          // startDate: overviewStartDate.toISOString(),
          startDate: new Date().addDays(-1).toISOString(),
          endDate: new Date().toISOString(),
        },
      }),
    );
  }, [
    // overviewStartDate,
    isPlusUser,
  ]);

  const handleGetPipelineUpdatesDetails = useCallback((): void => {
    if (!isPlusUser) return;
    // if (opportunitiesDetails.data.length === 0 && pageIndex > 0) {
    //   setUrlParams(history, { pageIndex: "0" }, { replace: true });
    // } else if (opportunitiesDetails.data.length === 0 && pageSize > 20) {
    //   setUrlParams(history, { pageSize: "20" }, { replace: true });
    // } else {
    //   const loadMore = pageIndex > 0;
    dispatch(
      getPipelineUpdatesDetails({
        context,
        params: {
          DatasetTypes: isEqual(JSON.parse(datasetTypes), ["All"])
            ? ["Forecast", "Contract", "Grant"]
            : JSON.parse(datasetTypes),
          PipelineIds: isEqual(JSON.parse(pipelineValue), ["all"])
            ? undefined
            : JSON.parse(pipelineValue),
          UpdatedAfter: overviewStartDate.toISOString(),
          UpdatedBefore: new Date().toISOString(),
          PageIndex: pageIndex,
          PageSize: pageSize,
          Keyword: opportunitiesKeyword,
          SortField: "lastUpdatedOn",
          SortOrder: -1,
          FilterToUserPipelines: detailsForPipelines,
        },
        // loadMore,
      }),
    );
    // }
  }, [
    pageSize,
    pageIndex,
    opportunitiesKeyword,
    overviewStartDate,
    datasetTypes,
    isPlusUser,
    detailsForPipelines,
    pipelineValue,
    opportunitiesDetails.data,
  ]);

  const getCombinedHeight = (): void => {
    if (!liveBidsContainerRef.current || !resourcesContainerRef.current) return;
    setCombinedHeight(
      liveBidsContainerRef.current.clientHeight +
        resourcesContainerRef.current.clientHeight,
    );
  };

  useEffect(() => {
    window.addEventListener("resize", getCombinedHeight);
    return () => {
      window.removeEventListener("resize", getCombinedHeight);
    };
  }, []);

  useEffect(() => {
    if (!resourcesContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      getCombinedHeight();
    });
    resizeObserver.observe(resourcesContainerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    getCombinedHeight();
  });

  useEffect(() => {
    dispatch(getDashboardData(context));
    // dispatch(
    //   getEvents({ context, params: { EventNameKeyword: eventsKeyword } }),
    // );
    dispatch(getPipelines(context));
  }, []);

  useEffect(() => {
    //it differs from regular fetch status value assignment since this requires updating the pipelines with additional data
    if (RequestStatus.isHalfDone(pipelinesFetchStatus)) {
      dispatch(updatePipelines({ pipelines: rawItems }));
    } else if (RequestStatus.isError(pipelinesFetchStatus)) {
      toast.error("Couldn't load pipelines details. Try again later.");
      dispatch(resetPipelinesState());
    }
  }, [pipelinesFetchStatus]);

  useEffect(() => {
    // if (tab === "overview") {
    // } else {
    // }
    handleGetOverviewData();
    handleGetPipelineUpdatesDetails();
  }, [
    overviewRange,
    tab,
    // updatesRange,
    opportunitiesKeyword,
    datasetTypes,
    pageIndex,
    pageSize,
    detailsForPipelines,
    pipelineValue,
  ]);

  // useEffect(() => {
  //   if (tab === "details") {
  //     handleGetPipelineUpdatesDetails();
  //   }
  // }, [updatesRange, opportunitiesKeyword, datasetTypes, tab]);

  useEffect(() => {
    dispatch(
      getEvents({
        context,
        params: {
          // EventNameKeyword: eventsKeyword,
          // DescriptionKeyword: eventsKeyword,
          Keyword: eventsKeyword,
        },
      }),
    );
  }, [eventsKeyword]);

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus)) {
      const today = new Date();
      const created_at =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const id = context.user.parsedIdToken?.sub;
      const email = context.user.parsedIdToken?.email ?? "";

      Userpilot.identify(id ?? "", {
        name: user?.firstName ?? "",
        email: email,
        role: userPilotRole,
        created_at: created_at,
        organization_name: organization?.name ?? "",
        tier: organization?.tier ?? "",
        expiration: organization?.tierExpiration ?? "",
      });
    }
  }, [fetchStatus]);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2, flex: 1 }}>
        {/*<Grid item xs={12}>*/}
        {/*  <SummaryCards />*/}
        {/*</Grid>*/}
        <Grid
          container
          item
          sm={12}
          md={6}
          lg={8}
          spacing={2}
          sx={{
            ...(isPlusUser
              ? { flex: 1 }
              : {
                  height: "fit-content",
                }),
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
              ...(isLessThanMd
                ? {
                    minHeight: "75vh",
                  }
                : {
                    maxHeight: `calc(100vh - 96px)`,
                  }),
            }}
          >
            <PipelineUpdates
              updates={updates}
              pipelineUpdates={pipelineUpdates}
              opportunitiesDetailsFetchStatus={opportunitiesDetails.fetchStatus}
              opportunitiesTotal={opportunitiesDetails.total}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          md={6}
          lg={4}
          spacing={2}
          sx={{
            height: "fit-content",
            ...(!isTallerThan660 && { minHeight: "580px" }),
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              flex: 1,
              ...(isLessThanMd
                ? {}
                : isTallerThan660
                ? {
                    maxHeight: `calc(100vh - 96px - ${combinedHeight}px)`,
                  }
                : { height: "235px" }),
            }}
          >
            <EventCalendar
              events={events.items}
              total={events.total}
              pageIndex={events.pageIndex}
              pageSize={events.pageSize}
              loading={RequestStatus.isFetching(events.fetchStatus)}
              loadDataAsync={() =>
                dispatch(
                  getEvents({ context, params: { name: eventsKeyword } }),
                )
              }
            />
          </Grid>
          <Grid
            item
            ref={liveBidsContainerRef}
            xs={12}
            sx={{
              height: "fit-content",
              p: `0 !important`,
            }}
          >
            {/*<AnticipatedLiveBids*/}
            {/*  loading={RequestStatus.isFetching(pipelinesFetchStatus)}*/}
            {/*  pipelines={pipelines}*/}
            {/*/>*/}
          </Grid>
          <Grid
            item
            ref={resourcesContainerRef}
            xs={12}
            sx={{
              height: "fit-content",
            }}
          >
            <Resources />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
