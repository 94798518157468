import axios from "axios";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../configs/axios-export.custom";
import { UserContextType } from "../services/UserContext";
import { RequestStatus } from "../utils/Helpers/fetchStatus";
import { buildQuery } from "../utils/Helpers/queryBuilder";
import { DropdownOption } from "../components/Widgets/Inputs/Input";

export interface ECDRecord {
  id: number;
  countries: string[];
  sectorTypes: string[];
  specificConditions: string[];
  thresholdDetermination: string[];
  projectNumber: string[];
  awardNumbers: string[];
  classification: string | null;
  climateRiskManagementAssessment: string | null;
  documentTitle: string;
  documentType: string;
  createdOnUtc: string;
  extECDRecord_Id: number;
  fileNumber: string;
  originatingBureauSubregionOffice: string;
  projectDateActivityDateRangeEndParsed: string | null;
  projectDateActivityDateRangeStartParsed: string | null;
  projectDateEnvironmentalDeterminationSignatureDateParsed: string;
  projectDateEnvironmentalDeterminationSignatureFyParsed: string;
  projectDateExpirationDateParsed: string | null;
  recordStatus: string;
  updatedOnUtc: string | null;
}

interface ECDState {
  items: ECDRecord[];
  fetchStatus: string;
  total: number;
  lastUpdatedUtc: string | null;
  attributes: Record<string, unknown>;
  dropdownOptions: {
    country: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
    technicalSector: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
    authoringOrg: {
      items: { value: string; label: string }[];
      fetchStatus: string;
    };
  };
}

export interface ECDFiltersProps {
  pageIndex: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: number;
  id?: number | null;
  countries?: string[] | DropdownOption[];
  bureaus?: string[] | DropdownOption[];
  sectors?: string[] | DropdownOption[];
  statuses?: string[] | DropdownOption[];
  classifications?: string[] | DropdownOption[];
  updatedAfter?: Date | string | null;
  updatedBefore?: Date | string | null;
  expiresAfter?: Date | string | null;
  expiresBefore?: Date | string | null;
}

const initialState: ECDState = Object.freeze({
  items: [],
  fetchStatus: RequestStatus.statuses.NULL,
  total: 0,
  lastUpdatedUtc: null,
  attributes: {},
  dropdownOptions: {
    country: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    technicalSector: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    authoringOrg: {
      items: [],
      fetchStatus: RequestStatus.statuses.NULL,
    },
    fetchStatus: RequestStatus.statuses.NULL,
  },
});

export const getECDData: any = createAsyncThunk(
  "ecd/getECDData",
  async (
    data: {
      context: UserContextType;
      params: ECDFiltersProps;
    },
    thunkAPI,
  ) => {
    const { context, params } = data;
    const response = await axios.get(
      getFullUrl(`/api/ecd${buildQuery({ ...params })}`, {
        useDedicatedEnvironment: true,
      }),
      createAuthenticatedRequest(context),
    );

    return response?.data ?? [];
  },
);

const slice = createSlice({
  name: "ecd",
  initialState,
  reducers: {
    resetReportsItems(state: ECDState) {
      state.items = initialState.items;
      state.total = initialState.total;
      state.fetchStatus = initialState.fetchStatus;
    },
  },
  extraReducers: {
    [getECDData.pending]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.FETCHING;
    },
    [getECDData.fulfilled]: (state, action: PayloadAction<any>) => {
      const { attributes, data, lastUpdatedUtc, totalItems } = action.payload;
      state.items = data;
      state.total = totalItems;
      state.lastUpdatedUtc = lastUpdatedUtc;
      state.attributes = attributes;
      state.fetchStatus = RequestStatus.statuses.DONE;
    },
    [getECDData.rejected]: (state, action) => {
      state.fetchStatus = RequestStatus.statuses.ERROR;
    },
  },
});

export const { reducer } = slice;

export const { resetReportsItems } = slice.actions;
export default slice;
