import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  CalendarEvent,
  deleteEvent,
  getEvents,
  resetCreateEventState,
} from "../../slices/dashboard";
import { format } from "date-fns";
import { getNewDateNoTimezoneAdjustment } from "../../utils/conversion/date-converters";
import { Clear, Delete, ExpandLess, LocationOn } from "@mui/icons-material";
import { OfficeBuildingIcon } from "../../assets/icons/material-icons/office-building";
import SeverityPill from "../../components/Widgets/SeverityPill";
import UserContext, {
  isUserAdminOrSemiAdminWith,
  ServerOrgRoles,
} from "../../services/UserContext";
import AddEditEventModal from "../../components/Dashboard/EventsCalendar/AddEditEventModal";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import toast from "react-hot-toast";
import { RootState, useDispatch, useSelector } from "../../store";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../utils/Helpers/extractDataFromSearchParams";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";

interface EventCalendarProps {
  events: CalendarEvent[] | null;
  loading: boolean;
  loadDataAsync: () => void;
  total: number;
  pageIndex: number;
  pageSize: number;
}

const EventCalendar: FC<EventCalendarProps> = (props) => {
  const { events, loading, loadDataAsync, total, pageSize, pageIndex } = props;

  const context = useContext(UserContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));

  const canEditEvents = isUserAdminOrSemiAdminWith(
    context,
    ServerOrgRoles.EditCalendarEvents,
  );

  const { fetchStatus: deleteFetchStatus } = useSelector(
    (state: RootState) => state.dashboard.event.delete,
  );

  const [showEvents, setShowEvents] = useState<boolean>(true);
  const [currentlyDeleting, setCurrentlyDeleting] = useState<number | null>(
    null,
  );

  const queryInputRef = useRef<HTMLInputElement>(null);

  const queryValue = useMemo(
    () =>
      getSearchParamValue<string>(history.location.search, "eventsKeyword", ""),
    [history.location],
  );

  const onQueryChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    debouncedKeywordChange(e.target.value);
  };

  const searchCallback = useCallback(
    (value: string): void => {
      setUrlParams(history, { eventsKeyword: value });
    },
    [history.location],
  );

  const debouncedKeywordChange = useCallback(debounce(searchCallback, 750), [
    searchCallback,
  ]);

  const isNewEvent = (dateStr: string) => {
    const date = new Date(dateStr);
    const nowDate = new Date();
    nowDate.addDays(-7);
    return date >= nowDate;
  };

  const handleDeleteEvent = (id: number): void => {
    setCurrentlyDeleting(id);
    dispatch(deleteEvent({ context, id }));
  };

  const handleLoadMore = useCallback((): void => {
    const eventsKeyword = getSearchParamValue<string>(
      history.location.search,
      "eventsKeyword",
      "",
    );

    dispatch(
      getEvents({
        context,
        params: {
          EventNameKeyword: eventsKeyword,
          PageIndex: pageIndex,
          PageSize: pageSize + 20,
        },
      }),
    );
  }, [history.location, pageSize, pageIndex]);

  useEffect(() => {
    if (RequestStatus.isDone(deleteFetchStatus)) {
      toast.success("Event deleted");
      loadDataAsync();
      dispatch(resetCreateEventState());
      setCurrentlyDeleting(null);
    } else if (RequestStatus.isError(deleteFetchStatus)) {
      toast.error("There was an error deleting this event.");
      dispatch(resetCreateEventState());
      setCurrentlyDeleting(null);
    }
  }, [deleteFetchStatus]);

  return (
    <Card
      variant={"outlined"}
      sx={{
        borderRadius: "8px",
        height: "100%",
        ...(isLessThanMd && {
          maxHeight: "55vh",
        }),
      }}
    >
      <Box sx={{ height: "4px" }}>{loading && <LinearProgress />}</Box>
      <CardContent>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            sx={{ textAlign: "left" }}
          >
            <CalendarMonthOutlinedIcon color={"primary"} />
            <Typography variant={"h5"} color={"inherit"}>
              Events
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <AddEditEventModal
              canEditEvents={canEditEvents}
              loadDataAsync={loadDataAsync}
              onlyIcon
            />
            <IconButton onClick={() => setShowEvents((prev) => !prev)}>
              <ExpandLess
                sx={{
                  transition: "transform 250ms ease-in-out",
                  transform: `rotate(${showEvents ? "0deg" : "180deg"})`,
                }}
              />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>

      <Collapse
        in={showEvents}
        timeout={250}
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflowY: "scroll",
          // maxHeight: "calc(100% - 68px - 58px)",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.light,
            px: 2,
            py: 1,
            borderTop: `solid 1px ${theme.palette.divider}`,
            borderBottom: `solid 1px ${theme.palette.divider}`,
          }}
        >
          <TextField
            disabled={loading}
            inputRef={queryInputRef}
            defaultValue={queryValue ?? ""}
            fullWidth
            onChange={onQueryChange}
            size={"small"}
            placeholder="Search"
            InputProps={{
              sx: {
                backgroundColor: theme.palette.background.paper,
              },
              startAdornment: (
                <SearchIcon
                  style={{
                    marginRight: "8px",
                  }}
                />
              ),
              endAdornment: queryValue ? (
                <IconButton
                  disabled={loading}
                  onClick={() => {
                    if (!queryInputRef.current) return;
                    queryInputRef.current.value = "";
                    searchCallback("");
                  }}
                >
                  <Clear />
                </IconButton>
              ) : undefined,
            }}
          />
        </Box>
      </Collapse>
      <Collapse
        in={showEvents}
        timeout={250}
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          maxHeight: "calc(100% - 68px - 58px)",
        }}
      >
        {events !== null ? (
          events.length ? (
            // [...events]
            //   .sort(
            //     (e1, e2) =>
            //       new Date(e1.date).getTime() - new Date(e2.date).getTime(),
            //   )
            // .filter(
            //   (event) =>
            //     new Date(event.date).getTime() > new Date().getTime(),
            // )
            events.map((event, idx) => (
              <Box
                key={event.name + idx}
                sx={{
                  ...(idx > 0 && {
                    borderTop: `solid 1px ${theme.palette.divider}`,
                  }),
                  px: 2,
                  py: 1,
                  textAlign: "left",
                  "& > *": {
                    my: 0.5,
                  },
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant={"subtitle2"} color={"inherit"}>
                    {event.date
                      ? format(
                          getNewDateNoTimezoneAdjustment(event.date),
                          "MMMM dd yyyy",
                        )
                      : "N/A"}
                  </Typography>
                  <Stack direction={"row"} spacing={1}>
                    <AddEditEventModal
                      canEditEvents={canEditEvents}
                      loadDataAsync={loadDataAsync}
                      oldEditIcon={false}
                      edit={{
                        event,
                      }}
                    />
                    {canEditEvents && (
                      <Tooltip title={"Delete event"}>
                        <span>
                          <IconButton
                            size={"small"}
                            onClick={() => handleDeleteEvent(event.id)}
                            color={"error"}
                            sx={{
                              border: "solid 2px",
                              // backgroundColor: alpha(theme.palette.primary.main, 0.1),
                            }}
                            disabled={currentlyDeleting === event.id}
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  {isNewEvent(event.createdOn) && (
                    <SeverityPill
                      label={"New"}
                      height={24}
                      fontSize={"14px"}
                      color={theme.palette.primary.main}
                      backgroundColor={theme.palette.primary.light}
                      borderRadius={"8px"}
                    />
                  )}
                  <Typography
                    variant={"h6"}
                    fontWeight={"500"}
                    {...(event?.url
                      ? {
                          component: "a",
                          href: event.url,
                          target: "_blank",
                          rel: "noreferrer noopener",
                          sx: { textDecoration: "underline" },
                        }
                      : {
                          color: "inherit",
                        })}
                  >
                    {event.name}
                  </Typography>
                </Stack>
                <Typography
                  variant={"subtitle2"}
                  color={"textSecondary"}
                  fontWeight={"300"}
                  fontSize={"13px"}
                  // ref={descriptionRef}
                  dangerouslySetInnerHTML={{
                    __html: event.description ?? "",
                  }}
                  sx={{
                    "p:first-of-type": {
                      marginTop: `0 !important`,
                    },
                    "& > *": {
                      color: `${theme.palette.text.secondary} !important`,
                      "& > *": {
                        color: `${theme.palette.text.secondary} !important`,
                      },
                    },
                    lineHeight: "24px",
                    // display: "-webkit-box",
                    // overflow: "hidden",
                    // WebkitBoxOrient: "vertical",
                    // WebkitLineClamp: isTruncated ? 2 : Number.MAX_SAFE_INTEGER,
                  }}
                />
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    "& > svg": {
                      fill: theme.palette.text.secondary,
                      height: "18px !important",
                      width: "18px !important",
                    },
                  }}
                >
                  <LocationOn />
                  <Typography variant={"body2"} color={"textSecondary"}>
                    {event.location?.length ? event.location : "N/A"}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={1}
                  sx={{
                    "& > svg": {
                      fill: theme.palette.text.secondary,
                      height: "18px !important",
                      width: "18px !important",
                    },
                  }}
                >
                  <OfficeBuildingIcon />
                  <Typography variant={"body2"} color={"textSecondary"}>
                    {event.organization.length ? event.organization : "N/A"}
                  </Typography>
                </Stack>
              </Box>
            ))
          ) : (
            <Typography variant={"h6"} sx={{ my: 1 }} color={"textSecondary"}>
              No events
            </Typography>
          )
        ) : (
          <></>
        )}
        {events && (
          <Button
            variant={"secondaryContained"}
            disabled={events.length === total || loading}
            onClick={handleLoadMore}
            sx={{
              my: 1,
            }}
          >
            Load more
          </Button>
        )}
      </Collapse>
    </Card>
  );
};

export default EventCalendar;
