import React, { FC, useContext, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Tab,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { RobotIcon } from "../../assets/icons/material-icons/robot";
import { getSearchParamValue } from "../../utils/Helpers/extractDataFromSearchParams";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { DashboardUpdatesTabType } from "./Dashboard";
import OverviewContent from "./Overview/OverviewContent";
import { DashboardUpdates, PipelineUpdate } from "../../slices/dashboard";
import { RequestStatus } from "../../utils/Helpers/fetchStatus";
import UpdatesDetails from "./UpdatesDetails/UpdatesDetails";
import { checkIfDesktop } from "../../utils/Helpers/browser";
import UserContext, {
  doesUserHaveRole,
  isUserInRoles,
  ServerOrgRoles,
  UserRoleGroups,
} from "../../services/UserContext";
import UpgradePlanModal from "../../components/Modals/UpgradePlanModal";
import Button from "@mui/material/Button";

interface PipelineUpdatesProps {
  updates: DashboardUpdates;
  pipelineUpdates: {
    items: PipelineUpdate[];
    paginatedItems: PipelineUpdate[];
    count: number;
    fetchStatus: string;
  };
  opportunitiesDetailsFetchStatus: string;
  opportunitiesTotal: number;
}

interface StyledTabProps {
  label: string;
  value: string;
  onClick: any;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "primary",
  minHeight: "unset",
  minWidth: "unset",
  padding: "8px",
  "&:hover": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
    boxShadow: "unset",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const PipelineUpdates: FC<PipelineUpdatesProps> = (props) => {
  const {
    updates,
    pipelineUpdates,
    opportunitiesDetailsFetchStatus,
    opportunitiesTotal,
  } = props;

  const history = useHistory();
  const theme = useTheme();
  const context = useContext(UserContext);

  const isLessThanMd = useMediaQuery(theme.breakpoints.down("md"));

  const isPlusUser = isUserInRoles(context, UserRoleGroups.plusOrHigher);
  const isMember = doesUserHaveRole(context, ServerOrgRoles.organizationMember);

  const [upgradePlanModalOpen, setUpgradePlanModalOpen] =
    useState<boolean>(false);

  const isMobile = useMemo(
    () => checkIfDesktop(),
    [window?.navigator?.userAgent],
  );

  const selectedTab: DashboardUpdatesTabType = useMemo(
    () =>
      getSearchParamValue<DashboardUpdatesTabType>(
        history.location.search,
        "tab",
        "overview",
      ),
    [history.location.search],
  );

  const handleChangeTab = (tab: DashboardUpdatesTabType): void => {
    if (selectedTab !== tab && tab !== null) {
      const params = new URLSearchParams(history.location.search);
      params.set("tab", tab);
      history.replace(`${history.location.pathname}?${params.toString()}`);
    }
  };

  return (
    <Card
      variant={"outlined"}
      sx={{
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...(isLessThanMd &&
          isMobile && {
            minHeight: opportunitiesTotal > 0 ? "75vh" : "240px",
          }),
      }}
    >
      <UpgradePlanModal
        upgradePlanModalOpen={upgradePlanModalOpen}
        setUpgradePlanModalOpen={(val: boolean) => setUpgradePlanModalOpen(val)}
      />
      <Box sx={{ height: "4px" }}>
        {(RequestStatus.isFetching(updates.fetchStatus) ||
          // RequestStatus.isFetching(pipelineUpdates.fetchStatus) ||
          RequestStatus.isFetching(opportunitiesDetailsFetchStatus)) && (
          <LinearProgress />
        )}
      </Box>
      <CardContent
        sx={{
          pb: 0,
        }}
      >
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <RobotIcon color={"primary"} />
            <Stack
              direction={"column"}
              alignItems={"flex-start"}
              sx={{ textAlign: "left" }}
            >
              <Typography variant={"h5"} color={"inherit"}>
                USAID Funding Updates
              </Typography>
              <Typography
                variant={"body2"}
                color={"textSecondary"}
                fontSize={"13px"}
              >
                Designed by USAID Partnering Experts, powered by AI
              </Typography>
            </Stack>
          </Stack>
          {/*<OverviewFilters />*/}
        </Stack>
        <Divider
          sx={{
            mt: 2,
            mx: -2,
            width: "calc(100% + 32px)",
          }}
        />
        {/*<Tabs value={selectedTab} sx={{ minHeight: "unset", mt: 1 }}>*/}
        {/*  <StyledTab*/}
        {/*    onClick={() => handleChangeTab("overview")}*/}
        {/*    value={"overview"}*/}
        {/*    label={"Overview"}*/}
        {/*  />*/}
        {/*  <StyledTab*/}
        {/*    onClick={() => handleChangeTab("details")}*/}
        {/*    value={"details"}*/}
        {/*    label={"Details"}*/}
        {/*  />*/}
        {/*</Tabs>*/}
      </CardContent>
      {isPlusUser ? (
        <Box
          // direction={"column"}
          // spacing={2}
          sx={{
            flex: 1,
            overflowY: "scroll",
          }}
        >
          {/*{selectedTab === "overview" && <OverviewContent updates={updates} />}*/}
          {/*{selectedTab === "details" && <UpdatesDetails />}*/}
          <OverviewContent updates={updates} />
          <UpdatesDetails />
        </Box>
      ) : (
        <Card
          variant={"outlined"}
          sx={{
            p: 4,
            m: 3,
          }}
        >
          <Button
            variant={"secondaryContained"}
            sx={{
              my: 2,
            }}
            {...(isMember
              ? { onClick: () => setUpgradePlanModalOpen(true) }
              : {
                  component: "a",
                  href: "/tiers",
                  target: "_blank",
                })}
          >
            Upgrade your plan to see pipeline updates
          </Button>
        </Card>
      )}
    </Card>
  );
};

export default PipelineUpdates;
