import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Switch,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import { AttachFile, ExpandLess } from "@mui/icons-material";
import UpdatesDetailsFilters from "./UpdatesDetailsFilters";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import { labelSettings } from "../../../components/OpportunityDetails/opportunityDetailsUtils";
import { RootState, useDispatch, useSelector } from "../../../store";
import { useHistory } from "react-router-dom";
// import { getPipelineUpdatesDetailsRefreshSpecificPage } from "../../../slices/dashboard";
import SimpleModal from "../../../components/Modals/SimpleModal";
import {
  ContractsResourceServer,
  GrantsResourceServer,
} from "../../../configs/resources";
import DetailsRow from "./DetailsRow";
import {
  getSearchParamValue,
  setUrlParams,
} from "../../../utils/Helpers/extractDataFromSearchParams";
import { isEqual } from "lodash";
import UserContext from "../../../services/UserContext";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import {
  clearDetails as clearGrantDetails,
  getGrantDetails,
  GrantsFileRecord,
  resetShareOpportunitiesStatus as resetShareOpportunitiesStatusGrants,
  shareOpportunities as shareOpportunitiesGrants,
} from "../../../slices/grants";
import {
  clearDetails as clearContractDetails,
  ContractsFileRecord,
  getContractDetails,
  resetShareOpportunitiesStatus as resetShareOpportunitiesStatusContracts,
  shareOpportunities as shareOpportunitiesContracts,
} from "../../../slices/contracts";
import Stack from "@mui/material/Stack";
import { customColors } from "../../../theme/MUITheme";
import toast from "react-hot-toast";
import {
  resetShareOpportunitiesStatus as resetShareOpportunitiesStatusForecast,
  shareOpportunities as shareOpportunitiesForecast,
} from "../../../slices/forecast";
import ShareModal from "../../../components/Widgets/USAIDBidSearchComponents/ShareModal";
import axios from "axios";
import {
  createAuthenticatedRequest,
  getFullUrl,
} from "../../../configs/axios-export.custom";
import {
  getPipelinesForRecord,
  resetPipelinesForRecord,
  setPipelinesForRecordFor,
} from "../../../slices/pipelines";
import { constantDimensions } from "../../../components/MaterialTable/Table";
import { getPipelineUpdatesDetails } from "../../../slices/dashboard";
import { format } from "date-fns";

interface UpdatesDetailsProps {}

const data = [
  {
    status: "ModifiedRecord",
    source: "forecast",
    createdOn: new Date().toISOString(),
  },
  {
    status: "NewRecord",
    source: "contracts",
    createdOn: new Date().toISOString(),
  },
  {
    status: "FinalRecord",
    source: "grants",
    createdOn: new Date().toISOString(),
  },
];

const getItems = (data: any[], theme) =>
  data.map((i) => ({
    ...i,
    badgeSettings: labelSettings(
      theme,
      (i.datasetType.toLowerCase() +
        (i.datasetType !== "Forecast" ? "s" : "")) as
        | "forecast"
        | "contracts"
        | "grants",
    ),
  }));

const UpdatesDetails: FC<UpdatesDetailsProps> = (props) => {
  const {} = props;

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const context = useContext(UserContext);

  const listRef = useRef<VirtuosoHandle>(null);

  const [attachmentsModalOpen, setAttachmentsModalOpen] =
    useState<boolean>(false);
  const [attachmentsModalOpenFor, setAttachmentsModalOpenFor] =
    useState<any>(null);
  const [attachments, setAttachments] = useState<
    ContractsFileRecord[] | GrantsFileRecord[]
  >([]);

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [shareModalOpenFor, setShareModalOpenFor] = useState<any>(null);

  const [showDetails, setShowDetails] = useState<boolean>(true);

  const [addToPipelinesOpen, setAddToPipelinesOpen] = useState<
    number | string | null
  >(null);
  const [removedFromAllPipelinesId, setRemovedFromAllPipelinesId] = useState<
    string | null
  >(null);
  const [preventScroll, setPreventScroll] = useState<boolean>(false);

  const {
    // paginatedItems, count,
    fetchStatus,
    pageIndex,
    attributes,
    pageSize,
    data,
    total,
    lastUpdatedUtc,
  } = useSelector((state: RootState) => state.dashboard.opportunitiesDetails);

  const {
    shareOpportunities: {
      postFetchStatus: shareOpportunitiesFetchStatusContracts,
    },
    details: contractDetails,
  } = useSelector((state: RootState) => state.contracts);
  const {
    shareOpportunities: {
      postFetchStatus: shareOpportunitiesFetchStatusGrants,
    },
    details: grantDetails,
  } = useSelector((state: RootState) => state.grants);

  const {
    shareOpportunities: {
      postFetchStatus: shareOpportunitiesFetchStatusForecast,
    },
  } = useSelector((state: RootState) => state.forecast);

  const {
    fetchStatus: pipelinesForRecordFetchStatus,
    itemsFor: pipelinesForRecordFor,
  } = useSelector((state: RootState) => state.pipelines.pipelinesForRecord);
  const { postFetchStatus } = useSelector(
    (state: RootState) => state.pipelines.addOrRemoveFromPipelines,
  );

  const datasetTypes = useMemo(
    () =>
      // stringify to prevent triggering useEffect even when the value doesn't change
      JSON.stringify(
        getSearchParamValue<("Forecast" | "Contract" | "Grant" | "All")[]>(
          history.location.search,
          "opportunitiesType",
          ["All"],
          {
            multiple: true,
          },
        ),
      ),
    [history.location],
  );

  const overviewRange = useMemo(
    () =>
      getSearchParamValue<"today" | "last7" | "lastMonth" | "lastYear">(
        history.location.search,
        "updatesRange",
        "today",
      ),
    [history.location],
  );
  const overviewStartDate = useMemo(
    () =>
      overviewRange === "lastYear"
        ? new Date().addYears(-1)
        : overviewRange === "lastMonth"
        ? new Date().addMonths(-1)
        : new Date().addDays(overviewRange === "last7" ? -7 : -1),
    [overviewRange],
  );

  const items = useMemo(() => getItems(data, theme), [data]);

  const handleGetGrantDetails = useCallback((id): void => {
    dispatch(
      getGrantDetails({
        context,
        params: {
          id,
        },
      }),
    );
  }, []);

  const handleGetContractDetails = (id): void => {
    dispatch(
      getContractDetails({
        context,
        params: {
          id,
        },
      }),
    );
  };

  const handleOpenModal = (item: any): void => {
    setAttachmentsModalOpen(true);
    setAttachmentsModalOpenFor(item);
    if (item.datasetType === "Contract") {
      handleGetContractDetails(item.sourceId);
    } else {
      handleGetGrantDetails(item.sourceId);
    }
  };

  const handleCloseModal = (): void => {
    setAttachmentsModalOpen(false);
    setTimeout(() => {
      dispatch(clearContractDetails());
      dispatch(clearGrantDetails());
      setAttachments([]);
      setAttachmentsModalOpenFor(null);
    }, 150);
  };

  const opportunitiesKeyword = useMemo(
    () =>
      getSearchParamValue<string>(
        history.location.search,
        "opportunitiesKeyword",
        "",
      ),
    [history.location],
  );

  const pipelineValue = useMemo(
    () =>
      // stringify to prevent triggering useEffect even when the value doesn't change
      JSON.stringify(
        getSearchParamValue<string[]>(
          history.location.search,
          "pipelineValue",
          ["all"],
          {
            multiple: true,
          },
        ),
      ),
    [history.location],
  );

  const detailsForPipelines = useMemo(
    () =>
      getSearchParamValue<"true" | "false">(
        history.location.search,
        "detailsForPipelines",
        "false",
      ),
    [history.location],
  );

  const handleSetDetailsForPipelines = (): void => {
    setUrlParams(
      history,
      {
        detailsForPipelines: detailsForPipelines === "true" ? "false" : "true",
        pageIndex: "0",
        ...(detailsForPipelines === "true" && { pipelineValue: ["all"] }),
      },
      {
        replace: true,
      },
    );
  };

  // const handleLoadMore = useCallback((): void => {
  //   if (data?.length % 20 !== 0) {
  //     setUrlParams(history, { pageSize: "40" }, { replace: true });
  //   } else
  //     setUrlParams(
  //       history,
  //       { pageIndex: (pageIndex + 1).toString(), pageSize: "20" },
  //       { replace: true },
  //     );
  // }, [
  //   history.location,
  //   data,
  //   total,
  //   pageSize,
  //   pageIndex,
  //   opportunitiesKeyword,
  // ]);

  const getCorrectId = async (
    sourceId: string | number,
    datasetType: string,
  ): Promise<any> => {
    const response = await axios.get(
      getFullUrl(
        datasetType === "Forecast"
          ? `/api/BusinessForecast/${sourceId}/details`
          : datasetType === "Contract"
          ? `/api/Contract/opportunity/details/${sourceId}`
          : `/api/Grant/details/${sourceId}`,
        {
          useDedicatedEnvironment: true,
        },
      ),
      createAuthenticatedRequest(context),
    );

    return datasetType === "Forecast"
      ? response.data.sort((a1, a2) => a2.rowId - a1.rowId)[0].rowId ?? null
      : datasetType === "Contract"
      ? response.data.sort(
          (a1, a2) =>
            a2.record.contractOpportunityBase_Id -
            a1.record.contractOpportunityBase_Id,
        )[0].record.contractOpportunityBase_Id ?? null
      : response.data.records.sort(
          (a1, a2) =>
            a2.grantRecord.opportunityId - a1.grantRecord.opportunityId,
        )[0].grantRecord.opportunityId ?? null;
  };

  const handleShareForecast = async (values: any): Promise<void> => {
    const id = await getCorrectId(
      shareModalOpenFor.sourceId,
      shareModalOpenFor.datasetType,
    );
    dispatch(
      shareOpportunitiesForecast({
        context,
        ...values,
        opportunityId: id,
      }),
    );
  };

  const handleShareContract = async (values: any): Promise<void> => {
    const id = await getCorrectId(
      shareModalOpenFor.sourceId,
      shareModalOpenFor.datasetType,
    );
    dispatch(
      shareOpportunitiesContracts({
        context,
        ...values,
        opportunityId: id,
      }),
    );
  };

  const onShareSubmit = (values: any): void => {
    if (!shareModalOpenFor) return;
    shareModalOpenFor.datasetType === "Forecast"
      ? handleShareForecast(values)
      : shareModalOpenFor.datasetType === "Contract"
      ? handleShareContract(values)
      : dispatch(
          shareOpportunitiesGrants({
            context,
            ...values,
          }),
        );
  };

  const handleCloseShareModal = (): void => {
    setShareModalOpen(false);
  };

  const handleOpenAddToPipelines = async (
    item: any,
    index: number,
  ): Promise<void> => {
    if (!item) return;
    if (addToPipelinesOpen) {
      handleCloseAddToPipelines();
      setTimeout(() => {
        dispatch(setPipelinesForRecordFor(`${item.sourceId}-${index}`));
        dispatch(
          getPipelinesForRecord({
            context,
            table:
              item.datasetType === "Forecast"
                ? "forecasts"
                : item.datasetType === "Contract"
                ? "contracts"
                : "grants",
            recordId: item.sourceId,
          }),
        );
      }, 100);
    } else {
      dispatch(setPipelinesForRecordFor(`${item.sourceId}-${index}`));
      dispatch(
        getPipelinesForRecord({
          context,
          table:
            item.datasetType === "Forecast"
              ? "forecasts"
              : item.datasetType === "Contract"
              ? "contracts"
              : "grants",
          recordId: item.sourceId,
        }),
      );
    }
  };

  const handleCloseAddToPipelines = (): void => {
    setAddToPipelinesOpen(null);
    setRemovedFromAllPipelinesId(null);
    dispatch(setPipelinesForRecordFor(null));
    dispatch(resetPipelinesForRecord());
  };

  const onChangePipelines = (
    addedToPipelinesIds: number[],
    removedFromPipelinesIds: number[],
    currentPipelinesIds: number[],
    item: any,
  ): void => {
    setPreventScroll(true);
    if (
      isEqual(removedFromPipelinesIds.sort(), currentPipelinesIds.sort()) &&
      addedToPipelinesIds.length === 0
    ) {
      setRemovedFromAllPipelinesId(item.sourceId);
    }
  };

  const onPageChange = (event: React.MouseEvent | null, page: number): void => {
    setUrlParams(history, { pageIndex: page.toString() }, { replace: false });
  };

  const onRowsPerPageChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    setUrlParams(
      history,
      { pageIndex: "0", pageSize: e.target.value },
      { replace: false },
    );
  };

  useEffect(() => {
    if (RequestStatus.isDone(fetchStatus) && listRef.current) {
      preventScroll
        ? setPreventScroll(false)
        : listRef.current.scrollToIndex(0);
    }
  }, [fetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(contractDetails.fetchStatus)) {
      setAttachments(contractDetails.files);
    }
  }, [contractDetails.fetchStatus]);

  useEffect(() => {
    if (RequestStatus.isDone(grantDetails.fetchStatus)) {
      setAttachments(grantDetails.files);
    }
  }, [grantDetails.fetchStatus]);

  useEffect(() => {
    if (
      RequestStatus.isDone(shareOpportunitiesFetchStatusForecast) ||
      RequestStatus.isDone(shareOpportunitiesFetchStatusContracts) ||
      RequestStatus.isDone(shareOpportunitiesFetchStatusGrants)
    ) {
      toast.success("Opportunity shared successfully");
      dispatch(resetShareOpportunitiesStatusForecast());
      dispatch(resetShareOpportunitiesStatusContracts());
      dispatch(resetShareOpportunitiesStatusGrants());
      handleCloseShareModal();
    }
    if (
      RequestStatus.isError(shareOpportunitiesFetchStatusForecast) ||
      RequestStatus.isError(shareOpportunitiesFetchStatusContracts) ||
      RequestStatus.isError(shareOpportunitiesFetchStatusGrants)
    ) {
      toast.error("There was an error sharing this opportunity.");
      dispatch(resetShareOpportunitiesStatusForecast());
      dispatch(resetShareOpportunitiesStatusContracts());
      dispatch(resetShareOpportunitiesStatusGrants());
    }
  }, [
    shareOpportunitiesFetchStatusForecast,
    shareOpportunitiesFetchStatusContracts,
    shareOpportunitiesFetchStatusGrants,
  ]);

  useEffect(() => {
    if (
      RequestStatus.isDone(pipelinesForRecordFetchStatus) &&
      pipelinesForRecordFor !== null &&
      !addToPipelinesOpen
    )
      setAddToPipelinesOpen(pipelinesForRecordFor);
    if (RequestStatus.isError(pipelinesForRecordFetchStatus)) {
      toast.error(
        "Pipeline data for this row couldn't be loaded. Try again later.",
      );
    }
  }, [pipelinesForRecordFetchStatus, pipelinesForRecordFor]);

  useEffect(() => {
    if (RequestStatus.isDone(postFetchStatus) && addToPipelinesOpen !== null) {
      // dispatch(
      //   getPipelineUpdatesDetailsRefreshSpecificPage({
      //     context,
      //     params: {
      //       DatasetTypes: isEqual(JSON.parse(datasetTypes), ["All"])
      //         ? ["Forecast", "Contract", "Grant"]
      //         : JSON.parse(datasetTypes),
      //       PipelineIds: isEqual(JSON.parse(pipelineValue), ["all"])
      //         ? undefined
      //         : JSON.parse(pipelineValue),
      //       UpdatedAfter: overviewStartDate.toISOString(),
      //       UpdatedBefore: new Date().toISOString(),
      //       PageIndex: index > 0 ? Math.floor(index / 20) : 0,
      //       // index > 0 && items.length > 100 ? Math.floor(index / 20) : 0,
      //       PageSize: pageSize, //items.length > 100 ? pageSize : items.length,
      //       Keyword: opportunitiesKeyword,
      //       SortField: "lastUpdatedOn",
      //       SortOrder: -1,
      //       FilterToUserPipelines: detailsForPipelines,
      //     },
      //     removedFromAllPipelinesId,
      //   }),
      if (
        removedFromAllPipelinesId !== null &&
        pageIndex > 0 &&
        total - 1 <= pageIndex * pageSize
      ) {
        onPageChange(null, pageIndex - 1);
      } else
        dispatch(
          getPipelineUpdatesDetails({
            context,
            params: {
              DatasetTypes: isEqual(JSON.parse(datasetTypes), ["All"])
                ? ["Forecast", "Contract", "Grant"]
                : JSON.parse(datasetTypes),
              PipelineIds: isEqual(JSON.parse(pipelineValue), ["all"])
                ? undefined
                : JSON.parse(pipelineValue),
              UpdatedAfter: overviewStartDate.toISOString(),
              UpdatedBefore: new Date().toISOString(),
              PageIndex: pageIndex,
              PageSize: pageSize,
              Keyword: opportunitiesKeyword,
              SortField: "lastUpdatedOn",
              SortOrder: -1,
              FilterToUserPipelines: detailsForPipelines,
            },
            // loadMore,
          }),
        ).then(() => {
          handleCloseAddToPipelines();
        });
    }
  }, [postFetchStatus]);

  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <ShareModal
        open={shareModalOpen}
        handleClose={handleCloseShareModal}
        openFor={shareModalOpenFor}
        onSubmit={onShareSubmit}
        shareFetchStatus={
          !shareModalOpenFor
            ? "null"
            : shareModalOpenFor.datasetType === "Forecast"
            ? shareOpportunitiesFetchStatusForecast
            : shareModalOpenFor.datasetType === "Contract"
            ? shareOpportunitiesFetchStatusContracts
            : shareOpportunitiesFetchStatusGrants
        }
        type={
          !shareModalOpenFor
            ? "forecast"
            : shareModalOpenFor.datasetType === "Forecast"
            ? "forecast"
            : shareModalOpenFor.datasetType === "Contract"
            ? "contracts"
            : "grants"
        }
        explicitIdParamName={"sourceId"}
      />
      <Stack
        direction={"row"}
        sx={{
          py: 1,
          px: 2,
          borderTop: `solid 1px ${theme.palette.divider}`,
          borderBottom: `solid 1px ${theme.palette.divider}`,
          backgroundColor: theme.palette.secondary.light,
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
          }}
          sx={{
            alignItems: "center",
            rowGap: 0.5,
            columnGap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant={"h6"}
            fontWeight={500}
            sx={{
              color: customColors.dashboardSectionTitle,
            }}
          >
            Details
          </Typography>
          <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
              lineHeight={"15px"}
            >
              All USAID market updates
            </Typography>
            <Switch
              checked={detailsForPipelines === "true"}
              onClick={handleSetDetailsForPipelines}
              sx={{
                height: "26px !important",
                width: "44px !important",
                p: 0.5,
                "& .MuiSwitch-switchBase": {
                  top: "-3px",
                  left: "-3px",
                  "& .MuiSwitch-thumb": {
                    height: "14px",
                    width: "14px",
                    color: "white !important",
                  },
                },
                // "& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                //   {
                //     backgroundColor: `${theme.palette.secondary.main} !important`,
                //   },
                "& .Mui-checked+.MuiSwitch-track": {
                  backgroundColor: `${theme.palette.success.main} !important`,
                  opacity: `1 !important`,
                },
              }}
            />
            <Typography
              variant={"subtitle2"}
              fontWeight={400}
              color={"inherit"}
              lineHeight={"15px"}
            >
              My pipeline updates only
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={() => setShowDetails((prev) => !prev)}>
          <ExpandLess
            sx={{
              transition: "transform 250ms ease-in-out",
              transform: `rotate(${showDetails ? "0deg" : "180deg"})`,
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={showDetails}>
        <UpdatesDetailsFilters
          loading={RequestStatus.isFetching(fetchStatus)}
        />
        <SimpleModal
          title={"Attachments"}
          open={attachmentsModalOpen}
          handleClose={handleCloseModal}
          handleAccept={handleCloseModal}
          disableCancelButton
          acceptLabel={"Close"}
          minWidth={"600px"}
          contentSx={{
            // minHeight: "50vh",
            overflowY: "auto",
          }}
          loading={
            RequestStatus.isFetching(contractDetails.fetchStatus) ||
            RequestStatus.isFetching(grantDetails.fetchStatus)
          }
        >
          <Typography
            variant={"h6"}
            color={"inherit"}
            fontWeight={"500"}
            sx={{ mb: 1 }}
            dangerouslySetInnerHTML={{
              __html: `Attachments for <b>${
                attachmentsModalOpenFor?.title ??
                attachmentsModalOpenFor?.Title ??
                attachmentsModalOpenFor?.opportunityTitle ??
                attachmentsModalOpenFor?.OpportunityTitle ??
                attachmentsModalOpenFor?.awardTitle ??
                attachmentsModalOpenFor?.AwardTitle ??
                ""
              }</b>`,
            }}
          />
          {attachments.length > 0
            ? attachments.map((attachment) => (
                <Card
                  variant={"outlined"}
                  sx={{
                    p: 1,
                    my: 0.5,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <AttachFile
                    sx={{
                      height: "20px !important",
                      width: "20px !important",
                    }}
                  />
                  <Stack direction={"column"}>
                    <Typography
                      variant={"subtitle2"}
                      component={"a"}
                      href={`${
                        attachmentsModalOpenFor?.datasetType === "Contract"
                          ? ContractsResourceServer
                          : GrantsResourceServer
                      }${attachment.fileName ?? attachment.name}`}
                      target={"_blank"}
                    >
                      {attachment.fileName ?? attachment.name}
                    </Typography>
                    {attachment.createdOn && (
                      <Typography variant={"subtitle2"} color={"textSecondary"}>
                        {format(new Date(attachment.createdOn), "dd MMMM yyyy")}
                      </Typography>
                    )}
                  </Stack>
                </Card>
              ))
            : (RequestStatus.isDone(contractDetails.fetchStatus) ||
                RequestStatus.isDone(grantDetails.fetchStatus) ||
                RequestStatus.isError(contractDetails.fetchStatus) ||
                RequestStatus.isError(grantDetails.fetchStatus)) && (
                <Typography
                  variant={"h6"}
                  color={"textSecondary"}
                  sx={{ my: 1 }}
                  fontWeight={"500"}
                >
                  No attachments found
                </Typography>
              )}
        </SimpleModal>
        {/*{items.length*/}
        {/*  ? items.map((item, idx) => (*/}
        {/*      <DetailsRow*/}
        {/*        key={`${item.id}-${idx}`}*/}
        {/*        item={item}*/}
        {/*        idx={idx}*/}
        {/*        setAttachmentsModalOpen={setAttachmentsModalOpen}*/}
        {/*        setAttachmentsModalOpenFor={setAttachmentsModalOpenFor}*/}
        {/*      />*/}
        {/*    ))*/}
        {/*  : RequestStatus.isDone(fetchStatus) && (*/}
        {/*      <Typography*/}
        {/*        variant={"h6"}*/}
        {/*        color={"textSecondary"}*/}
        {/*        sx={{ my: 1 }}*/}
        {/*        fontWeight={"500"}*/}
        {/*      >*/}
        {/*        No updates found*/}
        {/*      </Typography>*/}
        {/*    )}*/}
        <Virtuoso
          ref={listRef}
          style={{
            height: "calc(100vh - 360px - 52px)",
          }}
          data={
            !items.length && RequestStatus.isDone(fetchStatus)
              ? [{ noData: true }]
              : [
                  ...items,
                  // , { loadMore: true }
                ]
          }
          itemContent={(index, item) =>
            item?.noData ? (
              <Typography
                variant={"h6"}
                color={"textSecondary"}
                sx={{ my: 1 }}
                fontWeight={"500"}
              >
                No updates found
              </Typography>
            ) : (
              //   item?.loadMore ? (
              //   <Button
              //     variant={"secondaryContained"}
              //     disabled={
              //       items.length >= total || RequestStatus.isFetching(fetchStatus)
              //     }
              //     onClick={handleLoadMore}
              //     sx={{
              //       my: 1,
              //     }}
              //   >
              //     Load more
              //   </Button>
              // ) :
              <DetailsRow
                key={`${item.id}-${index}`}
                item={item}
                idx={index}
                handleOpenModal={
                  item.datasetType === "Forecast" ? null : handleOpenModal
                }
                setShareModalOpen={setShareModalOpen}
                setShareModalOpenFor={setShareModalOpenFor}
                addToPipelinesOpen={addToPipelinesOpen}
                handleCloseAddToPipelines={handleCloseAddToPipelines}
                handleOpenAddToPipelines={handleOpenAddToPipelines}
                onChangePipelines={onChangePipelines}
              />
            )
          }
        />
        <TablePagination
          count={total}
          page={pageIndex}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          component={"div"}
          sx={{
            mx: 2,
            height: constantDimensions.pagination.height,
          }}
        />
      </Collapse>
    </Box>
  );
};

export default UpdatesDetails;
