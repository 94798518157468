import React, { FC } from "react";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
  SxProps,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import InputComboBox from "./InputComboBox";
import { RequestStatus } from "../../../utils/Helpers/fetchStatus";
import RichTextEditor from "../RichTextEditor";
import ClearIcon from "@mui/icons-material/Clear";
import { NumericFormat } from "react-number-format";

export interface DropdownOption {
  value: any;
  label: string;
}

interface InputProps {
  type: string;
  name: string;
  control: any;
  defaultValue?: any;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  fetchStatus?: string;
  inputValue?: string;
  onInputChange?: any;
  options?: Array<DropdownOption> & { secondaryLabel?: string | null };
  fullWidth?: boolean;
  validationMessage?: string;
  noValueMessage?: string;
  requiredSign?: boolean;
  multiple?: boolean;
  limitTags?: number | null;
  multiline?: boolean;
  rows?: string | number;
  sx?: SxProps;
  withCheckboxes?: boolean;
  richTextUnformattedOnChange?: (value: any) => void;
  useVirtualizedList?: boolean;
  disabled?: boolean;
  asyncCallback?: ((value: string) => void) | null;
  disableAddAllMatchingOption?: boolean;
  size?: "small" | "medium";
}

interface NumericFormatCustomProps {
  onChange: any;
  name: string;
}

const ValidationMessage: FC<{ message: string }> = ({ message }) => (
  <FormHelperText error>{message}</FormHelperText>
);

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props: NumericFormatCustomProps,
  ref,
) {
  const { onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

const Input: FC<InputProps> = (props) => {
  const {
    type,
    name,
    control,
    defaultValue = null,
    label = null,
    placeholder = null,
    loading = false,
    inputValue = "",
    onInputChange = () => {},
    options = [],
    fullWidth = false,
    validationMessage = null,
    fetchStatus = RequestStatus.statuses.NULL,
    noValueMessage = "No items",
    requiredSign = false,
    multiple = false,
    limitTags = null,
    multiline = false,
    rows = null,
    sx = {},
    withCheckboxes = false,
    richTextUnformattedOnChange = null,
    useVirtualizedList = false,
    disabled = false,
    asyncCallback = null,
    disableAddAllMatchingOption = false,
    size = undefined,
  } = props;

  return type === "combo-box" ? (
    <Controller
      control={control}
      name={name}
      // defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <>
          <InputComboBox
            {...props}
            isTouched={isTouched}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            asyncCallback={asyncCallback}
            value={value}
            inputRef={ref}
            options={options}
            multiple={multiple}
            limitTags={limitTags}
            disabled={disabled}
            label={
              label
                ? `${label || placeholder} ${requiredSign ? "*" : ""}`
                : null
            }
            error={Boolean(validationMessage)}
            // defaultValue={defaultValue}
            fetchStatus={fetchStatus}
            noValueMessage={noValueMessage}
            requiredSign={requiredSign}
            sx={sx}
            withCheckboxes={withCheckboxes}
            useVirtualizedList={useVirtualizedList}
            disableAddAllMatchingOption={disableAddAllMatchingOption}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </>
      )}
    />
  ) : type === "date" ? (
    <Controller
      control={control}
      name={name}
      // defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <>
          <DatePicker
            label={
              label
                ? `${label || placeholder} ${requiredSign ? "*" : ""}`
                : null
            }
            disabled={disabled}
            onChange={onChange}
            value={value ? new Date(value) : null}
            inputRef={ref}
            slotProps={{
              field: {
                clearable: true,
              },
              popper: {
                sx: {
                  zIndex: 2147483640,
                },
              },
              textField: {
                sx: {
                  width: "100%",
                  ...sx,
                },
                name: name,
                fullWidth: fullWidth,
              },
            }}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </>
      )}
    />
  ) : type === "rich-text" ? (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <>
          <RichTextEditor
            setValue={(value: string) => onChange({ target: { value } })}
            {...(!!richTextUnformattedOnChange && {
              setUnformattedValue: (value: string) =>
                richTextUnformattedOnChange(value),
            })}
            value={value}
            label={label}
            disabled={disabled}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </>
      )}
    />
  ) : // ) : type === "switch" ? (
  //   <Controller
  //     control={control}
  //     name={name}
  //     render={({
  //       field: { onChange, onBlur, value, ref },
  //       fieldState: { isTouched },
  //     }) => (
  //       <>
  //         <FormControlLabel
  //           control={<Switch checked={value} onChange={onChange} />}
  //           label={label}
  //           disabled={disabled}
  //         />
  //         {validationMessage && (
  //           <ValidationMessage message={validationMessage} />
  //         )}
  //       </>
  //     )}
  //   />
  type === "amount" ? (
    <Controller
      control={control}
      name={name}
      // defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <>
          <TextField
            label={
              label
                ? `${label || placeholder} ${requiredSign ? "*" : ""}`
                : null
            }
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            fullWidth={fullWidth}
            disabled={disabled}
            {...(rows && { rows })}
            InputProps={{
              endAdornment: value?.length > 0 && (
                <IconButton
                  onClick={() => onChange({ target: { value: "" } })}
                  sx={{ mr: 0.5 }}
                >
                  <ClearIcon />
                </IconButton>
              ),
              //@ts-ignore
              inputComponent: NumericFormatCustom,
            }}
            sx={sx}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </>
      )}
    />
  ) : type === "switch" ? (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            ...(fullWidth && { width: "100%" }),
          }}
        >
          <FormControlLabel
            control={<Switch checked={value} onChange={onChange} />}
            label={label}
            labelPlacement={"start"}
            sx={{
              justifyContent: "space-between",
              mx: 0,
            }}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </Box>
      )}
    />
  ) : (
    <Controller
      control={control}
      name={name}
      // defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { isTouched },
      }) => (
        <>
          <TextField
            {...props}
            label={
              label
                ? `${label || placeholder} ${requiredSign ? "*" : ""}`
                : null
            }
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            fullWidth={fullWidth}
            multiline={multiline}
            disabled={disabled}
            {...(rows && { rows })}
            InputProps={{
              endAdornment: value?.length > 0 && (
                <IconButton
                  onClick={() => onChange({ target: { value: "" } })}
                  sx={{ mr: 0.5 }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
            sx={sx}
          />
          {validationMessage && (
            <ValidationMessage message={validationMessage} />
          )}
        </>
      )}
    />
  );
};

export default Input;
