import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import { useGridStyles } from "../../hooks/useGridStyles";
import { matchPath, useLocation } from "react-router-dom";
import Breadcrumbs from "../Widgets/Breadcrumbs";
import { DynamicPageTitleContext } from "../../services/dynamicPageTitleContext";
import { pageTitles } from "./pageTitles.config";

interface PageTitleProps {}

const PageTitle: FC<PageTitleProps> = (props) => {
  const {} = props;

  const styles = useGridStyles();
  const location = useLocation();
  const { pageTitle, pageTitleLoading, titleBadge } = useContext(
    DynamicPageTitleContext,
  );

  const [title, setTitle] = useState<string | null>(null);
  const [badge, setBadge] = useState<ReactNode | null>(null);

  const showBreadcrumbs = useMemo(
    () =>
      pageTitles.filter((pageTitle) =>
        matchPath(location.pathname, {
          path: pageTitle.pathname,
          exact: true,
          strict: false,
        }),
      ).length > 0 &&
      !["/", "/dashboard-new-w68f5es4"].includes(location.pathname),
    [location.pathname],
  );

  useEffect(() => {
    if (pageTitle) setTitle(pageTitle);
    else {
      const _pageTitle =
        pageTitles.find((title) =>
          matchPath(location.pathname, {
            path: title.pathname,
            exact: true,
            strict: false,
          }),
        ) ?? null;
      if (_pageTitle && !_pageTitle.onlyBreadcrumbs) setTitle(_pageTitle.title);
      else setTitle(null);
    }
  }, [location, pageTitle]);

  useEffect(() => {
    setBadge(titleBadge);
  }, [titleBadge]);

  return (
    <Box
      sx={{
        ml: 2,
      }}
    >
      {showBreadcrumbs && (
        <Breadcrumbs
          replaceLastWith={
            pageTitles.find((title) =>
              matchPath(location.pathname, {
                path: title.pathname,
                exact: true,
                strict: false,
              }),
            )?.title ?? undefined
          }
        />
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          mt: 1,
        }}
      >
        {title !== null && (
          <>
            <img
              src={styles.aidkonektIcon}
              style={styles.icon}
              alt="Aidkonekt"
              className="formIcon"
            />
            <Typography
              variant={"h5"}
              sx={{
                fontWeight: "bold",
                fontSize: "24px",
                // display: "-webkit-box",
                // overflow: "hidden",
                // WebkitBoxOrient: "vertical",
                // WebkitLineClamp: 1,
              }}
              align={"left"}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {badge && badge}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PageTitle;
